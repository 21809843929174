import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from 'components/Buttons/Button';
import { selectSelectedCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { costbooks } from '../data/plans';
import { useCostbookButton } from '../hooks/useButton';
import { useModal } from '../hooks/useModal';
import { useSubscriptionsContext } from '../providers/SubscriptionsProvider';
import { ActionsWrapper, AddOnImage, AddOnStack } from './Styles';

export default function Costbooks() {
  const { footer, name, subscriptions, image } = costbooks ?? {};
  const { monthly, yearly } = subscriptions ?? {};
  const {
    getCancelModalBundle,
    getCostbooksModalBundle,
    openModal,
    openCancelModal,
  } = useModal();

  const {
    ensemblesId,
    plan,
    costbooks: currentCostbooks,
    setModalBundle,
    setCancelModalBundle,
    stripeCustomerId: stripeCustomerIdFromContext,
  } = useSubscriptionsContext();

  const { stripe_customer_id: stripeCustomerIdFromCache } = useSelector(
    selectSelectedCompany
  );

  const stripeCustomerId =
    stripeCustomerIdFromContext || stripeCustomerIdFromCache;

  const { subscribed } = plan ?? {};

  const { renewalPeriod } = currentCostbooks ?? {};

  const price = getPrice(
    renewalPeriod,
    monthly.formatted_price,
    yearly.formatted_price
  );
  const priceText = getPriceText(renewalPeriod);

  const alternatePrice = getAlternatePrice(
    renewalPeriod,
    monthly.formatted_price,
    yearly.formatted_price
  );
  const alternatePriceText = getAlternatePriceText(renewalPeriod);

  const { key, label, options } = useCostbookButton(
    currentCostbooks,
    costbooks,
    subscribed
  );

  const handleCancel = useCallback(() => {
    const bundle = getCancelModalBundle({
      currentPlan: currentCostbooks,
      ensemblesId,
      planName: name,
      stripeCustomerId,
    });

    setCancelModalBundle(bundle);
    openCancelModal();
  }, [key, stripeCustomerId]);

  const handleSubmit = useCallback(() => {
    const bundle = getCostbooksModalBundle({
      costbooks,
      currentCostbooks,
      ensemblesId,
      stripeCustomerId,
    });

    setModalBundle(bundle);
    openModal();
  }, [key, stripeCustomerId]);

  return (
    <AddOnStack>
      <Price>
        {price}
        <span>/{priceText}</span>
      </Price>
      <AlternatePrice>
        {alternatePrice}
        <span>/{alternatePriceText}</span>
      </AlternatePrice>
      <AddOnImage src={image} alt={name} />
      <ActionsWrapper>
        <Button fullWidth {...options} onClick={handleSubmit}>
          {label}
        </Button>
        <CancelSubscription
          onClick={handleCancel}
          visible={currentCostbooks.subscribed}
        />
        <Typography variant="caption" color="textSecondary">
          {footer.caption}
        </Typography>
      </ActionsWrapper>
    </AddOnStack>
  );
}

// Not using costbooks as a prop in this component, but leaving
// To act as a reference for the PropTypes for costbooks from data/plans.js
Costbooks.propTypes = {
  costbooks: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    subscriptions: PropTypes.shape({
      monthly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
      yearly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
    }),
    footer: PropTypes.shape({
      caption: PropTypes.string,
    }),
  }),
};

// ##############################
// ### Plan Styled Components
// ##############################

function CancelSubscription({ onClick: handleClick, visible }) {
  if (!visible) return null;

  return (
    <Button fullWidth secondary onClick={handleClick}>
      Cancel Subscription
    </Button>
  );
}

CancelSubscription.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

const Price = styled((props) => <Typography variant="h4" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    '& span': {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

const AlternatePrice = styled((props) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey.dark,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  '& span': {
    marginLeft: '2px',
  },
}));

// ##############################
// ### Helper methods
// ##############################

// Helper function to get the price based on the renewal period
const getPrice = (renewalPeriod, monthlyPrice, yearlyPrice) => {
  return renewalPeriod === 'monthly' ? monthlyPrice : yearlyPrice;
};

// Helper function to get the price text based on the renewal period
const getPriceText = (renewalPeriod) => {
  return renewalPeriod === 'monthly' ? 'mo' : 'yr';
};

// Helper function to get the alternate price based on the renewal period
const getAlternatePrice = (renewalPeriod, monthlyPrice, yearlyPrice) => {
  return renewalPeriod === 'monthly' ? yearlyPrice : monthlyPrice;
};

// Helper function to get the alternate price text based on the renewal period
const getAlternatePriceText = (renewalPeriod) => {
  return renewalPeriod === 'monthly' ? 'yr' : 'mo';
};
