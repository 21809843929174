import {
  faArrowUp,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled, useTheme } from '@mui/material/styles';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { hasValue } from 'utils/helpers';
import { CommonFormControl, CommonInput } from './CommonInput';

export default function PasswordTextfield({
  animateError = true,
  error,
  gutter,
  hint,
  id,
  inputProps,
  label,
  onBlur: handleBlur,
  onChange: handleChange,
  required: isRequired,
  validationRef,
  ...props
}) {
  const theme = useTheme();
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleKeyUp = useCallback((e) => {
    const isCapsLockActive = e.getModifierState('CapsLock');
    const hasInputValue = hasValue(e.target?.value);

    if (isCapsLockActive && hasInputValue) {
      setCapsLockOn(e.key === 'CapsLock' ? (prev) => !prev : true);
    } else {
      setCapsLockOn(false);
    }
  }, []);

  const maxLength = props.maxLength || 50;

  return (
    <CommonFormControl
      animateError={animateError}
      error={error}
      gutter={gutter}
      hint={hint}
      id={id}
      label={label}
      required={isRequired}
      // {...props}
    >
      <CommonInput
        id={id}
        name={id}
        inputProps={{ ...inputProps, maxLength, onKeyUp: handleKeyUp }}
        onBlur={handleBlur}
        onChange={handleChange}
        validationRef={validationRef}
        endAdornment={
          <StyledEndAdornment>
            {capsLockOn && (
              <Icon
                icon={faArrowUp}
                size="sm"
                color={theme.palette.blue.main}
              />
            )}
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              size="small"
              onClick={handleShowPassword}
            >
              <Icon icon={showPassword ? faEyeSlash : faEye} />
            </IconButton>
          </StyledEndAdornment>
        }
        type={showPassword ? 'text' : 'password'}
        {...props}
      />
    </CommonFormControl>
  );
}

PasswordTextfield.propTypes = {
  animateError: PropTypes.bool,
  error: PropTypes.string,
  gutter: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validationRef: PropTypes.any.isRequired,
};

const StyledEndAdornment = styled((props) => (
  <InputAdornment position="end" {...props} />
))(({ theme }) => ({
  position: 'absolute',
  right: '12px',
  height: '100%',
  paddingLeft: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));
