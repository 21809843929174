import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { createRef, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { sentryDsn } from './data/constants';
import { routesAccessControlMap as accessControl } from './features/accessControl/checkPermissions';
import PrivateIndexLayout from './layouts/PrivateIndexLayout';
import {
  createLazyAdminRoute,
  createLazyCustomersRoute,
  createLazyJobsRoute,
  createLazyPublicRoute,
  createLazyRoute,
} from './lib/router';
import { registerSW } from './lib/serviceWorkerRegistrar';
import { initializeStore } from './lib/store/store';
import FeatureNotAvailable from './pages/public/FeatureNotAvailable';
import NotAllowed from './pages/public/NotAllowed';
import ComponentStateProvider from './providers/ComponentStateProvider';
import SnackbarProvider from './providers/SnackbarProvider';
import ThemeProvider from './providers/ThemeProvider';

import '@stripe/stripe-js'; // ensured stripe script is included in entire app (better fraud detection this way)

// ##############################
// ### IMPORT PUBLIC PAGES
// ##############################

import ForgotPassword, {
  action as forgotPasswordAction,
} from './pages/public/ForgotPassword';

import ForgotPasswordSent from './pages/public/ForgotPasswordSent';
import Login, { action as loginAction } from './pages/public/Login';

// ##############################
// ### SENTRY
// ##############################

Sentry.init({
  dsn: sentryDsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

// ##############################
// ### Init root, store
// ##############################

const store = initializeStore();
let root = window.__root;

if (!root) {
  root = ReactDOM.createRoot(document.getElementById('root'));
  window.__root = root;
}

// ##############################
// ### ROUTER
// ##############################

const routes = [
  // #### PUBLIC ROUTES ####
  {
    path: '/login',
    errorElement: <ErrorBoundary />,
    element: <Login />,
    action: ({ params, request }) => {
      return loginAction({ store, params, request });
    },
  },
  {
    path: '/forgot-password',
    errorElement: <ErrorBoundary />,
    element: <ForgotPassword />,
    action: ({ params, request }) => {
      return forgotPasswordAction({ store, params, request });
    },
  },
  {
    path: '/forgot-password/sent',
    errorElement: <ErrorBoundary />,
    element: <ForgotPasswordSent />,
  },
  {
    path: '/setup-password',
    errorElement: <ErrorBoundary />,
    lazy: async () => createLazyPublicRoute('SetupPassword', store),
  },
  {
    path: '/estimate/:estimateId',
    errorElement: <ErrorBoundary />,
    lazy: async () => createLazyPublicRoute('Estimate', store),
  },
  {
    path: '/invoice/:invoiceId',
    errorElement: <ErrorBoundary />,
    lazy: async () => createLazyPublicRoute('Invoice', store),
  },
  {
    path: '/payment/:paymentId',
    errorElement: <ErrorBoundary />,
    lazy: async () => createLazyPublicRoute('Payment', store),
  },
  {
    path: '/purchaseorders',
    errorElement: <ErrorBoundary />,
    lazy: async () => createLazyPublicRoute('PurchaseOrders', store),
  },
  // ### Bad Routes ###
  {
    path: '/feature-not-available',
    element: <FeatureNotAvailable />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/not-allowed',
    element: <NotAllowed />,
    errorElement: <ErrorBoundary />,
  },
  // ### Error Routes ###
  {
    path: '/error',
    element: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <Navigate to="/error?status=404" replace />,
  },
  // ### PRIVATE ROUTES ###
  {
    isPrivateHead: true,
    path: '/',
    element: <PrivateRoutes store={store} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        nodeRef: createRef(),
        lazy: async () => createLazyRoute('Home', store),
        path: '/', // used for private index layout animation
      },
      {
        path: 'account',
        nodeRef: createRef(),
        lazy: async () => createLazyRoute('Account', store),
      },
      {
        path: 'affiliate',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyRoute('Affiliate', store, accessControl.affiliate),
      },
      {
        path: 'billing',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyRoute('Billing', store, accessControl.billingSettings),
      },
      {
        path: 'customers',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyCustomersRoute('Customers', store, accessControl.customers),
      },
      {
        path: 'customers/:customerId',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyCustomersRoute('Customer', store, accessControl.customers),
      },
      {
        path: 'customers/:customerId/jobs',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('Jobs', store, accessControl.customerJobs),
      },
      {
        path: 'customers/:customerId/jobs/:jobId',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('Job', store, accessControl.customerJobs),
      },
      {
        path: 'customers/:customerId/jobs/:jobId/change-orders',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute(
            'JobChangeOrders',
            store,
            accessControl.customerJobChangeOrders
          ),
        children: [
          {
            path: ':changeOrderId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobChangeOrderDetails',
                store,
                accessControl.customerJobChangeOrders
              ),
          },
        ],
      },
      {
        path: 'customers/:customerId/jobs/:jobId/estimate',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute(
            'JobEstimate',
            store,
            accessControl.customerJobEstimates
          ),
        children: [
          {
            path: 'document',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobEstimateDetails',
                store,
                accessControl.customerJobEstimates
              ),
          },
        ],
      },
      {
        path: 'customers/:customerId/jobs/:jobId/invoices',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute(
            'JobInvoices',
            store,
            accessControl.customerJobInvoices
          ),
        children: [
          {
            path: ':invoiceId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobInvoiceDetails',
                store,
                accessControl.customerJobInvoices
              ),
          },
        ],
      },
      {
        path: 'customers/:customerId/jobs/:jobId/payments',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute(
            'JobPayments',
            store,
            accessControl.customerJobPayments
          ),
        children: [
          {
            path: ':paymentId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobPaymentDetails',
                store,
                accessControl.customerJobPayments
              ),
          },
        ],
      },
      {
        path: '/downloads',
        nodeRef: createRef(),
        lazy: async () => createLazyRoute('Downloads', store),
      },
      {
        path: 'jobs',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('Jobs', store, accessControl.jobs),
      },
      {
        path: 'jobs/:jobId',
        nodeRef: createRef(),
        lazy: async () => createLazyJobsRoute('Job', store, accessControl.jobs),
      },
      {
        path: 'jobs/:jobId/change-orders',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute(
            'JobChangeOrders',
            store,
            accessControl.jobChangeOrders
          ),
        children: [
          {
            path: ':changeOrderId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobChangeOrderDetails',
                store,
                accessControl.jobChangeOrders
              ),
          },
        ],
      },
      {
        path: 'jobs/:jobId/estimate',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('JobEstimate', store, accessControl.jobEstimates),
        children: [
          {
            path: 'document',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobEstimateDetails',
                store,
                accessControl.jobEstimates
              ),
          },
        ],
      },
      {
        path: 'jobs/:jobId/invoices',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('JobInvoices', store, accessControl.jobInvoices),
        children: [
          {
            path: ':invoiceId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobInvoiceDetails',
                store,
                accessControl.jobInvoices
              ),
          },
        ],
      },
      {
        path: 'jobs/:jobId/payments',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyJobsRoute('JobPayments', store, accessControl.jobPayments),
        children: [
          {
            path: ':paymentId',
            nodeRef: createRef(),
            lazy: async () =>
              createLazyJobsRoute(
                'JobPaymentDetails',
                store,
                accessControl.jobPayments
              ),
          },
        ],
      },
      {
        path: 'payment-settings',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyRoute(
            'PaymentSettings',
            store,
            accessControl.paymentSettings
          ),
      },
      {
        path: 'subscriptions',
        nodeRef: createRef(),
        lazy: async () =>
          createLazyRoute(
            'Subscriptions',
            store,
            accessControl.subscriptionSettings
          ),
      },
      // ### Private Admin Routes ###
      {
        path: 'admin/impersonate',
        nodeRef: createRef(),
        lazy: async () => createLazyAdminRoute('Impersonate', store),
      },
    ],
  },
];

// ##############################
// ### RENDER
// ##############################
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <SnackbarProvider>
          <ComponentStateProvider>
            <RouterProvider
              router={sentryCreateBrowserRouter(routes)}
              future={{
                // Wrap all state updates in React.startTransition()
                v7_startTransition: true,
              }}
            />
          </ComponentStateProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

function PrivateRoutes({ store }) {
  const index = useMemo(
    () => routes.findIndex((route) => route.isPrivateHead),
    [routes]
  );
  return <PrivateIndexLayout routes={routes[index].children} />;
}

PrivateRoutes.propTypes = {
  store: PropTypes.object,
};

// ##############################
// ### SERVICE WORKER
// ##############################

// Calling this will register the service worker for this app
// Out of the box, this service worker is bootstrapped with the ability
// To cache assets, and serve them first, and can be used to prompt users to reload
// when there are updates. You should go through ./serviceWorker.js to customize how you want to handle that
registerSW();
