import PropTypes from 'prop-types';
import { CommonFormControl, CommonInput } from './CommonInput';

export default function Textfield({
  animateError = true,
  error,
  gutter,
  hint,
  id,
  inputProps,
  label,
  onBlur: handleBlur,
  onChange: handleChange,
  required: isRequired,
  validationRef,
  defaultValue,
  ...props
}) {
  const maxLength = props.maxLength || 50;
  return (
    <CommonFormControl
      animateError={animateError}
      error={error}
      gutter={gutter}
      hint={hint}
      id={id}
      label={label}
      required={isRequired}
      {...props}
    >
      <CommonInput
        id={id}
        name={id}
        inputProps={{ ...inputProps, maxLength }}
        onBlur={handleBlur}
        onChange={handleChange}
        validationRef={validationRef}
        defaultValue={defaultValue}
        {...props}
      />
    </CommonFormControl>
  );
}

Textfield.propTypes = {
  animateError: PropTypes.bool,
  error: PropTypes.string,
  gutter: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  multiLine: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validationRef: PropTypes.any.isRequired,
  multiline: PropTypes.bool,
  defaultValue: PropTypes.string,
};
