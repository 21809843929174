import { useCallback } from 'react';
import { FETCHER_KEY, ROUTES } from '../../data/constants';
import { plans, PlanCard } from '../../../subscriptions';
import { styled } from '@mui/material/styles';
import { useFetcher } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from 'components/Buttons/Button';
import PropTypes from 'prop-types';

export default function AddCompanyPlans() {
  const fetcher = useFetcher({ key: FETCHER_KEY }) ?? {};

  const onSubmit = useCallback(
    (name) => () => {
      fetcher.submit(
        { intent: ROUTES.PLANS, planName: name },
        { method: 'post', action: '/' }
      );
    },
    []
  );

  return (
    <PlansWrapper>
      {plans.map((model) => (
        <PlanView
          key={model.name}
          model={model}
          onSubmit={onSubmit(model.name)}
        />
      ))}
    </PlansWrapper>
  );
}

function PlanView({ model, onSubmit: handleSubmit }) {
  return (
    <PlanWrapper id={model?.id}>
      <PlanCard plan={model} />
      <ActionsWrapper>
        <Button fullWidth primary onClick={handleSubmit}>
          {`Sign up for ${model?.name}`}
        </Button>
      </ActionsWrapper>
    </PlanWrapper>
  );
}

PlanView.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
};

// ##############################
// ### Styles
// ##############################

const PlanWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    maxWidth: '296px',
  },
}));

const PlansWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  width: '100%',
  '& > button': {
    marginBottom: theme.spacing(2),
  },
}));
