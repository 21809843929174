import { isFunction } from 'utils/helpers';
import { styled } from '@mui/material/styles';
import { NavLink as Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import PropTypes from 'prop-types';

export default function TransitionLink({
  disableActiveStyles,
  children,
  isActive,
  onClick: handleClick,
  to,
}) {

  if (isFunction(handleClick)) {
    const activeClass = isActive ? 'active' : '';
    const disableActiveClass = disableActiveStyles ? 'no-active' : '';
    const classNames = `${activeClass} ${disableActiveClass}`.trim();

    return (
      <StyledListItemButton
        className={classNames}
        isLink={false}
        onClick={handleClick}
      >
        {children}
      </StyledListItemButton>
    );
  }



  return (
    <StyledListItemButton to={to}>
      {children}
    </StyledListItemButton>
  );
}

TransitionLink.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disableActiveStyles: PropTypes.bool,
};

// ###########################
// ### Styled Components
// ###########################

const StyledListItemButton = styled(({ isLink = true, ...props }) => (
  <ListItemButton
    component={isLink ? Link : 'button'}
    className={({ isActive }) => {
      return isActive ? 'active' : '';
    }}
    {...props}
  />
))(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey.light,
  },
  '&:hover .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '&.active:not(.no-active)': {
    backgroundColor: theme.palette.grey.light,
    pointerEvents: 'none',
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
  },
}));
