import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import Button from './Button';

const AddButton = styled((props) => (
  <Button endIcon={<Icon icon={faArrowRight} />} {...props} />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  paddingLeft: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    paddingLeft: 0,
  },
  ' svg': {
    [theme.breakpoints.down('mobileLandscape')]: {
      display: 'none',
    },
  },
}));

export default AddButton;
