/* eslint-disable no-nested-ternary */
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Icon from 'components/Icon';
import { COMPONENT_KEYS } from 'data/constants';
import { DownloadReceipt, Receipt, ReceiptModal } from 'features/billing';
import { selectSelectedCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiToUTC, formatInBrowserLocale } from 'utils/dates';
import { isEmpty, isNumeric, sleep } from 'utils/helpers';
import { plansByProductId } from '../data/plansByProductId';

export default function PaymentHistoryTable({ ensemblesId: id, payments }) {
  const theme = useTheme();
  const [downloading, setDownloading] = useState(false);
  const [document, setDocument] = useState({});
  const [currentPayment, setCurrentPayment] = useState(null);

  const modalContext = useComponentStateContext();
  const balanceDue = useBalanceDue();

  const handleClick = useCallback(
    (payment) => (event) => {
      event.stopPropagation();
      if (downloading) return;
      setCurrentPayment(payment);
      modalContext.setOpen(COMPONENT_KEYS.RECEIPT_MODAL)();
    },
    [downloading]
  );

  const handlePrint = useCallback(
    (payment) => (event) => {
      event.stopPropagation();
      // Guard against multiple clicks
      if (downloading) return;

      setDownloading(true);

      const name = `Contractor Tools Receipt: ${formatInBrowserLocale(
        apiToUTC(payment.purchase_date)
      )}-${payment.subscription_id}`;

      const document = <Receipt payment={payment} />;
      const trigger = +new Date();

      setDocument({
        name,
        document,
        trigger,
        callback: async () => {
          // We give an artificial delay to prevent the user from spamming the download button
          await sleep(700);
          setDownloading(false);
          setDocument({});
        },
      });
    },
    [downloading]
  );

  return (
    <>
      <TableContainer
        sx={{
          borderTop: '1px solid',
          borderColor: theme.palette.divider,
          // overflow: 'hidden', // This causes the table to not scroll which could be useful if we want to show varous table views based on responsive design
        }}
      >
        <Table sx={{ minWidth: 300 }}>
          <TableBody>
            {payments?.map((payment) => (
              <StyledTableRow
                key={`${payment.purchase_date}-${payment.subscription_id}-${id}`}
                onClick={handleClick(payment)}
              >
                <PlanNameCell payment={payment} />
                <PaymentDateCell payment={payment} />
                <PaymentAmountCell payment={payment} />
                <PrintCell
                  downloading={downloading}
                  onClick={handlePrint(payment)}
                />
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} align="right">
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {balanceDue.label}
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align="left">
                <Typography
                  sx={{
                    color: balanceDue.isCredit
                      ? 'success.main'
                      : 'text.primary',
                  }}
                  variant="subtitle2"
                >
                  {balanceDue.value}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!isEmpty(document) && (
        <DownloadReceipt
          document={document.document}
          name={document.name}
          trigger={document.trigger}
          callback={document.callback}
        />
      )}
      <ReceiptModal payment={currentPayment} />
    </>
  );
}

PaymentHistoryTable.propTypes = {
  ensemblesId: PropTypes.string,
  payments: PropTypes.array,
};

// ########################################################
// Table Cells
// ########################################################

function PlanNameCell({ payment }) {
  const planName = plansByProductId[payment.product_id];
  return <TableCell>{planName}</TableCell>;
}

PlanNameCell.propTypes = {
  payment: PropTypes.object.isRequired,
};

function PaymentDateCell({ payment }) {
  const theme = useTheme();
  const date = formatInBrowserLocale(apiToUTC(payment.purchase_date));
  return (
    <TableCell align="left" sx={{ color: theme.palette.text.secondary }}>
      {date}
    </TableCell>
  );
}

PaymentDateCell.propTypes = {
  payment: PropTypes.object.isRequired,
};

function PaymentAmountCell({ payment }) {
  const theme = useTheme();
  const {
    is_trial: isTrial,
    paid,
    price,
    currency_code: currencyCode,
  } = payment ?? {};

  return (
    <TableCell align="left" sx={{ color: theme.palette.text.secondary }}>
      {isTrial ? (
        'Free Trial'
      ) : (
        <>
          {paid ? (
            <>
              {price} {currencyCode}
            </>
          ) : (
            'Not Paid'
          )}
        </>
      )}
    </TableCell>
  );
}

PaymentAmountCell.propTypes = {
  payment: PropTypes.object.isRequired,
};

function PrintCell({ downloading, onClick: handleClick }) {
  const theme = useTheme();
  return (
    <TableCell align="right">
      <IconButton
        onClick={handleClick}
        disabled={downloading}
        sx={{ opacity: downloading ? '0.5' : '1.0' }}
      >
        <Icon size="sm" icon={faPrint} color={theme.palette.primary.main} />
      </IconButton>
    </TableCell>
  );
}

PrintCell.propTypes = {
  downloading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

// ########################################################
// Styled Components
// ########################################################

const StyledTableRow = styled((props) => <TableRow hover {...props} />)(
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  })
);

// ########################################################
// Helper Functions
// ########################################################

function useBalanceDue(payments) {
  const { account_balance: balance, currency_code: currencyCode } = useSelector(
    selectSelectedCompany
  );

  let balanceDue = 0;
  let label = 'Balance Due';
  let isCredit = false;
  if (isNumeric(balance)) {
    balanceDue = Number(balance);
  }

  if (balanceDue < 0) {
    label = 'Credit';
    isCredit = true;
  }
  return { value: `${balanceDue} ${currencyCode}`, label, isCredit };
}
