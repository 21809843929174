import {COMPONENT_KEYS} from 'data/constants';
import {
  selectCancelingApple,
  selectCardByEnsemblesId,
  selectSelectedCompany,
  setCancelingApple,
} from 'lib/store/rootSlice';
import {useComponentStateContext} from 'providers/ComponentStateProvider';
import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isExpired} from 'utils/dates';
import {hasValue, isEmpty, isFunction, isNil, sleep} from 'utils/helpers';
import {useBillingContext} from '../providers/BillingProvider';

// This is a custom hook to encapsulate the logic for initializing the cancel Apple Pay process
// We merely just mark the state as cancelingApple to ensure
// When we're canceling Apple Pay, we stay on the correct sheet in the drawer
export function useInitCancelApple() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(setCancelingApple(true));
  }, [dispatch]);
}

export function useCustomerCardDrawer() {
  const { clearErrors } = useBillingContext() ?? {};
  const { state, setOpen, setClose } = useComponentStateContext();
  const dispatch = useDispatch();

  // Make async await arrow function that waits 500ms then calls clearErrors
  const clearErrorsAsync = useCallback(async () => {
    if (!isFunction(clearErrors)) return;
    await sleep(500);
    clearErrors();
  }, [clearErrors]);

  return useMemo(() => {
    const openDrawer = setOpen(COMPONENT_KEYS.CREDITCARD_DRAWER);
    const isDrawerOpen = state.open[COMPONENT_KEYS.CREDITCARD_DRAWER];

    const closeDrawer = () => {
      setClose(COMPONENT_KEYS.CREDITCARD_DRAWER)();
      setClose(COMPONENT_KEYS.APPLE_CANCEL_ERROR_SHEET)();
      setClose(COMPONENT_KEYS.APPLE_CANCEL_SUCCESS_SHEET)();
      setClose(COMPONENT_KEYS.ADD_CARD_SHEET)();
      dispatch(setCancelingApple(false));
      clearErrorsAsync();
    };

    // Apple Stuff
    const setSlideInAppleError = (slide) => {
      if (slide) {
        setOpen(COMPONENT_KEYS.APPLE_CANCEL_ERROR_SHEET)();
      } else {
        setClose(COMPONENT_KEYS.APPLE_CANCEL_ERROR_SHEET)();
      }
    };

    const setSlideInAppleSuccess = (slide) => {
      if (slide) {
        setOpen(COMPONENT_KEYS.APPLE_CANCEL_SUCCESS_SHEET)();
      } else {
        setClose(COMPONENT_KEYS.APPLE_CANCEL_SUCCESS_SHEET)();
      }
    };

    const setSlideInAddCard = (slide) => {
      if (slide) {
        setOpen(COMPONENT_KEYS.ADD_CARD_SHEET)();
      } else {
        setClose(COMPONENT_KEYS.ADD_CARD_SHEET)();
      }
    };

    const slideInAddCard = state.open[COMPONENT_KEYS.ADD_CARD_SHEET];

    const slideInAppleError =
      state.open[COMPONENT_KEYS.APPLE_CANCEL_ERROR_SHEET];
    const slideInAppleSuccess =
      state.open[COMPONENT_KEYS.APPLE_CANCEL_SUCCESS_SHEET];

    return {
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      slideInAddCard,
      slideInAppleError,
      slideInAppleSuccess,
      setSlideInAddCard,
      setSlideInAppleError,
      setSlideInAppleSuccess,
    };
  }, [state]);
}

// Note: id is the company Stripe ID ( stripe_customer_id )
export function useCustomerCardStatus(payments, ensemblesId) {
  const card = useSelector(selectCardByEnsemblesId(ensemblesId));
  const { stripe_customer_id: id } = useSelector(selectSelectedCompany);
  const cancelingApple = useSelector(selectCancelingApple);

  const cardStatus = useMemo(() => {
    // Check if Apple Pay needs to be canceled
    const needToCancelApple = checkNeedToCancelApple(payments, id);

    const hasCard = !isNil(card) || !isEmpty(card);

    if (needToCancelApple || cancelingApple) {
      return 'cancelApplePay';
    }

    // Check if the card exists and is not empty, and if the id is present
    if (id && hasCard) {
      return 'updateCard';
    }

    // Default to adding a new card
    return 'addCard';
  }, [card?.id, id, payments, cancelingApple]);

  return { status: cardStatus };
}

export function useCustomerCardImage(companyName, ensemblesId) {
  const card = useSelector(selectCardByEnsemblesId(ensemblesId));
  return useMemo(() => {
    const { last4, exp_month, exp_year } = card ?? {};
    return {
      companyName,
      last4,
      expMonth: exp_month,
      expYear: exp_year,
      none: !card?.id || !card.last4,
    };
  }, [card?.id, companyName]);
}

// ##########################################
// ### Helper Functions
// ##########################################

// Helper function to check if Apple Pay needs to be canceled
function checkNeedToCancelApple(payments, id) {
  const applePlan = payments?.find((p) => p.source === 0);
  const { expiration_date: date, source } = applePlan ?? {};
  // Conditions on canceling Apple Pay:
  // - source === 0 (Apple Pay)
  // - expiration date is not expired
  // - id (company Stripe ID) is not present
  return Boolean(source === 0 && !isExpired(date)) && !hasValue(id);
}
