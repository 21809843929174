import { apiUrl } from 'data/constants';
import adminSession from 'lib/storage/admin';
import { setImpersonate } from 'lib/store/rootSlice';
import { hasValue } from 'utils/helpers';
import { api } from './api';

export const rootApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // User API call requires a token
    getUser: builder.query({
      query: (token) => {
        return {
          url: `${apiUrl}user`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      transformResponse: (res) => {
        const { user } = res ?? {};
        const role = hasValue(user?.customer_id)
          ? 'CUSTOMER_USER'
          : 'CONTRACTOR_USER';
        return { ...user, role };
      },
      providesTags: (_result, _error, _args) => {
        return [{ type: 'root', id: `ROOT_USER` }];
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          // Check if impersonating and update root so the impersonate card has the correct user
          const { data } = await queryFulfilled;
          const isImpersonating = adminSession.isImpersonating();
          if (isImpersonating) {
            dispatch(setImpersonate({ isAdmin: true, user: { ...data } }));
          }
        } catch (error) {
          console.error('Query failed', error);
        }
      },
    }),
    getAdminUsers: builder.query({
      query: ({ searchString }) => {
        return {
          url: `${apiUrl}admin/users`,
          method: 'POST',
          body: { search_string: searchString },
        };
      },
      transformResponse: (response, _request, args) => {
        const { users } = response ?? { users: [] };
        const userSearchList = users?.map(
          (user) => `${user?.first_name} ${user?.last_name} ${user?.email}`
        );
        return { key: args?.searchString, users, userSearchList };
      },
      providesTags: (result, error, args) => {
        return [
          { type: 'root', id: `ADMIN_USERS_SEARCH_${args?.searchString}` },
        ];
      },
    }),
    getAffiliateData: builder.query({
      query: () => {
        return {
          url: `${apiUrl}user/promos`,
          method: 'POST',
        };
      },
      transformResponse: (res) => {
        const { promos } = res ?? { promos: [] };
        const newPromos = promos?.map((promo) => ({
          ...promo,
          comissionDisplayRate: convertToPercentage(promo?.commission_rate),
          url: getPromoCodeUrl(promo?.promo_code),
        }));

        const hasPromos = hasValue(newPromos);

        return { promos: newPromos, hasPromos };
      },
      providesTags: (_result, _error, _args) => {
        return [{ type: 'root', id: `ROOT_AFFILIATE_DATA` }];
      },
    }),
    sendEmail: builder.mutation({
      query: ({ email }) => {
        return {
          url: `${apiUrl}forgotpassword`,
          method: 'POST',
          body: { email },
        };
      },
    }),
    postUser: builder.mutation({
      query: () => {
        return {
          url: `${apiUrl}user`,
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'root', id: `ROOT_COMPANIES` }],
    }),
    impersonateUser: builder.mutation({
      query({ authData, id }) {
        return {
          url: `${apiUrl}getimpersonationtoken`,
          method: 'POST',
          body: { impersonated_user_id: id },
          headers: {
            Authorization: `Basic ${authData}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetAdminUsersQuery,
  useGetAffiliateDataQuery,
  useSendEmailMutation,
  usePostUserMutation,
  useImpersonateUserMutation,
} = rootApi;

function convertToPercentage(input) {
  if (!hasValue(input)) {
    return '';
  }
  // Convert the input to a floating-point number
  const floatValue = parseFloat(input);

  // Check if the conversion resulted in a valid number
  if (isNaN(floatValue)) {
    return '';
  }

  // Convert the float value to a percentage string
  const percentage = `${(floatValue * 100).toFixed(0)}%`;

  return percentage;
}

function getPromoCodeUrl(promoCode) {
  return `https://contractortools.com?promo=${promoCode}`;
}
