import { styled } from '@mui/material/styles';
import MUIDrawer from '@mui/material/Drawer';

export default styled(
  (props) => <MUIDrawer anchor="top" variant="temporary" {...props} />,
  { shouldForwardProp: (prop) => prop !== 'zIndex' }
)(({ theme, zIndex }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  zIndex: zIndex || theme.zIndex.drawer,
  [`& .MuiDrawer-paper`]: {
    overflow: 'hidden',
    height: '100%',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
