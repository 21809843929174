import { selectSubscriptionByEnsemblesId } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';

const SubscriptionsContext = createContext();

export function useSubscriptionsContext() {
  return useContext(SubscriptionsContext);
}

const initialState = {
  errors: {},
  modalBundle: null,
  cancelModalBundle: null,
  plan: {},
};

/**
 * Notes on Errors:
 * - Errors is an object that contains all the errors for the form
 * - as well as a special key 'server' that contains the server errors
 *
 * - The forms in use right now are just in the CancelSubscriptionModal
 * - The server error is used in the SubscriptionsModal component to address
 *   any errors that come from the server-- Actually still not sure about this
 *
 * Server Errors:
 * - Empty string for customer stripe_customer_id ( cardId in onld code )
 */

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.error,
        },
      };
    case 'SET_SERVER_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          server: action.payload,
        },
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: {},
      };
    case 'SET_MODAL_BUNDLE':
      return {
        ...state,
        modalBundle: action.payload,
      };
    case 'SET_CANCEL_MODAL_BUNDLE':
      return {
        ...state,
        cancelModalBundle: action.payload,
      };
    case 'SET_STRIPE_CUSTOMER_ID':
      return {
        ...state,
        stripeCustomerId: action.payload,
      };
    case 'SET_STRIPE':
      return {
        ...state,
        stripe: action.payload,
      };
    default:
      return state;
  }
};

export default function SubscriptionsProvider({ children, root }) {
  const { ensemblesId, isAppleActive, stripe, stripeCustomerId, users } =
    root ?? {};

  const subscription = useSelector(
    selectSubscriptionByEnsemblesId(ensemblesId)
  );

  const subscriptionDependencies = getDependencies(subscription);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...subscription,
    isAppleActive,
    ensemblesId,
    stripe,
    stripeCustomerId,
    usersCount: users?.length ?? 0,
  });

  const setError = (id, error) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { key: id, error },
    });
  };

  const setServerError = (error) => {
    dispatch({
      type: 'SET_SERVER_ERROR',
      payload: error,
    });
  };

  const clearErrors = () => {
    dispatch({ type: 'CLEAR_ERRORS' });
  };

  const setModalBundle = (bundle) => {
    dispatch({ type: 'SET_MODAL_BUNDLE', payload: bundle });
  };

  const setCancelModalBundle = (bundle) => {
    dispatch({ type: 'SET_CANCEL_MODAL_BUNDLE', payload: bundle });
  };

  const setStripeCustomerId = (stripeCustomerId) => {
    dispatch({ type: 'SET_STRIPE_CUSTOMER_ID', payload: stripeCustomerId });
  };

  const setStripe = (stripe) => {
    dispatch({ type: 'SET_STRIPE', payload: stripe });
  };

  const value = useMemo(
    () => ({
      ...state,
      ...subscription,
      hasErrors: !hasNoErrors(state.errors),
      cancelModalBundle: state.cancelModalBundle,
      errors: state.errors,
      modalBundle: state.modalBundle,
      stripe: state.stripe,
      stripeCustomerId: state.stripeCustomerId,
      // Actions
      setError,
      setModalBundle,
      setCancelModalBundle,
      setServerError,
      setStripeCustomerId,
      setStripe,
      clearErrors,
    }),
    [state, ...subscriptionDependencies]
  );

  // useEffect(() => {
  //   console.log('SubscriptionsProvider', value);
  // }, [value]);

  return (
    <SubscriptionsContext.Provider value={value}>
      {children}
    </SubscriptionsContext.Provider>
  );
}

SubscriptionsProvider.propTypes = {
  children: PropTypes.node,
  root: PropTypes.object,
};

// ##############################
// ## Helper functions
// ##############################

function hasNoErrors(errors) {
  // Check if errors is null or undefined
  if (!errors) {
    return true;
  }

  // Check if errors is an object (also ensuring it's not an array)
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    // Get the keys of the errors object
    const keys = Object.keys(errors);

    // Check if the errors object is empty
    if (keys.length === 0) {
      return true;
    }

    // Check each property to see if they are all undefined
    // Or if the property is 'server' we skip it
    for (const key of keys) {
      if (errors[key] !== undefined && key !== 'server') {
        return false; // Found an error
      }
    }

    // All properties are undefined
    return true;
  }

  // In case errors is not an object
  return false;
}

// ##############################
// ## Helper functions
// ##############################

function getDependencies(subscription) {
  const { costbooks, extraUsers, plan, users } = subscription ?? {};

  const {
    product_id: planProductId,
    is_cancelled: isPlanCancelled,
    subscribed: isPlanSubscribed,
    stripe_subscription_id: planSubId,
  } = plan ?? {};

  const {
    product_id: costbooksProductId,
    is_cancelled: isCostbooksCancelled,
    subscribed: isCostbooksSubscribed,
    stripe_subscription_id: costbooksSubId,
  } = costbooks ?? {};

  const {
    product_id: extraUsersProductId,
    is_cancelled: isExtraUsersCancelled,
    subscribed: isExtraUsersSubscribed,
    stripe_subscription_id: extraUsersSubId,
  } = extraUsers ?? {};

  return [
    planProductId,
    costbooksProductId,
    extraUsersProductId,
    isPlanCancelled,
    isCostbooksCancelled,
    isExtraUsersCancelled,
    isPlanSubscribed,
    isCostbooksSubscribed,
    isExtraUsersSubscribed,
    planSubId,
    costbooksSubId,
    extraUsersSubId,
    users?.length,
  ];
}
