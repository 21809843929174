import { getAdmin } from 'lib/storage/admin';
import { getUser } from 'lib/storage/user';
import safeJsonParse from 'utils/safeJsonParse';

export {
  checkPermissions,
  permissions,
  roles,
  routesAccessControlMap,
} from './checkPermissions';
export { featureEnabled, features } from './featureEnabled';

export const verifyIsAdmin = (user = null) => {
  let loggedInUser = user;
  if (!loggedInUser) {
    // If we don't have a user passed in, get the user from local storage
    // from the userSession file
    loggedInUser = getUser();
  }

  if (!loggedInUser) {
    return false;
  }
  return loggedInUser?.admin === 1;
};

export const verifyIsContractor = () => {
  const loggedInUser = getUser();
  if (!loggedInUser) {
    return false;
  }
  return !loggedInUser?.customer_id;
};

export const verifyIsImperonating = () => {
  const admin = getAdmin();
  const impersonating = safeJsonParse(
    localStorage.getItem('impersonating'),
    null
  );
  return !!admin && !!impersonating;
};
