import userSession from 'lib/storage/user';
import { features } from './featureEnabled';

export const checkPermissions = (permissionsNeeded, user) => {
  if (!permissionsNeeded) {
    return true;
  }

  const loggedInUser = user || userSession.getUser();

  if (!loggedInUser || !loggedInUser.role) {
    return false;
  }

  // Check if `permissionsNeeded` is an array (any permission from array must pass)
  if (Array.isArray(permissionsNeeded)) {
    return permissionsNeeded.some((permission) =>
      checkPermissionString(permission, loggedInUser.role)
    );
  }

  // If it's not an array, check a single string permission (all must pass)
  return checkPermissionString(permissionsNeeded, loggedInUser.role);
};

/**
 * Checks if the user's role includes all the permissions required
 * @param  {string} permissionsNeeded Comma-separated string of permissions
 * @param  {string} role User's role
 * @return {boolean} True if the role has all the required permissions
 */
function checkPermissionString(permissionsNeeded, role) {
  // Convert permissions string to array
  const permissionsArray = permissionsNeeded.split(',');

  // Get the role's permissions (or empty array if role not found)
  const rolePermissions = roles[role] || [];

  // Ensure every permission in `permissionsArray` exists in `rolePermissions`
  const passed = permissionsArray.every((permission) =>
    rolePermissions.includes(permission)
  );

  return passed;
}

export const permissions = Object.freeze({
  affiliateData: 'affiliateData_view',
  billingSettings: 'billingSettings_view',
  customers: 'customers_view',
  customerJobs: 'customerJobs_view',
  customerJobChangeOrders: 'customerJobChangeOrders_view',
  customerJobInvoices: 'customerJobInvoices_view',
  customerJobPayments: 'customerJobPayments_view',
  customerJobEstimates: 'customerJobEstimates_view',
  jobs: 'jobs_view',
  jobChangeOrders: 'jobChangeOrders_view',
  jobInvoices: 'jobInvoices_view',
  jobPayments: 'jobPayments_view',
  jobEstimates: 'jobEstimates_view',
  paymentSettings: 'paymentSettings_view',
  subscriptionSettings: 'subscriptionSettings_view',
});

export const roles = Object.freeze({
  CONTRACTOR_USER: [
    permissions.affiliateData,
    permissions.billingSettings,
    permissions.customers,
    permissions.customerJobs,
    permissions.customerJobChangeOrders,
    permissions.customerJobInvoices,
    permissions.customerJobPayments,
    permissions.customerJobEstimates,
    permissions.jobs,
    permissions.jobChangeOrders,
    permissions.jobInvoices,
    permissions.jobPayments,
    permissions.jobEstimates,
    permissions.paymentSettings,
    permissions.subscriptionSettings,
  ],
  CUSTOMER_USER: [
    permissions.affiliateData,
    permissions.jobs,
    permissions.jobChangeOrders,
    permissions.jobInvoices,
    permissions.jobPayments,
    permissions.jobEstimates,
  ],
  AFFILIATE_USER: [
    permissions.affiliateData,
    permissions.paymentSettings,
    permissions.billingSettings,
  ],
});

// This is janky place to put this, but it's the only place that makes sense
// Having here makes it easy to compare values to the permissions above
// We have to import features for just a small amount that I'm ok with this
// for now. If this becomes a problem, we can move this to a separate file
export const routesAccessControlMap = {
  affiliate: {
    permissions: permissions.affiliateData,
    features: null,
  },
  // Customer Routes
  customers: {
    permissions: permissions.customers,
    features: null,
  },
  customerJobs: {
    permissions: permissions.customerJobs,
    features: null,
  },
  customerJobChangeOrders: {
    permissions: permissions.customerJobChangeOrders,
    features: null,
  },
  customerJobEstimates: {
    permissions: permissions.customerJobEstimates,
    features: null,
  },
  customerJobInvoices: {
    permissions: permissions.customerJobInvoices,
    features: null,
  },
  customerJobPayments: {
    permissions: permissions.customerJobPayments,
    features: null,
  },
  // Job Routes
  jobs: {
    permissions: permissions.jobs,
    features: null,
  },
  jobChangeOrders: {
    permissions: permissions.jobChangeOrders,
    features: features.changeOrders,
  },
  jobEstimates: {
    permissions: permissions.jobEstimates,
    features: features.estimates,
  },
  jobInvoices: {
    permissions: permissions.jobInvoices,
    features: features.invoicesAndPayments,
  },
  jobPayments: {
    permissions: permissions.jobPayments,
    features: features.invoicesAndPayments,
  },
  // Settings Routes
  billingSettings: {
    permissions: permissions.billingSettings,
    features: null,
  },
  paymentsSettings: {
    permissions: permissions.paymentSettings,
    features: null,
  },
  subscriptionSettings: {
    permissions: permissions.subscriptionSettings,
    features: null,
  },
};
