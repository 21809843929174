import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import { AlternatePrice, Price } from './Styles';

export default function PlanCard({ plan, renewalPeriod = 'monthly' }) {
  const { name, subscriptions, users, callouts } = plan ?? {};
  const { monthly, yearly } = subscriptions ?? {};

  const price =
    renewalPeriod === 'monthly'
      ? monthly.formatted_price
      : yearly.formatted_price;
  const priceText = renewalPeriod === 'monthly' ? 'mo' : 'yr';

  const alternatePrice =
    renewalPeriod === 'monthly'
      ? yearly.formatted_price
      : monthly.formatted_price;
  const alternatePriceText = renewalPeriod === 'monthly' ? 'yr' : 'mo';
  const plural = users > 1 ? 's' : '';

  return (
    <PlanPanel>
      <Typography variant="h5">{name}</Typography>
      <Divider sx={{ width: '3.125em', my: 2 }} />
      <Price>
        {price}
        <span>/{priceText}</span>
      </Price>
      <AlternatePrice>
        {alternatePrice}
        <span>/{alternatePriceText}</span>
      </AlternatePrice>
      <UsersLabel>
        {users} User{plural}
      </UsersLabel>
      <CalloutList>
        {callouts.defaults.map((callout) => (
          <Callout key={callout}>
            <span>
              <Dot />
            </span>
            {callout}
          </Callout>
        ))}
        {callouts.extras.map((callout) => (
          <CalloutHightlight key={callout}>
            <span>
              <Icon icon={faCheck} />
            </span>
            {callout}
          </CalloutHightlight>
        ))}
      </CalloutList>
    </PlanPanel>
  );
}

PlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subscriptions: PropTypes.shape({
      monthly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
      yearly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
    }),
    users: PropTypes.number,
    callouts: PropTypes.shape({
      defaults: PropTypes.arrayOf(PropTypes.string),
      extras: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  renewalPeriod: PropTypes.oneOf(['monthly', 'yearly']),
};

// ##############################
// ### Plan Styled Components
// ##############################

const PlanPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%',
  height: '480px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const UsersLabel = styled((props) => <Typography variant="body1" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.dark,
    padding: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  })
);

const CalloutList = styled((props) => <Box component="ul" {...props} />)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: 0,
    paddingLeft: theme.spacing(1),
    '& li': {
      marginTop: theme.spacing(0.25),
    },
  })
);

const Callout = styled((props) => <Box component="li" {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.charcoal.dark,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    listStyle: 'none',
    '& span': {
      display: 'block',
      margin: 0,
      padding: 0,
      marginRight: theme.spacing(1),
    },
  })
);

const CalloutHightlight = styled((props) => <Callout {...props} />)(
  ({ theme }) => ({
    color: theme.palette.green.main,
    span: {
      color: theme.palette.green.main,
      display: 'block',
      position: 'relative',
      left: '-4px',
    },
  })
);

const Dot = styled((props) => <Box component="span" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.charcoal.light,
    borderRadius: '50%',
    display: 'block',
    height: '6px',
    width: '6px',
  })
);
