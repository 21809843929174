import { createSlice } from '@reduxjs/toolkit';
import userSession from 'lib/storage/user';

const defaultValues = {
  invalidToken: true,
  token: undefined,
  customer_id: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: defaultValues,
  reducers: {
    invalidateToken(state) {
      return defaultValues;
    },

    setUser(state, action) {
      const { payload } = action;

      if (!payload.token || !payload.exp) {
        console.error(
          'No token or exp provided in payload. invalidToken!',
          payload
        );
      }

      return {
        ...state,
        ...payload,
        invalidToken: userSession.tokenHasExpired(payload.token),
      };
    },
    setUserToAffiliate(state) {
      return {
        ...state,
        role: 'AFFILIATE_USER',
      };
    },
  },
});

export const { invalidateToken, setUser, setUserToAffiliate } =
  userSlice.actions;

// Not doing any modification to the user so we can just return it
export const selectUser = (state) => state.user;
// export const selectUser = createSelector(
//   (state) => state.user, // extract user from state
//   (user) => user // derive user from state to memoize
// );

export const selectImpersonated = (state) => state.user?.impersonated;

export default userSlice.reducer;
