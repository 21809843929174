import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const PlanStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '296px',
  },
}));

export const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  '& > button': {
    marginBottom: theme.spacing(2),
  },
}));

export const AddOnStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%',
  height: '480px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  // border: `1px solid ${theme.palette.grey[300]}`,
}));

export const AddOnImage = styled('img')(({ theme }) => ({
  width: 'auto',
  height: '156px',
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

export const Price = styled((props) => <Typography variant="h4" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    '& span': {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export const AlternatePrice = styled((props) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey.dark,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  '& span': {
    marginLeft: '2px',
  },
}));
