import {
  selectSubscriptionByEnsemblesId,
  updatePlan,
} from 'lib/store/rootSlice';
import {subscriptionsApi} from 'services/subscriptionsService';
import {dispatchAndUnwrap, dispatchMultipleEndpoints} from 'utils/api';
import {
  getNewTempExpirationDateForSubscription,
  getPurchaseDateForSubscription,
  isExpired,
  setAppleDateForTrialEndDate,
  setTrialEndDate,
} from 'utils/dates';
import {sleep} from '../../utils/helpers';
import {findSubscription} from './data/plans';

export function cancelSubscription({ isCostbooks, isExtraUsers }) {
  // Determine the appropriate endpoint based on the flags
  let endpoint = subscriptionsApi.endpoints.cancelPlan;
  
  if (isExtraUsers) {
    endpoint = subscriptionsApi.endpoints.cancelExtraUsers;
  }
  if (isCostbooks) {
    endpoint = subscriptionsApi.endpoints.cancelCostbooks;
  }

  // Return the curried async arrow function
  return async ({
    ensemblesId,
    reason,
    store,
    stripe_subscription_id,
    stripe_customer_id,
  }) => {
    try {
      const result = await dispatchAndUnwrap(
        store,
        endpoint,
        {
          stripe_subscription_id,
          stripe_customer_id,
          reason,
          ensemblesId,
        }
      );

      // Instead of invalidating the cache, we force call the getPlan endpoint.
      // This approach ensures the server has updated the data before we fetch it.
      await sleep(256);
      await getSubscriptions({
        ensemblesId,
        store,
        forceRefetch: true,
      });

      return { success: true, error: null, result };
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      return {
        success: false,
        error,
      };
    }
  };
}

export async function getSubscriptions({
  ensemblesId,
  store,
  forceRefetch = false,
}) {
  try {
    const results = await dispatchMultipleEndpoints(
      store,
      [
        {
          endpoint: subscriptionsApi.endpoints.getCostbooks,
          params: { ensemblesId },
        },
        {
          endpoint: subscriptionsApi.endpoints.getExtraUsers,
          params: { ensemblesId },
        },
        {
          endpoint: subscriptionsApi.endpoints.getPlan,
          params: { ensemblesId },
        },
      ],
      forceRefetch
    );
    const [costbooks, extraUsers, plan] = results ?? [];
    const isAppleActive =
      plan?.source === 0 && !isExpired(plan?.expiration_date);

    return {
      costbooks,
      extraUsers,
      isAppleActive,
      plan,
    };
  } catch (error) {
    console.error('Error getting all the things:', error);
  }
}

/**
 * Creates a new subscription.
 * Used outside of the subscription flow, such as when a user
 * transitions from Apple to Stripe.
 *
 * Another use case is when adding a new company.
 *
 * @param {Object} params - The subscription parameters.
 * @param {string} params.appleDate - The Apple date in ISO string format.
 * @param {string} params.ensemblesId - The ensembles_id value from the selected company.
 * @param {string} params.stripeCustomerId - The stripe_customer_id value from the selected company.
 * @param {string} params.subscriptionName - The ID of the Stripe plan id. Examples: 'basic_monthly', 'basic_annual', etc.
 * @param {Object} params.store - The store object.
 * @returns {Promise} A promise that resolves when the subscription is created.
 */
export async function createSubscription({
  appleDate,
  ensemblesId,
  subscriptionName,
  stripeCustomerId,
  store,
  freeTrial = false,
}) {
  // Guard clauses
  // TODO: Figure out how to deal with these.
  //  Example for stipreId we should probably notify the user to add a credit card
  if (!subscriptionName) {
    return { success: false, error: 'No subscription name.' };
  }

  if (!stripeCustomerId) {
    return { success: false, error: 'No stripe customer id.' };
  }

  const isCostbooksPlan = subscriptionName.includes('craftsman');

  const isExtraUsersPlan = subscriptionName.includes('extra_users');

  // Used to build up the data for the subscription
  const payload = {
    stripe_customer_id: stripeCustomerId,
    stripe_plan_id: subscriptionName,
  };

  // For Basic, Essentials, or Pro plans, if subscription purchaseDate
  // is greater than 1 month ago, set trial_end to now.
  if (!isCostbooksPlan && !isExtraUsersPlan && !appleDate && !freeTrial) {
    const { plan } = selectSubscriptionByEnsemblesId(
      ensemblesId,
      store.getState()
    );
    payload.trial_end = setTrialEndDate(plan);
  }

  // If the appleDate is set, then set trial_end to the same date as appleDate
  if (appleDate) {
    payload.trial_end = setAppleDateForTrialEndDate(appleDate);
  }

  // We have the payload now. Let's create the subscription
  // and update the plan in the store
  try {
    const result = await dispatchAndUnwrap(
      store,
      subscriptionsApi.endpoints.createSubscription,
      payload
    );

    return await handleUpdatingPlan({
      appleDate,
      data: result,
      ensemblesId,
      subscriptionName,
      store,
    });
  } catch (error) {
    console.error('Error creating subscription:', error);
    return {
      success: false,
      error: 'Unable to create subscription. Please try again.',
    };
  }
}

export async function renewSubscription({
  trialEnd,
  stripeCustomerId,
  subscriptionName,
  ensemblesId,
  store,
}) {
  const result = await dispatchAndUnwrap(
    store,
    subscriptionsApi.endpoints.createSubscription,
    {
      stripe_customer_id: stripeCustomerId,
      stripe_plan_id: subscriptionName,
      trial_end: trialEnd,
    }
  );
  await sleep(1375);
  await getSubscriptions({
    ensemblesId,
    store,
    forceRefetch: true,
  });
  return { success: true, error: null, result };
}

export async function updateSubscription({
  trialEnd,
  stripeCustomerId,
  subscriptionName,
  stripeSubscriptionId,
  ensemblesId,
  store,
}) {
  const result = await dispatchAndUnwrap(
    store,
    subscriptionsApi.endpoints.updateSubscription,
    {
      stripe_customer_id: stripeCustomerId,
      stripe_subscription_id: stripeSubscriptionId,
      stripe_plan_id: subscriptionName,
      trial_end: trialEnd,
    }
  );
  await sleep(375);
  await getSubscriptions({
    ensemblesId,
    store,
    forceRefetch: true,
  });
  return { success: true, error: null, result };
}

// ############################################
// ### UPDATE HANDLERS FOR CREATE SUBSCRIPTION
// ############################################

/**
 * handleUpdatingPlan in store after creating a subscription.
 * @param {*} param0
 * @returns
 */
async function handleUpdatingPlan({
  appleDate,
  data,
  ensemblesId,
  subscriptionName,
  store,
}) {
  const stripeSubscriptionId = data?.subscription?.id;

  const subscriptionStatus = data?.subscription?.status;

  const isSubscribed = Boolean(
    subscriptionStatus === 'active' || subscriptionStatus === 'trialing'
  );

  const newPlan = findSubscription(subscriptionName);

  if (newPlan) {
    store.dispatch(
      updatePlan({
        ensemblesId,
        plan: {
          ...newPlan,
          purchase_date: getPurchaseDateForSubscription(data),
          expiration_date: getNewTempExpirationDateForSubscription({
            appleDate,
            plan: newPlan,
          }),
          stripe_subscription_id: stripeSubscriptionId, // e.g., sub_1234567890
          stripe_plan_id: subscriptionName, // e.g., basic_monthly
          currency: data?.subscription?.currency.toUpperCase(),
          cancelled: !isSubscribed,
          subscribed: isSubscribed,
          source: 1,
          extraUseres: { subscribed: false },
          costbooks: { subscribed: false },
          renewalPeriod: newPlan?.product_id < 11 ? 'monthly' : 'yearly',
          renewalPeriodOpposite: newPlan?.product_id < 11 ? 'yearly' : 'monthly',
        },
      })
    );
    return { success: true, error: null, plan: newPlan };
  }
}
