export const appleToStripeProductId = {
  1: 'basic_monthly',
  2: 'essentials_monthly',
  3: 'pro_monthly',
  5: 'craftsman_monthly',
};

export const subscriptions = {
  messages: {
    billMeMonthly: (name) =>
      `You will now be billed monthly for your ContractorTools ${name} plan.`,
    billMeYearly: (name) =>
      `You will now be billed yearly for your ContractorTools ${name} plan.`,
    cancelSubscription:
      'We would really appreciate it if you could let us know why you would like to cancel. Our goal is to make ContractorTools a great app at a great price, so your feedback is very important to us. Feel free to give us a call at (888)802-1040 if you have any questions or suggestions.',
    downgradeToBasics:
      'Downgrading to Basic will remove the ability to create invoices and payments.',
    downgradeToEssentials:
      'Downgrading to Essentials will remove the ability to create change orders, credits, draw schedules, multiple invoices per job, multiple payments per job.',
    upgradeToEssentials:
      'Upgrading to Essentials will add the ability to create a single invoice per job and a single payment per job.',
    upgradeToPro:
      'Upgrading to Pro will add the ability to create change orders, credits, draw schedules, multiple invoices per job, multiple payments per job.',
  },
  cancelLabels: {
    'Not good fit': {
      label: "It's not what I'm looking for, it doesn't fit my business needs",
      value: 'Not good fit',
    },
    'Too complicated': {
      label: "It's too complicated, I can't get it to work for me",
      value: 'Too complicated',
    },
    Cost: {
      label: "It costs too much, I can't afford it",
      value: 'Cost',
    },
    Features: {
      label:
        'I need more functions, like scheduling, project management and cost tracking',
      value: 'Features',
    },
    Other: {
      label: 'Other',
      value: 'Other',
    },
  },
};