import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {api} from 'services/api';
import {authApi} from 'services/authService';
import {billingApi} from 'services/billingService';
import {companiesApi} from 'services/companiesService';
import {customersApi} from 'services/customersService';
import {demoApi} from 'services/demoService';
import {jobsApi} from 'services/jobsService';
import {paymentsApi} from 'services/paymentsService';
import {publicApi} from 'services/publicService';
import {rootApi} from 'services/rootService';
import {subscriptionsApi} from 'services/subscriptionsService';
import root from './rootSlice';
import user from './userSlice';

const rootReducer = combineReducers({
  root,
  user,
  [authApi.reducerPath]: authApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [demoApi.reducerPath]: demoApi.reducer,
});

export const initializeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
};
