import Typography from '@mui/material/Typography';
import ArrowButton from 'components/Buttons/ArrowButton';
import CloseButton from 'components/Buttons/CloseButton';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { useSuccessSnackbar } from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubscription,
  renewSubscription,
  updateSubscription,
} from '../actions';
import { useModal } from '../hooks/useModal';
import { useErrorManager, useValidation } from '../hooks/useSubscriptions';
import { useSubscriptionsContext } from '../providers/SubscriptionsProvider';

export default function SubscriptionsModal({ mobile: isMobile }) {
  const { isOpen, closeModal } = useModal();

  return (
    <Modal open={isOpen} onClose={closeModal} fullScreen={isMobile} size="md">
      <Content mobile={isMobile} onClose={closeModal} />
    </Modal>
  );
}

SubscriptionsModal.propTypes = {
  mobile: PropTypes.bool,
};

function Content({ mobile: isMobile, onClose: handleClose }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const setSuccessSnackbar = useSuccessSnackbar();

  const [isLoading, setIsloading] = useState(false);
  const { modalBundle: bundle } = useSubscriptionsContext();
  const { messageBuilder: builder, label, title, key, payload } = bundle ?? {};
  const message = buildMessage(builder);
  const { handleError } = useErrorManager(payload, key);
  const { validate } = useValidation(payload, key);

  // submit onClick
  const handleSubmit = useCallback(async () => {
    try {
      setIsloading(true);
      const store = {
        dispatch,
        getState: () => state,
      };

      const { error: validationError } = validate();
      if (validationError) {
        throw validationError;
      }

      const submit = getAction({ ...payload, store });
      const { error } = await submit();

      if (error) {
        console.error('Error submitting!');
        throw error;
      }

      setSuccessSnackbar(getSuccessMessage(bundle));
    } catch (err) {
      console.error('Error:', err);
      await handleError(err);
    } finally {
      setIsloading(false);
      handleClose();
    }
  }, [key]);

  return (
    <>
      <ModalHeader onClose={handleClose}>{title}</ModalHeader>
      <ModalBody>
        <Typography variant="body1">{message}</Typography>
      </ModalBody>
      <ModalFooter id="modal-footer-subscriptions">
        <CloseButton fullWidth={isMobile} onClick={handleClose}>
          Cancel
        </CloseButton>
        <ArrowButton
          fullWidth={isMobile}
          primary
          onClick={handleSubmit}
          loading={isLoading}
        >
          {label}
        </ArrowButton>
      </ModalFooter>
    </>
  );
}

Content.propTypes = {
  mobile: PropTypes.bool,
  onClose: PropTypes.func,
};

// ##############################
// ### Helper functions
// ##############################

const buildMessage = (builder) => {
  const { text: message, variables } = builder ?? {};
  // Split the message into parts and filter out empty strings
  // based on the regex pattern to find {variables}
  const parts = message?.split(/(\{[^}]+\})/g)?.filter(Boolean);

  return parts?.map((part) => {
    // Strip the curly braces from the variable
    // and check if it exists in the variables object
    const key = part.replace(/[{}]/g, '');
    if (variables?.[key]) {
      return (
        <span key={key} className="strong">
          {variables[key]}
        </span>
      );
    }
    return part;
  });
};

const getAction = (data) => {
  const { key, ...payload } = data;

  if (key === 'createFreeTrialSubscription') {
    payload.freeTrial = true;
  }

  const action =
    {
      createFreeTrialSubscription: createSubscription,
      renewSubscription,
      updateSubscription,
    }[key] ?? (() => {});

  return () => action(payload);
};

export function getSuccessMessage(bundle) {
  const { payload } = bundle ?? {};
  const { subscriptionName } = payload;
  const payloadKey = getPayloadKey(bundle);

  const subscriptionMap = {
    basic_annual: 'Basic (Yearly)',
    basic_monthly: 'Basic (Monthly)',
    essentials_annual: 'Essentials (Yearly)',
    essentials_monthly: 'Essentials (Monthly)',
    pro_annual: 'Pro (Yearly)',
    pro_monthly: 'Pro (Monthly)',
    craftsman_monthly: 'Craftsman (Monthly)',
    craftsman_annual: 'Craftsman (Yearly)',
    extra_users: 'Extra Users',
  };

  const actionMap = {
    updated: 'updated to',
    upgrade: 'upgraded to',
    downgrade: 'downgraded to',
    renew: 'renewed as',
    freeTrial: 'started a free trial of',
  };

  const subscriptionLabel =
    subscriptionMap[subscriptionName] || 'Unknown Subscription';
  const actionLabel = actionMap[payloadKey] || 'processed';

  const successMessage = `You have successfully ${actionLabel} ${subscriptionLabel} subscription.`;

  return successMessage;
}

function getPayloadKey(bundle) {
  const { label } = bundle ?? {};
  const lowercaseLabel = label?.toLowerCase() || '';
  let mapValue = 'freeTrial';

  if (lowercaseLabel.includes('bill me')) {
    mapValue = 'updated';
  }

  if (lowercaseLabel.includes('upgrade')) {
    mapValue = 'upgrade';
  }

  if (lowercaseLabel.includes('downgrade')) {
    mapValue = 'downgrade';
  }

  if (lowercaseLabel.includes('renew')) {
    mapValue = 'renew';
  }

  return mapValue;
}
