import { clearUserSession } from 'features/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useTheme } from '@mui/material/styles';
import { sleep } from 'utils/helpers';
import { COMPONENT_KEYS } from 'data/constants';

export function useSignout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalContext = useComponentStateContext();
  const open = modalContext.state?.open[COMPONENT_KEYS.SIGNOUT_MODAL] || false;
  const loading =
    modalContext.state?.loading[COMPONENT_KEYS.SIGNOUT_MODAL] || false;

  const theme = useTheme();

  const handleSignout = useCallback(async () => {
    modalContext.setLoading(COMPONENT_KEYS.SIGNOUT_MODAL)(true);
    await sleep(theme.transitions.duration.complex);
    clearUserSession(dispatch);
    navigate('/login', { replace: true });
    modalContext.setClose(COMPONENT_KEYS.SIGNOUT_MODAL)();
  }, []);

  const handleClose = useCallback(() => {
    modalContext.setClose(COMPONENT_KEYS.SIGNOUT_MODAL)();
  }, []);

  const handleOpen = useCallback(() => {
    modalContext.setOpen(COMPONENT_KEYS.SIGNOUT_MODAL)();
  }, []);

  return {
    handleClose,
    handleOpen,
    handleSignout,
    id: COMPONENT_KEYS.SIGNOUT_MODAL,
    loading,
    open,
  };
}
