import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { styled, useTheme } from '@mui/material/styles';
import { useCustomerCardImage } from '../hooks/useBilling';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CreditCard({ companyName, ensemblesId }) {
  const card = useCustomerCardImage(companyName, ensemblesId);
  const { last4, expMonth, expYear, none = true } = card ?? {};
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('tablet'));

  return (
    <>
      {matches ? (
        <StyledCard none={none}>
          <CardCompany>{none ? 'No Card On File' : companyName}</CardCompany>
          <CardNumber>
            <NumberSet>XXXX</NumberSet>
            <NumberSet>XXXX</NumberSet>
            <NumberSet>XXXX</NumberSet>
            <NumberSet isReal>{last4}</NumberSet>
          </CardNumber>
          <CardBottom>
            <Chip>
              <ChipLine />
              <ChipLine />
              <ChipLine />
              <ChipLine />
              <ChipMain />
            </Chip>
            <Expiration>
              <ExpLabel>
                Good
                <br />
                Thru:
              </ExpLabel>
              <ExpDate>
                {none
                  ? 'N/A'
                  : `${expMonth?.toString() || ''}/${expYear
                      ?.toString()
                      .slice(-2)}`}
              </ExpDate>
            </Expiration>
          </CardBottom>
        </StyledCard>
      ) : (
        <MiniCard>
          <MiniDisplay>{`Card Ending in: ${last4 || ''}`}</MiniDisplay>
          <Subtitle>Expiration</Subtitle>
          <MiniRow>
            <MiniColumn>
              <Typography variant="body2">Month</Typography>
              <Typography variant="body2" className="mini-col-date">
                {expMonth?.toString() || ''}
              </Typography>
            </MiniColumn>
            <MiniColumn>
              <Typography variant="body2">Year</Typography>
              <Typography variant="body2" className="mini-col-date">
                {expYear?.toString().slice(-2)}
              </Typography>
            </MiniColumn>
          </MiniRow>
        </MiniCard>
      )}
    </>
  );
}

CreditCard.propTypes = {
  // card: PropTypes.shape({
  //   none: PropTypes.bool,
  //   companyName: PropTypes.string,
  //   last4: PropTypes.string,
  //   expMonth: PropTypes.number,
  //   expYear: PropTypes.number,
  // }),
  companyName: PropTypes.string,
  ensemblesId: PropTypes.string,
};

// Styled Components

const StyledCard = styled((props) => <Box {...props} />, {
  shouldForwardProp: (prop) => prop !== 'none',
})(({ theme, none }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  height: '200px',
  boxShadow: `0px 5px 10px -6px ${theme.palette.common.black}`,
  color: theme.palette.common.white,
  borderRadius: '12px',
  background: none
    ? `linear-gradient(145deg, ${theme.palette.charcoal.main} 0%, ${theme.palette.grey.dark} 100%)`
    : `linear-gradient(145deg, ${theme.palette.blue.main} 0%, ${theme.palette.blue.dark} 100%)`,
  padding: theme.spacing(5),
}));

const CardCompany = styled(({ ...props }) => <Box {...props} />)(
  ({ theme }) => ({
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
    margin: 0,
    lineHeight: '1em',
    fontSize: '1.3em',
    maxWidth: '16ch',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
);

const CardNumber = styled((props) => <Box {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isReal',
})(({ theme, isReal }) => ({
  marginBottom: theme.spacing(isReal ? 1 : 2),
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  alignItems: 'baseline',
  fontSize: '1.2em',
}));

const NumberSet = styled((props) => <Box {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isReal',
})(({ theme, isReal }) => ({
  color: rgba(theme.palette.blue.light, 0.7),
  ...(isReal
    ? {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '1.1em',
        color: theme.palette.common.white,
      }
    : {
        color: rgba(theme.palette.common.white, 0.5),
      }),
}));

const CardBottom = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Expiration = styled(Box)(({ _theme }) => ({
  // color: theme.palette.blue.light,
}));

const ExpLabel = styled(Box)(({ _theme }) => ({
  display: 'inline-block',
  fontSize: '0.5em',
  textTransform: 'uppercase',
  marginRight: '5px',
  lineHeight: '8px',
}));

const ExpDate = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  fontSize: '1.3em',
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
}));

const Chip = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '40px',
  borderRadius: '5px',
  backgroundImage: 'linear-gradient(to bottom left, #ffecc7, #d0b978)',
  overflow: 'hidden',
});

const ChipLine = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '1px',
  backgroundColor: '#333',
  '&:nth-of-type(1)': {
    top: '13px',
  },
  '&:nth-of-type(2)': {
    top: '20px',
  },
  '&:nth-of-type(3)': {
    top: '28px',
  },
  '&:nth-of-type(4)': {
    left: '25px',
    width: '1px',
    height: '50px',
  },
});

const ChipMain = styled(Box)({
  width: '20px',
  height: '25px',
  border: '1px solid #333',
  borderRadius: '3px',
  backgroundImage: 'linear-gradient(to bottom left, #efdbab, #e1cb94)',
  zIndex: 1,
});

const MiniCard = styled(Box)(({ _theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
}));

const MiniDisplay = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey.dark,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  height: 56,
  width: '100%',
}));

const MiniRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
  width: '100%',
}));

const MiniColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& .mini-col-date': {
    color: theme.palette.grey.dark,
  },
}));

const Subtitle = styled((props) => (
  <Typography variant="subtitle2" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'block',
  width: '100%',
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
}));
