import {
  getCompaniesForUser,
  getSelectedCompanyOnRefresh,
} from 'features/company';
import adminSession from 'lib/storage/admin';
import userSession from 'lib/storage/user';
import { reset, setAffiliateData } from 'lib/store/rootSlice';
import {
  invalidateToken,
  setUser,
  setUserToAffiliate,
} from 'lib/store/userSlice';
import { api } from 'services/api';
import { rootApi } from 'services/rootService';
import { dispatchAndUnwrap } from 'utils/api';
import { hasValue, sleep } from 'utils/helpers';

// ############################################### //
// ### Helper Methods For auth methods
// ############################################### //

export async function authenticateUser(store) {
  // Get user from local storage
  const user = userSession.getUser();
  const hasTokenExpired = userSession.tokenHasExpired(user?.token);

  if (user?.token && !hasTokenExpired) {
    return user;
  }

  // No user in store or token has expired
  let tokenFromCookie = userSession.getUserCookie();

  if (
    hasValue(tokenFromCookie) &&
    !userSession.tokenHasExpired(tokenFromCookie)
  ) {
    const userFromCookie = await getUserFromTokenAndSetUserInCache(
      tokenFromCookie,
      store
    );
    // User from cookie doesn't have the user from slice's invalidToken
    // That is managed in the slice But if token has not expired, we can return the user with a invalidToken of false
    return { ...userFromCookie, invalidToken: false };
  }

  // If we get here, we don't have a user with a valid token in the cookie or the store
  // So we send back an object of invalidToken: true
  console.log(`%c>>> INVALID TOKEN`, 'color #fcba03');
  return { invalidToken: true };
}

export async function getUserFromTokenAndSetUserInCache(token, store) {
  const tokenUser = userSession.createUserDataFromToken(token);

  const currentUser = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.getUser,
    token,
    true
  );

  const user = { ...tokenUser, ...currentUser };

  userSession.setUser(user);
  userSession.setUserCookie(user);
  store.dispatch(setUser(user));

  return user;
}

export function clearUserSession(dispatch) {
  // Removes local storage user, cookie, and any selected company
  userSession.removeUser();
  adminSession.removeAdmin();
  // Marks user in store as invalid
  dispatch(api.util.resetApiState());
  dispatch(invalidateToken());
  dispatch(reset());
}

export async function impersonateUser({ authData, id, store }) {
  const { token } = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.impersonateUser,
    { authData, id }
  );

  adminSession.setImpersonationUser(token);

  // The user we are impersonating
  const user = userSession.createUserDataFromToken(token);

  // Now we need to run bootstrapping stuff again
  await fetchAndSyncUserForAdmin(store, user);
  await fetchAndSyncCompaniesForAdmin(store, user);
  await fetchAffiliateDataForAdmin(store);

  return user;
}

export async function unimpersonateUser(store) {
  await sleep(375);
  const admin = adminSession.signBackInAsAdmin();
  const newUser = { ...admin, impersonated: false, impersonator: null };
  await fetchAndSyncUserForAdmin(store, newUser);
  await fetchAndSyncCompaniesForAdmin(store, newUser);
  await fetchAffiliateDataForAdmin(store);
  return admin;
}

// ############################################### //
// ### Helper Methods FOR USER
// ############################################### //

export async function fetchAndSyncUser(store, user) {
  // Fetch to get the customer_id and id from the user
  const currentUser = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.getUser,
    user.token
  );
  const newUser = { ...user, ...currentUser };
  userSession.setUser(newUser);
  userSession.setUserCookie(newUser);
  store.dispatch(setUser(newUser));

  return newUser;
}

export async function fetchAndSyncCompanies(store, user, forceRefetch = false) {
  const { companies, customerId } = await getCompaniesForUser(
    store,
    user,
    forceRefetch
  );

  // If no companies exists, set user to affiliate to prevent errors
  if (!hasValue(companies)) {
    store.dispatch(setUserToAffiliate());
  }

  // This function eventually sets selected company in the store
  // And updates to store and local storage
  const selectedCompany = getSelectedCompanyOnRefresh(
    store,
    companies,
    customerId
  );

  // Need to update the local storage with the selected company

  return { companies, selectedCompany };
  // throw new Error('Not implemented', 'fetchAndSyncCompanies');
}

export async function fetchAffiliateData(store) {
  // Dispatch the getAffiliateData action and wait for the response

  const affiliateData = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.getAffiliateData
  );

  store.dispatch(setAffiliateData(affiliateData));

  // Return the affiliate data
  return affiliateData;
}

//###############################################
//### Helper Methods For Admin
//###############################################

async function fetchAndSyncUserForAdmin(store, user) {
  // Update store with impersonated user
  store.dispatch(setUser(user));
  // Make call to get customer_id and newer info for impersonated user
  const currentUser = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.getUser,
    user?.token,
    true
  );
  const newUser = { ...user, ...currentUser };
  userSession.setUser(newUser);
  userSession.setUserCookie(newUser);
  store.dispatch(setUser(newUser));

  return newUser;
}

async function fetchAndSyncCompaniesForAdmin(store, user) {
  const { companies, customerId } = await getCompaniesForUser(
    store,
    user,
    true
  );

  // This function eventually sets selected company in the store
  const selectedCompany = getSelectedCompanyOnRefresh(
    store,
    companies,
    customerId
  );

  return { companies, selectedCompany };
}

async function fetchAffiliateDataForAdmin(store) {
  // Dispatch the getAffiliateData action and wait for the response

  const affiliateData = await dispatchAndUnwrap(
    store,
    rootApi.endpoints.getAffiliateData,
    null,
    true
  );

  store.dispatch(setAffiliateData(affiliateData));

  // Return the affiliate data
  return affiliateData;
}

// Need to remove check for isAdmin becuase when they come back they are a user
