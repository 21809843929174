// localStorageHelper.js
// This helper file abstracts the localStorage functionality, providing a unified interface for storing, retrieving, and removing data in a structured and predictable manner.

/**
 * Generates a namespaced key for localStorage to avoid key collisions.
 * The prefix 'SC_LOCAL_STORAGE_' ensures uniqueness across different modules.
 *
 * @param {string} name - The original key name.
 * @return {string} A namespaced key unique to this application.
 */
const getLocalStorageKey = (name) => {
  return `CT_PORTAL_LOCAL_STORAGE_${name}`;
};

/**
 * Stores a value in localStorage under the provided key.
 * Automatically stringifies the value to ensure JSON-compatible storage.
 * Useful for persisting state, preferences, or session data across reloads.
 *
 * @param {string} key - The key under which to store the value.
 * @param {*} value - The value to store.
 */
function setItem(key, value) {
  const safeKey = getLocalStorageKey(key);
  localStorage.setItem(safeKey, JSON.stringify(value));
}

/**
 * Retrieves a value from localStorage by key.
 * Automatically parses the stored string back into its original format.
 * Can return null if the key does not exist, which should be handled appropriately.
 *
 * @param {string} key - The key of the value to retrieve.
 * @return {*} The retrieved value, or null if not found.
 */
function getItem(key) {
  const safeKey = getLocalStorageKey(key);
  const item = localStorage.getItem(safeKey);

  if (item === 'undefined' || item === 'null') {
    localStorage.removeItem(key);
    return null;
  }

  return item ? JSON.parse(item) : null;
}

/**
 * Removes a value from localStorage by key.
 * Use this to clear data that is no longer needed or to reset state.
 *
 * @param {string} key - The key of the value to remove.
 */
function removeItem(key) {
  const safeKey = getLocalStorageKey(key);
  localStorage.removeItem(safeKey);
}

export default {
  setItem,
  getItem,
  removeItem,
};

// Defining commonly used keys as constants for easy reference and to avoid typos in key names.
export const localStorageKeys = {
  // Add Keys here
  // e.g.: TOKEN: 'token',
};
