import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

export default function ModalView({
  children,
  onClose: handleClose,
  open = false,
  size = 'sm',
  fullScreen = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={size}
      sx={{ borderRadius: '16px' }}
    >
      {/* Wrap children in a column stack for easy layout */}
      <Stack sx={{ height: '100%' }}>{children}</Stack>
    </Dialog>
  );
}

ModalView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  leadingIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullScreen: PropTypes.bool,
};
