export const FETCHER_KEY = 'addCompany';

export const FORM_IDS = {
  COMPANY_NAME: 'companyName',
  ZIP_CODE: 'zipCode',
  PROMO_CODE: 'promoCode',
};

export const ROUTES = {
  RESET: 'reset',
  PLANS: 'plans',
  INFO: 'info',
  CARD: 'card',
  SUMMARY: 'summary',
};

export const PLANS = {
  BASIC: 'Basic',
  ESSENTIALS: 'Essentials',
  PRO: 'Pro',
};