import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';

export const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

export const Body = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    paddingBottom: theme.spacing(4),
  },
  '& .strong': {
    fontWeight: 'bold',
  },
}));

export const Footer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 0,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const CloseIcon = styled((props) => (
  <IconButton edge="end" {...props}>
    <Icon size="xs" icon={faTimes} />
  </IconButton>
))(({ theme }) => ({
  width: '40px',
  height: '40px',
  position: 'relative',
  color: theme.palette.charcoal.light,
}));
