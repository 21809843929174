import { styled } from '@mui/material/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';

const AddButton = styled((props) => (
  <Button startIcon={<Icon icon={faPlus} />} success {...props} />
))(({ theme }) => ({
  color: theme.palette.green.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  paddingLeft: theme.spacing(3),
  '&:hover': {
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.dark,
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    paddingLeft: 0,
  },
  ' svg': {
    [theme.breakpoints.down('mobileLandscape')]: {
      display: 'none',
    },
  }
}));

export default AddButton;