import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';
import Button from 'components/Buttons/Button';
import CloseButton from 'components/Buttons/CloseButton';
import DrawerHeader from 'components/Drawer/DrawerHeader';
import { FormErrorAlert, InputErrorText } from 'components/form/Styles';
import Icon from 'components/Icon';
import { useSuccessSnackbar } from 'hooks/useSnackbar';
import { selectSelectedCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { manageCustomerCard } from '../../actions';
import { useCustomerCardDrawer } from '../../hooks/useBilling';
import { useBillingContext } from '../../providers/BillingProvider';

// Decide how to show success message. Snackbard and close drawer
// Slide in from the right a success message probably with a checkmark
// Update the card image in the billing page
// - need to set cards in dictionary with company id as key
// - need to fix selector to get the correct card
export default function CreditCardSlide({ update }) {
  const elements = useElements();
  const { closeDrawer } = useCustomerCardDrawer();
  const { clearErrors, hasErrors, setServerError, stripe } =
    useBillingContext();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const selectedCompany = useSelector(selectSelectedCompany);

  const setSuccessSnackbar = useSuccessSnackbar();

  const handleSubmit = async () => {
    clearErrors(); // Reset errors

    setIsLoading(true);
    try {
      const { error: tokenError, token } = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );
      // const tokenError = new Error('Token Error');
      // const token = { id: '' };

      if (tokenError) {
        throw new Error(
          'An error occurred while adding your card. Please contact support for assistance.\n888-802-1040'
        );
      }

      const { error } = await manageCustomerCard(
        { dispatch },
        { tokenId: token?.id, update, selectedCompany }
      );

      // At this point we're done with the request
      // We either have an error or we're successful
      setIsLoading(false);

      if (error) {
        throw error;
      }

      setSuccessSnackbar(`Card ${update ? ' updated' : 'added'} successfully`);
      closeDrawer();
    } catch (error) {
      console.error('SET_SERVER_ERROR: Billing AddCustomerCard', error);
      setIsLoading(false);
      setServerError(error);
    }
  };

  return (
    <>
      <DrawerHeader title="Credit Card" onClose={closeDrawer} />
      <DrawerPaper>
        <CreditCardForm />
      </DrawerPaper>
      <Footer>
        <CloseButton fullWidth onClick={closeDrawer}>
          Cancel
        </CloseButton>
        <Button
          disabled={hasErrors}
          fullWidth
          primary
          startIcon={<Icon icon={faCreditCard} />}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {update ? 'Update' : 'Add'}
        </Button>
      </Footer>
    </>
  );
}

CreditCardSlide.propTypes = {
  update: PropTypes.bool,
};

function CreditCardForm() {
  const { errors, setError } = useBillingContext();
  const theme = useTheme();

  const stripeStyles = {
    base: {
      fontSize: '16px',
    },
    invalid: {
      color: theme.palette.error.main,
    },
  };

  return (
    <>
      {errors?.server && (
        <FormErrorAlert sx={{ m: 1, mt: 2 }}>{errors?.server}</FormErrorAlert>
      )}
      <CardGrid>
        <CardFormControl
          id="cardNumber"
          label="Card Number"
          error={errors.cardNumber}
        >
          <CardNumberElement
            onChange={setError}
            options={{ style: stripeStyles }}
          />
        </CardFormControl>
        <CardFormControl
          id="cardExpiry"
          label="Expiration"
          error={errors.cardExpiry}
        >
          <CardExpiryElement
            onChange={setError}
            options={{ style: stripeStyles }}
          />
        </CardFormControl>
        <CardFormControl id="cardCvc" label="CVC" error={errors.cardCvc}>
          <CardCvcElement
            onChange={setError}
            options={{ style: stripeStyles }}
          />
        </CardFormControl>
      </CardGrid>
    </>
  );
}

function Footer({ children }) {
  return (
    <>
      <Divider />
      <Stack direction="row" alignItems="center" gap={2} sx={{ p: 2 }}>
        {children}
      </Stack>
    </>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
};

export const DrawerPaper = styled(Box)(({ _theme }) => ({
  flex: 1,
  padding: 0,
  overflowY: 'auto',
}));

// ##############################
// ### Input Field
// ##############################

function CardFormControl({ children, id, label, error }) {
  return (
    <Box sx={{ gridArea: id }}>
      <CardInputLabel htmlFor={id}>
        {label}
        <span className="card-asterisk">*</span>
      </CardInputLabel>
      <CardInput id={id}>{children}</CardInput>
      {error && <InputErrorText>{error}</InputErrorText>}
    </Box>
  );
}

CardFormControl.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

// ##############################
// ### Styles
// ##############################

const CardGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  backgroundColor: theme.palette.background.paper,
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
      'cardNumber cardNumber'
      'cardExpiry cardCvc'
      'submit submit'
    `,
  padding: theme.spacing(2),
}));

const CardInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontSize: '1.1rem',
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(1),
  '&:focus': {
    color: theme.palette.primary.main,
  },
  '& .card-asterisk': {
    display: 'inline-block',
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
}));

export const CardInput = styled((props) => <Box {...props} />)(({ theme }) => ({
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.white,
  border: '1px solid',
  borderColor: theme.palette.charcoal.main,
  fontSize: 16,
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&:focus': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export const AlertBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.charcoal.main,
  '& span': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
