import Divider from '@mui/material/Divider';
import ArrowButton from 'components/Buttons/ArrowButton';
import PasswordTextfield from 'components/form/PasswordTextfield';
import { FormErrorAlert } from 'components/form/Styles';
import Textfield from 'components/form/Textfield';
import { FORM_IDS } from 'data/constants';
import {
  FormCard,
  FormContent,
  FormDescription,
  FormFooter,
  FormHeader,
  FormLink,
  login,
  PageFooter,
  PageLayout,
  validationCallback,
} from 'features/login';
import { useFormInputHandler } from 'hooks/useFormInputHandler';
import { useNavigationIsLoading } from 'hooks/useNavigation';
import PublicIndexLayout from 'layouts/PublicIndexLayout';
import { useCallback, useReducer } from 'react';
import { Form, redirect, useActionData } from 'react-router-dom';

const initialState = {
  errors: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.id]: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default function Login() {
  return (
    <PublicIndexLayout>
      <PageLayout>
        <LoginForm />
        <PageFooter>
          <FormLink to="/forgot-password">Forgot Password?</FormLink>
        </PageFooter>
      </PageLayout>
    </PublicIndexLayout>
  );
}

function LoginForm() {
  const { error } = useActionData() ?? {};
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLoading = useNavigationIsLoading();

  const setError = useCallback((id, error) => {
    dispatch({ type: 'SET_ERROR', payload: { id, error } });
  }, []);

  const {
    disabled,
    getError,
    handleBlur,
    handleChange,
    isValid,
    validationRef,
  } = useFormInputHandler({
    errorContext: { errors: state.errors, setError },
    ids: [FORM_IDS.USER_EMAIL, FORM_IDS.USER_PASSWORD],
    validationCallback,
  });

  if (error) {
    console.log(`%cERROR!!!!`, 'color: #7FFFD4');
    console.log('error', error);
  }

  return (
    <FormCard component={Form} method="post" replace>
      <FormHeader>Sign In</FormHeader>
      {error && (
        <FormErrorAlert sx={{ mb: 2 }} color="error">
          {error}
        </FormErrorAlert>
      )}
      <FormDescription>
        Please enter your credentials to access your jobs, invoices, and more.
      </FormDescription>
      <FormContent>
        <Textfield
          error={getError(FORM_IDS.USER_EMAIL)}
          fullWidth
          id={FORM_IDS.USER_EMAIL}
          label="Email"
          maxLength={128}
          onBlur={handleBlur(FORM_IDS.USER_EMAIL)}
          onChange={handleChange(FORM_IDS.USER_EMAIL)}
          placeholder="Enter your email"
          validationRef={validationRef}
        />
        <PasswordTextfield
          error={getError(FORM_IDS.USER_PASSWORD)}
          fullWidth
          id={FORM_IDS.USER_PASSWORD}
          label="Password"
          maxLength={128}
          onBlur={handleBlur(FORM_IDS.USER_PASSWORD)}
          onChange={handleChange(FORM_IDS.USER_PASSWORD)}
          placeholder="Enter your password"
          validationRef={validationRef}
        />
      </FormContent>
      <Divider />
      <FormFooter>
        <ArrowButton
          name="intent"
          type="submit"
          value="login"
          primary
          fullWidth
          disabled={disabled || !isValid}
          loading={isLoading}
        >
          Sign In
        </ArrowButton>
      </FormFooter>
    </FormCard>
  );
}

// ##############################
// ### Login Action
// ##############################

export async function action({ store, request }) {
  const handleLogin = async (store, request) => {
    try {
      const formData = await request.formData();
      const email = formData.get(FORM_IDS.USER_EMAIL); // 'jwrigh26@gmail.com'
      const password = formData.get(FORM_IDS.USER_PASSWORD); // 'justin123'
      const result = await login(store, { email, password });
      return redirect(result.url);
    } catch (error) {
      const { data } = error ?? {};
      const { title: serverMessage } = data ?? {};
      return {
        success: false,
        error: serverMessage || 'An error occurred while logging in.',
      };
    }
  };

  return await handleLogin(store, request);
}
