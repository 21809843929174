import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import Button from './Button';

export default function CloseButton({
  children,
  label,
  onClick: handleClose,
  ...props
}) {
  return (
    <Button
      fullWidth
      secondary
      startIcon={<Icon icon={faTimes} />}
      onClick={handleClose}
      {...props}
    >
      {children || label || 'Close'}
    </Button>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
};
