import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useReducer } from 'react';

const BillingContext = createContext();

export function useBillingContext() {
  return useContext(BillingContext);
}

const initialState = {
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.error,
        },
      };
    case 'SET_SERVER_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          server: action.payload,
        },
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};

export default function BillingProvider({ children, root }) {
  const { ensemblesId, stripe, selectedCompany } = root ?? {};
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ensemblesId,
    selectedCompany,
    stripe,
  });

  const setError = (event) => {
    const { elementType, error } = event;
    dispatch({
      type: 'SET_ERROR',
      payload: { key: elementType, error: error?.message },
    });
  };

  const setServerError = (error) => {
    dispatch({
      type: 'SET_SERVER_ERROR',
      payload:
        error?.message ||
        'An error occurred while processing your request for billing. Please contact support for assistance.\n888-802-1040',
    });
  };

  const clearErrors = () => {
    dispatch({ type: 'CLEAR_ERRORS' });
  };

  const value = useMemo(
    () => ({
      ...state,
      hasErrors: !hasNoErrors(state.errors),
      setError,
      setServerError,
      clearErrors,
    }),
    [state]
  );

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  );
}

BillingProvider.propTypes = {
  children: PropTypes.node,
  root: PropTypes.object,
};

function hasNoErrors(errors) {
  // Check if errors is null or undefined
  if (!errors) {
    return true;
  }

  // Check if errors is an object (also ensuring it's not an array)
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    // Get the keys of the errors object
    const keys = Object.keys(errors);

    // Check if the errors object is empty
    if (keys.length === 0) {
      return true;
    }

    // Check each property to see if they are all undefined
    // Or if the property is 'server' we skip it
    for (const key of keys) {
      if (errors[key] !== undefined && key !== 'server') {
        return false; // Found an error
      }
    }

    // All properties are undefined
    return true;
  }

  // In case errors is not an object
  return false;
}
