import Divider from '@mui/material/Divider';
import ArrowButton from 'components/Buttons/ArrowButton';
import { FormErrorAlert } from 'components/form/Styles';
import Textfield from 'components/form/Textfield';
import { FORM_IDS } from 'data/constants';
import {
  FormCard,
  FormContent,
  FormDescription,
  FormFooter,
  FormHeader,
  FormLink,
  PageFooter,
  PageLayout,
  reset,
  validationCallback,
} from 'features/login';
import { useFormInputHandler } from 'hooks/useFormInputHandler';
import { useNavigationIsLoading } from 'hooks/useNavigation';
import PublicIndexLayout from 'layouts/PublicIndexLayout';
import { useCallback, useReducer } from 'react';
import { Form, redirect, useActionData } from 'react-router-dom';

const initialState = {
  errors: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.id]: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default function ForgotPassword() {
  return (
    <PublicIndexLayout>
      <PageLayout>
        <ForgotPasswordForm />
        <PageFooter>
          <FormLink to="/login">Sign In</FormLink>
        </PageFooter>
      </PageLayout>
    </PublicIndexLayout>
  );
}

function ForgotPasswordForm() {
  const { error } = useActionData() ?? {};
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLoading = useNavigationIsLoading();
  const setError = useCallback((id, error) => {
    dispatch({ type: 'SET_ERROR', payload: { id, error } });
  }, []);

  const {
    disabled,
    getError,
    handleBlur,
    handleChange,
    isValid,
    validationRef,
  } = useFormInputHandler({
    errorContext: { errors: state.errors, setError },
    ids: [FORM_IDS.USER_EMAIL],
    validationCallback,
  });

  return (
    <FormCard component={Form} method="post">
      <FormHeader>Forgot Your Password?</FormHeader>
      {error && <FormErrorAlert sx={{ mb: 2 }}>{error}</FormErrorAlert>}
      <FormDescription>
        Enter the email address used with your account and we&apos;ll send you a
        link to reset your password.
      </FormDescription>
      <FormContent>
        <Textfield
          error={getError(FORM_IDS.USER_EMAIL)}
          fullWidth
          id={FORM_IDS.USER_EMAIL}
          label="Email"
          maxLength={128}
          onBlur={handleBlur(FORM_IDS.USER_EMAIL)}
          onChange={handleChange(FORM_IDS.USER_EMAIL)}
          placeholder="Enter your email"
          validationRef={validationRef}
        />
      </FormContent>
      <Divider />
      <FormFooter>
        <ArrowButton
          name="intent"
          type="submit"
          value="reset-password"
          primary
          fullWidth
          disabled={disabled || !isValid}
          loading={isLoading}
        >
          Reset Pasword
        </ArrowButton>
      </FormFooter>
    </FormCard>
  );
}

// #####################################
// ### Forgot Password Action
// #####################################

export async function action({ store, request }) {
  const handleForgotPassword = async (store, request) => {
    try {
      const formData = await request.formData();
      const email = formData.get(FORM_IDS.USER_EMAIL); // 'jwrigh26@gmail.com'
      const result = await reset(store, { email });
      return redirect(result.url);
    } catch (error) {
      const { data } = error ?? {};
      const { title: serverMessage } = data ?? {};
      return {
        success: false,
        error:
          serverMessage || 'An error occurred while resetting your account.',
      };
    }
  };

  return await handleForgotPassword(store, request);
}
