import { createSlice } from '@reduxjs/toolkit';
import { ROUTES as ADD_COMPANY_ROUTES } from 'features/company';
import { createSelector } from 'reselect';

const defaultValues = {
  addCompany: { company: null, plan: null, route: ADD_COMPANY_ROUTES.PLANS },
  affiliateData: { promos: [] },
  cancelingApple: false,
  cards: {},
  companies: [],
  payments: {},
  selectedCompany: {},
  subscriptions: {},
  tempPublicAccount: {},
  timeoutId: null,
  impersonate: {
    isAdmin: false,
    admin: null,
    user: null,
  },
};

const rootSlice = createSlice({
  name: 'root',
  initialState: defaultValues,
  reducers: {
    appendPaymentIntent(state, action) {
      state.paymentIntent = { ...state.paymentIntent, ...action.payload };
    },
    reset() {
      return defaultValues;
    },
    setAddCompany(state, action) {
      state.addCompany = {
        ...state.addCompany,
        ...action.payload,
      };
    },
    setAffiliateData(state, action) {
      state.affiliateData = action.payload;
    },
    setCancelingApple(state, action) {
      state.cancelingApple = action.payload;
    },
    setCard(state, action) {
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.ensemblesId]: action.payload.card,
        },
      };
    },
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload;
    },
    setCostbooks(state, action) {
      state.subscription.costbooks = action.payload;
    },
    setExtraUsers(state, action) {
      state.subscription.extraUsers = action.payload;
    },
    setImpersonate(state, action) {
      state.impersonate = action.payload;
    },
    setPayments(state, action) {
      return {
        ...state,
        payments: {
          ...state.payments,
          [action.payload.ensemblesId]: action.payload.payments,
        },
      };
    },
    setTempPublicAccount(state, action) {
      state.tempPublicAccount = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setUpdateSelectedCompany(state, action) {
      state.selectedCompany = { ...state.selectedCompany, ...action.payload };
    },
    setTimeoutId(state, action) {
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
      }
      state.timeoutId = action.payload;
    },
    clearTimeoutId(state) {
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
      }
      state.timeoutId = null;
    },
    updatePlan(state, action) {
      const prevPlan = state.subscriptions[action.payload.ensemblesId] || {};
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.ensemblesId]: {
            ...prevPlan,
            plan: action.payload.plan,
          },
        },
      };
    },
    updateCostbooks(state, action) {
      const prevPlan = state.subscriptions[action.payload.ensemblesId] || {};

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.ensemblesId]: {
            ...prevPlan,
            costbooks: action.payload.costbooks,
          },
        },
      };
    },
    updateExtraUsers(state, action) {
      const prevPlan = state.subscriptions[action.payload.ensemblesId] || {};

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.ensemblesId]: {
            ...prevPlan,
            extraUsers: action.payload.extraUsers,
          },
        },
      };
    },
  },
});

export const {
  appendPaymentIntent,
  reset,
  setAddCompany,
  setAffiliateData,
  setCancelingApple,
  setCard,
  setCompanies,
  setSelectedCompany,
  setCostbooks,
  setExtraUsers,
  setImpersonate,
  setPayments,
  setTempPublicAccount,
  setUsers,
  setUpdateSelectedCompany,
  updateCostbooks,
  updateExtraUsers,
  updatePlan,
  setTimeoutId,
  clearTimeoutId,
} = rootSlice.actions;

export const selectaffiliateData = createSelector(
  (state) => state.root, // extract root from state
  (root) => root.affiliateData // derive affiliatedData from root to memoize
);

// export const selectSelectedCompany = (state) => state.root.selectedCompany;
export const selectSelectedCompany = createSelector(
  (state) => state.root,
  (root) => root.selectedCompany // derive user from state to memoize
);

export const selectCompanies = createSelector(
  (state) => state.root,
  (root) => root.companies
);

export const selectSubscriptionByEnsemblesId = (ensemblesId) =>
  createSelector(
    (state) => state.root,
    (root) => root.subscriptions[ensemblesId]
  );

// export const selectSubscriptionByEnsemblesId = (ensemblesId) => (state) =>
//   state.root.subscriptions[ensemblesId] || [];

export const selectSubscriptions = createSelector(
  (state) => state.root,
  (root) => root.subscriptionss
);

export const selectCardByEnsemblesId = (ensemblesId) =>
  createSelector(
    (state) => state.root,
    (root) => root.cards[ensemblesId]
  );

export const selectcards = createSelector(
  (state) => state.root,
  (root) => root.cards
);

export const selectUsers = createSelector(
  (state) => state.root,
  (root) => root.users
);

export const selectAddCompany = createSelector(
  (state) => state.root,
  (root) => root.addCompany
);

export const selectCancelingApple = createSelector(
  (state) => state.root,
  (root) => root.cancelingApple
);

export const selectTempPublicAccount = createSelector(
  (state) => state.root,
  (root) => root.tempPublicAccount
);

export default rootSlice.reducer;
