import { apiUrl } from 'data/constants';
import { api } from './api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => {
        return {
          url: `${apiUrl}gettoken`,
          method: 'POST',
          headers: {
            Authorization: `Basic ${btoa(`${email}:${password}`)}`,
          },
        };
      },
    }),
    reset: builder.mutation({
      query: ({ email }) => {
        return {
          url: `${apiUrl}forgotpassword`,
          method: 'POST',
          body: { email },
        };
      },
    }),
  }),
});

export const { useLoginMutation, useResetMutation } = authApi;
