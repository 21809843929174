// Should be used inside all JSX components and pages
export function getErrorPageForRedirect(status = 400) {
  const url = `/error?status=${status}`;
  return url;
}

export function throwErrorWithStatusCode(error, statusCode = 400) {
  const message = error?.message || 'An error occurred';
  const isString = (value) => typeof value === 'string';

  // Get the most specific error message if possible
  let errorMessage;
  if (isString(error)) {
    errorMessage = error;
  } else if (isString(error?.error)) {
    errorMessage = error.error;
  } else if (isString(error?.data)) {
    errorMessage = error.data;
  } else if (isString(error?.data?.error)) {
    errorMessage = error.data.error;
  } else {
    errorMessage = message; // Fallback to the generic message
  }

  // Determine the final status code (falling back to the provided `statusCode` if needed)
  const finalStatusCode =
    Number(error?.originalStatus) ||
    Number(error?.response?.status) ||
    Number(error?.status) ||
    statusCode;


  // Create a new error with the derived message and attach the status
    const newError = new Error(errorMessage);
  newError.status = isNaN(finalStatusCode) ? statusCode : finalStatusCode;
  // Only set message if you want to override the one passed to the Error constructor
  if (message && !errorMessage) {
    newError.message = message;
  }
  throw newError;
}

// Should only be used in loaders and actions that are NOT deffered, otherwise use throwResponseErrorWithStatusCode
// is not captured by the error boundary
// Again this should only be used in loaders and actions that are NOT deffered.
export function throwResponseErrorWithStatusCode(statusCode) {
  const statusTexts = {
    400: 'Bad Request',
    401: 'Not Authorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    503: 'Service Unavailable',
    // Add more status codes and messages as needed
  };

  const statusText = statusTexts[statusCode] || 'Unknown Error';

  throw new Response(statusText, {
    status: statusCode || 400,
    statusText,
  });
}

export function parseStripeError(obj) {
  const { elementType, error: err } = obj;
  return { key: elementType, message: err?.message };
}
