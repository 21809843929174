import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { isFunction, isString } from '../../utils/helpers';
import { CloseIcon, Header } from './Styles';

export default function ModalHeader({ children, onClose }) {
  return (
    <Header>
      {isString(children) && <Typography variant="h6">{children}</Typography>}
      {!isString(children) && children}
      <CloseIcon onClick={isFunction ? onClose : () => {}} />
    </Header>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};
