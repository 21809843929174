import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowButton from 'components/Buttons/ArrowButton';
import CloseButton from 'components/Buttons/CloseButton';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import PropTypes from 'prop-types';
import { useSignout } from '../hooks/useSignout';

export function SignoutModal({ mobile: isMobile }) {
  const {
    handleClose,
    handleSignout,
    id,
    loading: isLoading,
    open,
  } = useSignout();

  const theme = useTheme();

  return (
    <Modal
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      size="sm"
      title="Sign Out"
    >
      <>
        <ModalHeader onClose={handleClose}>Sign Out</ModalHeader>
        <ModalBody>
          <Typography variant="body1">
            Are you sure you want to sign out?
          </Typography>
        </ModalBody>
        <ModalFooter id={id}>
          <CloseButton fullWidth={isMobile} onClick={handleClose}>
            Cancel
          </CloseButton>
          <ArrowButton
            fullWidth={isMobile}
            primary
            onClick={handleSignout}
            loading={isLoading}
          >
            Sign Out
          </ArrowButton>
        </ModalFooter>
      </>
    </Modal>
  );
}

SignoutModal.propTypes = {
  mobile: PropTypes.bool,
};
