import { getSelectedCompany, getUser } from 'lib/storage/user';

export const featureEnabled = (featuresNeeded) => {
  if (!featuresNeeded) {
    return true;
  }

  const loggedInUser = getUser();
  if (!loggedInUser) return false;

  const myCompany = getSelectedCompany();
  if (!myCompany) return false;

  const companyFeatureIds = loggedInUser.customer_id
    ? getAllPossibleFeatures()
    : myCompany.features;

  // Check if `featuresNeeded` is an array (at least one feature must pass)
  if (Array.isArray(featuresNeeded)) {
    return featuresNeeded.some((feature) =>
      companyFeatureIds.includes(feature)
    );
  }

  // If it's not an array, check a single feature number
  return companyFeatureIds.includes(featuresNeeded);
};

/**
 * "When a user is signed in as a customer, they should be able to view all of
 * their documents (estimates, invoices, payments), which means that all features
 * should be enabled for every company they can access."
 * This function gets all possible features for comparison.
 */
function getAllPossibleFeatures() {
  return Object.values(features); // Return all feature IDs as an array
}

/**
 * Mapping of feature IDs received in company details.
 * Allows reference to these IDs by string keys.
 */
export const features = Object.freeze({
  usersAndSubscriptions: 1,
  costbooksAndJobTemplates: 2,
  estimates: 3,
  changeOrders: 4,
  credits: 5,
  invoicesAndPayments: 6,
  reports: 7,
  quickbooksSyncing: 8,
});
