import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddButton from 'components/Buttons/AddButton';
import CloseButton from 'components/Buttons/CloseButton';
import { FormErrorAlert } from 'components/form/Styles';
import Textfield from 'components/form/Textfield';
import { PlanCard } from 'features/subscriptions';
import { useFormInputHandler } from 'hooks/useFormInputHandler';
import { useIsLoading } from 'hooks/useNavigation';
import { selectAddCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useFetcher } from 'react-router-dom';
import { hasValue } from 'utils/helpers';
import { FETCHER_KEY, FORM_IDS, ROUTES } from '../../data/constants';

const initialState = {
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.id]: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default function AddCompanyInformation({ onClose: handleClose }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetcher = useFetcher({ key: FETCHER_KEY });
  const isLoading = useIsLoading(fetcher);

  const { plan } = useSelector(selectAddCompany);

  const hasFetcherError = !!fetcher?.data?.error;

  const setError = useCallback((id, error) => {
    dispatch({ type: 'SET_ERROR', payload: { id, error } });
  }, []);

  const {
    disabled,
    getError,
    handleBlur,
    handleChange,
    isValid,
    validationRef,
  } = useFormInputHandler({
    errorContext: { errors: state.errors, setError },
    ids: [FORM_IDS.COMPANY_NAME, FORM_IDS.ZIP_CODE, FORM_IDS.PROMO_CODE],
    validationCallback,
  });

  const {
    name,
    subscriptions: {
      monthly: { formattedPrice },
    },
  } = plan ?? { subscriptions: { monthly: {} }, name: '' };

  return (
    <CompanyWrapper>
      {plan && <PlanCard plan={plan} />}
      <CompanyForm component={fetcher.Form} method="post" action="/">
        {hasFetcherError && (
          <FormErrorAlert color="error">{fetcher.data.error}</FormErrorAlert>
        )}
        <FormMessage>
          {`To finish adding a new company subscription, please provide the company
        name and zip code below. You will be billed ${formattedPrice}/mo for the ${name} company subscription.`}
        </FormMessage>
        <Textfield
          error={getError(FORM_IDS.COMPANY_NAME)}
          fullWidth
          id={FORM_IDS.COMPANY_NAME}
          label="Company Name"
          maxLength={128}
          onBlur={handleBlur(FORM_IDS.COMPANY_NAME)}
          onChange={handleChange(FORM_IDS.COMPANY_NAME)}
          placeholder="Enter Company Name"
          required
          validationRef={validationRef}
        />
        <Textfield
          error={getError(FORM_IDS.ZIP_CODE)}
          fullWidth
          // formatType="zipcode"
          id={FORM_IDS.ZIP_CODE}
          label="Zip Code"
          onBlur={handleBlur(FORM_IDS.ZIP_CODE)}
          onChange={handleChange(FORM_IDS.ZIP_CODE)}
          placeholder="Enter 5-Digit Zip Code"
          required
          maxLength={10}
          validationRef={validationRef}
        />
        <Textfield
          error={getError(FORM_IDS.PROMO_CODE)}
          fullWidth
          id={FORM_IDS.PROMO_CODE}
          label="Promo Code"
          maxLength={64}
          onBlur={handleBlur(FORM_IDS.PROMO_CODE)}
          onChange={handleChange(FORM_IDS.PROMO_CODE)}
          placeholder="Enter Promo Code"
          validationRef={validationRef}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 2,
            gap: 2,
          }}
        >
          <CloseButton fullWidth onClick={handleClose}>
            Close
          </CloseButton>
          <AddButton
            disabled={disabled || !isValid}
            loading={isLoading}
            fullWidth
            onClick={() => {}}
            name="intent"
            type="submit"
            value={ROUTES.INFO}
          >
            Add Company
          </AddButton>
        </Box>
      </CompanyForm>
    </CompanyWrapper>
  );
}

AddCompanyInformation.propTypes = {
  onClose: PropTypes.func,
};

// ##############################
// ### Form Styled Components
// ##############################

const CompanyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

const CompanyForm = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  width: '100%',
}));

const FormMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.charcoal.main,
  textWrap: 'pretty',
}));

// ##############################
// ### Validation Callback
// ##############################

const validationCallback = (id, value) => {
  let error = null;
  switch (id) {
    case FORM_IDS.COMPANY_NAME:
      if (!hasValue(value)) {
        error = 'Company name is required.';
      } else if (value.length > 128) {
        error = 'Company name cannot exceed 50 characters.';
      }
      break;
    case FORM_IDS.ZIP_CODE:
      // if (hasValue(value) && !/^\d{5}$/.test(value)) {
      //   return 'Zip code must be 5 digits.';
      // }
      if (!hasValue(value)) {
        return 'Zip code is required.';
      }
      break;
    case FORM_IDS.PROMO_CODE:
      if (hasValue(value) && value.length > 64) {
        error = 'Promo code cannot exceed 64 characters.';
      }
      break;
    default:
      break;
  }

  return error;
};
