import additionalUserImage from 'assets/images/additionalusers.png';
import costbooksImage from 'assets/images/costbooks.png';

// Plan details
export const plans = [
  {
    name: 'Basic',
    id: 1,
    users: 1,
    callouts: { defaults: ['Estimates', 'QuickBooks Sync'], extras: [] },
    subscriptions: {
      monthly: {
        name: 'basic_monthly',
        plan_id: 1,
        plan_name: 'Basic',
        product_id: 6,
        price: '14.99',
        formatted_price: '$14.99',
        rate: 'monthly',
      },
      yearly: {
        name: 'basic_annual',
        plan_id: 1,
        plan_name: 'Basic',
        product_id: 11,
        price: '149',
        formatted_price: '$149',
        rate: 'yearly',
      },
    },
  },
  {
    name: 'Essentials',
    id: 2,
    users: 3,
    callouts: {
      defaults: ['Estimates', 'QuickBooks Sync'],
      extras: ['1 Invoice per job', '1 Payment per job'],
    },
    subscriptions: {
      monthly: {
        name: 'essentials_monthly',
        plan_id: 2,
        plan_name: 'Essentials',
        product_id: 7,
        price: '24.99',
        formatted_price: '$24.99',
        rate: 'monthly',
      },
      yearly: {
        name: 'essentials_annual',
        plan_id: 2,
        plan_name: 'Essentials',
        product_id: 12,
        price: '249',
        formatted_price: '$249',
        rate: 'yearly',
      },
    },
  },
  {
    name: 'Pro',
    id: 3,
    users: 5,
    callouts: {
      defaults: ['Estimates', 'QuickBooks Sync'],
      extras: ['Draw Schedules', 'Change Orders', 'Multiple Invoices', 'Multiple Payments'],
    },
    subscriptions: {
      monthly: {
        name: 'pro_monthly',
        plan_id: 3,
        plan_name: 'Pro',
        product_id: 8,
        price: '54.99',
        formatted_price: '$54.99',
        rate: 'monthly',
      },
      yearly: {
        name: 'pro_annual',
        plan_id: 3,
        plan_name: 'Pro',
        product_id: 13,
        price: '549',
        formatted_price: '$549',
        rate: 'yearly',
      },
    },
  },
];

// Mapping of plan names to subscription details
export const planSubscriptionList = plans.reduce((subs, plan) => {
  const { subscriptions: { monthly, yearly } } = plan;
  return { ...subs, [monthly.name]: monthly, [yearly.name]: yearly };
}, {});

export function findSubscription(subscriptionName) {
  return planSubscriptionList[subscriptionName];
}

// Subscription options for additional users
export const extraUsers = {
  name: 'Additional Users',
  subscription: { name: 'extra_users' },
  price: { month: '$9.99', user: 'Per User' },
  image: additionalUserImage,
  footer: {
    caption: (plan, count, subscribed) => {
      if (!subscribed) {
        return 'Please select a plan to add additional users. You will be billed $9.99/mo for each additional user beyond the number included with your plan.';
      }
      const users = count > 1 ? `${count} users` : `${count} user`;
      return `You are currently subscribed to the ${plan} plan, which includes ${users}. You will be billed $9.99/mo for each additional user beyond the number included with your plan.`;
    },
  },
  styles: { leading: false, trailing: true },
};

// Subscription options for Craftsman Costbooks
export const costbooks = {
  name: 'Craftsman Costbooks',
  subscriptions: {
    monthly: {
      name: 'craftsman_monthly',
      plan_id: null,
      plan_name: 'Craftsman Costbooks',
      product_id: 10,
      price: '12.99',
      formatted_price: '$12.99',
      rate: 'monthly',
    },
    yearly: {
      name: 'craftsman_annual',
      plan_id: null,
      plan_name: 'Craftsman Costbooks',
      product_id: 15,
      price: '129',
      formatted_price: '$129',
      rate: 'yearly',
    },
  },
  price: { month: '$12.99', year: '$129' },
  image: costbooksImage,
  footer: {
    caption: 'Get instant access to thousands of common construction costs by adding the Craftsman costbook subscription for $12.99/mo. The first month is free.',
  },
  styles: { leading: true, trailing: false },
};

export const plansByProductId = {
  1: 'Basic - Apple',
  2: 'Essentials - Apple',
  3: 'Pro - Apple',
  4: 'Enterprise - Apple',
  5: 'Costbooks - Apple',
  6: 'Basic Plan (mo)',
  7: 'Essentials Plan (mo)',
  8: 'Pro Plan (mo)',
  9: 'Enterprise (mo)',
  10: 'Craftsman (mo)',
  11: 'Basic Plan (yr)',
  12: 'Essentials Plan (yr)',
  13: 'Pro Plan (yr)',
  14: 'Enterprise Plan (yr)',
  15: 'Costbook (yr)',
  16: 'Additional Users',
}

// Source 
// isActiveApple : source === 0