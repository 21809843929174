import {
  faCheckCircle,
  faCreditCard,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import BackButton from 'components/Buttons/BackButton';
import Icon from 'components/Icon';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { COMPONENT_KEYS } from 'data/constants';
import { useStripeWithAccount } from 'hooks/useStripe';
import { selectAddCompany, setAddCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetcher } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FETCHER_KEY, ROUTES } from '../../data/constants';
import AddCompanyCard from './AddCompanyCard';
import AddCompanyInformation from './AddCompanyInformation';
import AddCompanyPlans from './AddCompanyPlans';
import AddCompanySummary from './AddCompanySummary';
import './styles.css';

export default function AddCompanyModal({ mobile: isMobile }) {
  const fetcher = useFetcher({ key: FETCHER_KEY });
  const modalContext = useComponentStateContext();
  const { route } = useSelector(selectAddCompany);

  const open =
    modalContext.state?.open[COMPONENT_KEYS.ADD_COMPANY_MODAL] || false;

  const handleClose = useCallback(() => {
    modalContext.setClose(COMPONENT_KEYS.ADD_COMPANY_MODAL)();
    fetcher.submit({ intent: ROUTES.RESET }, { method: 'post', action: '/' });
  }, []);

  return (
    <Modal open={open} onClose={handleClose} size="md">
      <Content mobile={isMobile} onClose={handleClose} route={route} />
    </Modal>
  );
}

AddCompanyModal.propTypes = {
  mobile: PropTypes.bool,
};

function Content({ onClose: handleClose, route }) {
  const stripe = useStripeWithAccount();

  const plansRef = useRef(null);
  const formRef = useRef(null);
  const cardRef = useRef(null);
  const summaryRef = useRef(null);

  const currentRef =
    {
      [ROUTES.PLANS]: plansRef,
      [ROUTES.INFO]: formRef,
      [ROUTES.CARD]: cardRef,
      [ROUTES.SUMMARY]: summaryRef,
    }[route] || plansRef;

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={route}
        nodeRef={currentRef}
        timeout={300}
        classNames="fade"
      >
        <div ref={currentRef}>
          <div className="step">
            <ModalHeader onClose={handleClose}>
              <Header route={route} />
            </ModalHeader>
            <ModalBody>
              {/* ROUTES START HERE */}
              {route === ROUTES.PLANS && (
                <AddCompanyPlans onClose={handleClose} />
              )}
              {route === ROUTES.INFO && (
                <AddCompanyInformation onClose={handleClose} />
              )}
              {route === ROUTES.CARD && <AddCompanyCard stripe={stripe} />}
              {route === ROUTES.SUMMARY && (
                <AddCompanySummary onClose={handleClose} />
              )}
            </ModalBody>
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

Content.propTypes = {
  onClose: PropTypes.func.isRequired,
  route: PropTypes.string,
};

function Header({ route }) {
  const title = {
    [ROUTES.PLANS]: <PlansHeader />,
    [ROUTES.INFO]: <FormHeader />,
    [ROUTES.CARD]: <CardHeader />,
    [ROUTES.SUMMARY]: <SummaryHeader />,
  }[route];

  return <>{title}</>;
}

Header.propTypes = {
  route: PropTypes.string,
};

// ####################################
// ### Header Components for Title
// ####################################

function PlansHeader() {
  return <Typography variant="h6">Select a plan</Typography>;
}

function FormHeader() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backButtLabel = isMobile ? 'Back' : 'Back To Plans';

  const handleBack = () => {
    dispatch(setAddCompany({ route: ROUTES.PLANS }));
  };

  return (
    <Stack direction="row" alignItems="center" gap={1.25}>
      <BackButton
        onClick={handleBack}
        sx={{ position: 'relative', top: '1px', pr: 0 }}
      >
        {backButtLabel}
      </BackButton>
      <StyledDivider orientation="vertical" flexItem />
      <Typography variant="h6">Company Information</Typography>
    </Stack>
  );
}

function CardHeader() {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Icon icon={faCreditCard} />
      <Typography variant="h6">Credit Card</Typography>
    </Stack>
  );
}

function SummaryHeader() {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Icon icon={faCheckCircle} />
      <Typography variant="h6">Summary</Typography>
    </Stack>
  );
}

// ##############################
// ### Styles
// ##############################

const CloseButton = styled((props) => (
  <IconButton edge="end" {...props}>
    <Icon size="xs" icon={faTimes} />
  </IconButton>
))(({ theme }) => ({
  width: '40px',
  height: '40px',
  position: 'relative',
  top: '2px',
  color: theme.palette.charcoal.light,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
