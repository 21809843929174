import { isFunction } from 'utils/helpers';
import { useEffect } from 'react';
import { usePDF } from '@react-pdf/renderer'; // Ensure correct import path for usePDF
import PropTypes from 'prop-types';

function DownloadReceipt({
  document,
  name,
  trigger,
  callback: loadingCallback,
}) {
  const [instance] = usePDF({ document });

  useEffect(() => {
    if (instance?.loading && isFunction(loadingCallback)) {
      loadingCallback(instance.loading);
    }

    if (instance?.error) {
      console.error(`Error generating PDF for ${name}:`, instance.error);
    }

    if (instance?.url && !instance.loading && isFunction(loadingCallback)) {
      downloadFile(instance.url, name);
      loadingCallback();
    }
  }, [instance, trigger, loadingCallback, name]);

  return null;
}

DownloadReceipt.propTypes = {
  document: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  trigger: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};

export default DownloadReceipt;


// ##############################
// Helper files
// ##############################

const downloadFile = (url, name) => {
  const element = document.createElement('a')
  element.href = url
  element.download = `${name}.pdf`
  // element.setAttribute('download', fileName);
  // Need to append the element again for firefox and IE
  document.body.appendChild(element)
  element.click()
  element.remove()
}