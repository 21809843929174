import { apiUrl } from 'data/constants';
import { features } from 'features/accessControl';
import { hasValue } from 'utils/helpers';
import { api } from './api';

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Companies API call for customers
    getCompaniesForCustomer: builder.query({
      query: ({ userId }) => {
        return {
          url: `${apiUrl}users/${userId}/contacts`,
          method: 'GET',
        };
      },
      transformResponse: (res, _, meta) => {
        const { customerId } = meta?.arg ?? { customerId: null };
        const companies = assignEnsemblesIdAndRetrieveCompanies(res);
        return { companies, customerId };
      },
      providesTags: (_result, _error, _args) => {
        return [{ type: 'companies', id: `COMPANIES_FOR_CUSTOMER` }];
      },
    }),

    // Companies API call for non-customers
    getCompanies: builder.query({
      query: () => {
        return {
          url: `${apiUrl}user/companies`,
          method: 'POST',
        };
      },
      transformResponse: (res, _, meta) => {
        const { customerId } = meta?.arg ?? { customerId: null };
        const { companies } = res ?? {};
        // const newCompanies = assignCompanyFeatures(companies);

        return { companies, customerId };
      },
      providesTags: (_result, _error, _args) => {
        return [{ type: 'companies', id: `COMPANIES_FOR_USER` }];
      },
    }),
    // Mutations
    createCompanyAndCustomer: builder.mutation({
      query: ({ companyName, zipCode, promoCode }) => {
        return {
          url: `${apiUrl}createcompanyandcustomer`,
          method: 'POST',
          body: {
            name: companyName,
            country_code: 'US',
            postal: zipCode,
            ...(hasValue(promoCode) && { promo_code: promoCode }),
          },
        };
      },
      invalidatesTags: [{ type: 'companies', id: `COMPANIES_FOR_USER` }],
    }),
  }),
});

export const {
  useGetCompaniesForCustomerQuery,
  useGetCompaniesQuery,
  useCreateCompanyAndCustomerMutation,
} = companiesApi;

// Used up above in getCompaniesForCustomer
function assignEnsemblesIdAndRetrieveCompanies(res) {
  let companies;

  if (Array.isArray(res)) {
    companies = res.map((company) => ({
      ...company,
      ensembles_id: company.company_id,
    }));
  } else if (!res?.ensembles_id) {
    // This is a single company
    companies = {
      ...res,
      ensembles_id: res.company_id,
    };
  } else {
    companies = res;
  }

  return companies;
}

// Not currently being used
function assignCompanyFeatures(res) {
  let companies;

  const mapFeatures = (f) => {
    return f.map((feature) => ({
      id: feature,
      name: features[feature],
    }));
  };

  if (Array.isArray(res)) {
    companies = res.map((company) => ({
      ...company,
      features: mapFeatures(company.features),
    }));
  } else {
    companies = {
      ...res,
      features: mapFeatures(res.features),
    };
  }

  return companies;
}
