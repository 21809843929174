import { verifyIsAdmin } from 'features/accessControl';
import localStorageHelper from './localStorageHelper';
import { createUserDataFromToken, getUser, removeUser, setUser } from './user';

export default {
  getAdmin,
  getAdminToken,
  removeAdmin,
  setAdmin,
  setImpersonationUser,
  signBackInAsAdmin,
  isImpersonating,
};

export {
  getAdmin,
  getAdminToken,
  isImpersonating,
  removeAdmin,
  setAdmin,
  setImpersonationUser,
  signBackInAsAdmin,
};

/**
 * Returns the admin object from our localstorage
 * @returns {object} user object
 */
function getAdmin() {
  return localStorageHelper.getItem('admin');
}

function isImpersonating() {
  return localStorageHelper.getItem('impersonating') === 'true';
}

/**
 * Returns the jwt token from our admin localstorage object
 * @return {string} the token
 */
function getAdminToken() {
  const user = getAdmin();
  return user ? user.token : undefined;
}

/**
 * Remove admin from localStorage and sessionStorage and clear any cookies
 * for impersonation
 */
function removeAdmin() {
  localStorageHelper.removeItem('admin');
  localStorageHelper.removeItem('impersonating');
}

/**
 * Sets the admin value in localStorage
 * @param {object} admin
 */
function setAdmin(admin) {
  if (!admin) {
    return;
  }
  localStorageHelper.setItem('admin', admin);
  return admin;
}

/**
 * Sets the impersonation user value in localStorage and sessionStorage
 * Squirells away the admin user data in localStorage
 * @param {string} token
 */
function setImpersonationUser(token) {
  const admin = getAdmin();
  const currentUser = getUser();
  if (!verifyIsAdmin(currentUser) && !verifyIsAdmin(admin)) {
    throw new Error('User is not an admin');
  }
  // Save local user data as admin
  setAdmin(currentUser);
  // Save impersonation user data as user
  // First remove the user data
  removeUser();
  // Then set the impersonation user data as user
  const userData = createUserDataFromToken(token);
  setUser(userData);

  // set localStorage to mark that we are impersonating
  localStorageHelper.setItem('impersonating', 'true');

  // Return the impersonation user data
  return userData;
}

/**
 * Reverts the user back to the admin user
 * @returns null
 */
function signBackInAsAdmin() {
  const admin = getAdmin();
  if (!verifyIsAdmin(admin)) {
    throw new Error('User is not an admin');
  }
  // First remove the user data
  removeUser();

  // Then set the admin user data as user
  setUser(admin);

  // Finally remove the admin user data
  removeAdmin();
  return admin;
}
