import big from 'big.js';
import { getSelectedCompany } from 'lib/storage/user';
import numeral from 'numeral';

export { formatCurrency, removeCurrencySymbol };

function formatCurrency(
  input,
  { convertFromCents = false, currencyCode: inputCurrencyCode = undefined } = {}
) {
  let amountNumeral;
  if (convertFromCents) {
    amountNumeral = numeral(Number(big(input).div(100)));
  } else {
    amountNumeral = numeral(input);
  }

  const currencyCode = inputCurrencyCode || cachedCurrencyCode();

  const localeString = amountNumeral
    .value()
    .toLocaleString(undefined, { style: 'currency', currency: currencyCode });

  return localeString ? localeString : amountNumeral.format('$0,0.00');
}

let prevCachedCode = undefined;

const cachedCurrencyCode = () => {
  let currencyCode = 'USD';
  if (!prevCachedCode) {
    currencyCode = getSelectedCompany()?.currency_code || 'USD';
    prevCachedCode = currencyCode;
  }
  return prevCachedCode;
};

function removeCurrencySymbol(value) {
  const code = cachedCurrencyCode();
  if (code === 'CAD') {
    return value.replace(/CA\$/g, '');
  }
  return value.replace(/\$/g, '');
}
