import { apiUrl } from 'data/constants';
import { isSubscribed } from 'utils/dates';
import {
  updateCostbooks,
  updateExtraUsers,
  updatePlan,
} from '../lib/store/rootSlice';
import { api } from './api';
import { isNumeric } from 'utils/helpers';

export const subscriptionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCostbooks: builder.query({
      query: ({ ensemblesId }) => {
        return {
          url: `${apiUrl}company/costbooksubscription`,
          method: 'POST',
          body: {
            ensembles_id: ensemblesId,
          },
        };
      },
      transformResponse: (res) => {
        const { success, subscription } = res;
        if (!success) {
          return null;
        }

        const isMonthly =
          !subscription?.product_id ||
          (isNumeric(subscription?.product_id) &&
            subscription?.product_id < 11);

        return {
          subscribed: isSubscribed(subscription),
          renewalPeriod: isMonthly ? 'monthly' : 'yearly', // less than 11 is monthly
          renewalPeriodOpposite: isMonthly ? 'yearly' : 'monthly',
          ...subscription,
        };
      },
      onQueryStarted: async ({ ensemblesId }, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateCostbooks({ ensemblesId, costbooks: data }));
        } catch (error) {
          console.error('Error getting extra users subscription:', error);
        }
      },
      providesTags: (_result, _error, args) => {
        const { ensemblesId } = args ?? {};
        return [{ type: 'subscriptions', id: `COSTBOOKS_${ensemblesId}` }];
      },
    }),
    getExtraUsers: builder.query({
      query: ({ ensemblesId }) => {
        return {
          url: `${apiUrl}company/extrauserssubscription`,
          method: 'POST',
          body: {
            ensembles_id: ensemblesId,
          },
        };
      },
      transformResponse: (res) => {
        const { success, subscription } = res;
        if (!success) {
          return null;
        }
        return {
          subscribed: isSubscribed(subscription),
          ...subscription,
        };
      },
      onQueryStarted: async ({ ensemblesId }, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateExtraUsers({ ensemblesId, extraUsers: data }));
        } catch (error) {
          console.error('Error getting extra users subscription:', error);
        }
      },
      providesTags: (_result, _error, args) => {
        const { ensemblesId } = args ?? {};
        return [{ type: 'subscriptions', id: `EXTRA_USERS_${ensemblesId}` }];
      },
    }),
    getPlan: builder.query({
      query: ({ ensemblesId }) => {
        return {
          url: `${apiUrl}company/plansubscription`,
          method: 'POST',
          body: {
            ensembles_id: ensemblesId,
          },
        };
      },
      transformResponse: (res) => {
        const { success, subscription } = res;
        if (!success) {
          return null;
        }

        return {
          ...subscription,
          subscribed: isSubscribed(subscription),
          renewalPeriod: subscription?.product_id < 11 ? 'monthly' : 'yearly', // less than 11 is monthly
          renewalPeriodOpposite:
            subscription?.product_id < 11 ? 'yearly' : 'monthly',
        };
      },
      onQueryStarted: async ({ ensemblesId }, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updatePlan({ ensemblesId, plan: data }));
        } catch (error) {
          console.error('Error getting plan subscription:', error);
        }
      },
      providesTags: (_result, _error, args) => {
        const { ensemblesId } = args ?? {};
        return [{ type: 'subscriptions', id: `PLAN_${ensemblesId}` }];
      },
    }),
    getUsers: builder.query({
      query: ({ ensemblesId }) => {
        return {
          url: `${apiUrl}company/users`,
          method: 'POST',
          body: {
            ensembles_id: ensemblesId,
          },
        };
      },
      transformResponse: (res) => {
        const { users } = res;
        return users;
      },
      providesTags: (_result, _error, args) => {
        const { ensemblesId } = args ?? {};
        return [{ type: 'subscriptions', id: `USERS_${ensemblesId}` }];
      },
    }),
    cancelCostbooks: builder.mutation({
      query: ({ stripe_customer_id, stripe_subscription_id, reason }) => {
        return {
          url: `${apiUrl}stripe/cancelsubscription`,
          method: 'POST',
          body: {
            stripe_subscription_id,
            stripe_customer_id,
            reason,
          },
        };
      },
    }),
    cancelExtraUsers: builder.mutation({
      query: ({ stripe_customer_id, stripe_subscription_id, reason }) => {
        return {
          url: `${apiUrl}stripe/cancelsubscription`,
          method: 'POST',
          body: {
            stripe_subscription_id,
            stripe_customer_id,
            reason,
          },
        };
      },
    }),
    cancelPlan: builder.mutation({
      query: ({ stripe_subscription_id, stripe_customer_id, reason }) => {
        return {
          url: `${apiUrl}stripe/cancelallsubscriptions`,
          method: 'POST',
          body: {
            stripe_subscription_id,
            stripe_customer_id,
            reason,
          },
        };
      },
      // Currently not using this, but it's here for reference
      // We don't invalidate the cache because the server has a delay in updating the subscription status
      // and we want to show the user that the subscription is cancelled immediately
      // So we do dispatchAndUnwrap to bust the cache with a forced refetch
      // -----
      // invalidatesTags: (_result, _error, {ensemblesId}) => {
      //   console.log('invalidatesTags', ensemblesId);
      //   return [{ type: 'subscriptions', id: `PLAN_${ensemblesId}` }];
      // },
    }),
    // Note on createSubscription because it's a bit confusing
    // Used for creating a new subscription from apple pay
    // And for renewing a subscription
    createSubscription: builder.mutation({
      query: (body) => {
        return {
          url: `${apiUrl}stripe/createsubscription`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response, meta) => {
        return response;
      },
    }),
    updateSubscription: builder.mutation({
      query: (body) => {
        return {
          url: `${apiUrl}stripe/updatesubscription`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response, meta) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetCostbooksQuery,
  useGetExtraUsersQuery,
  useGetPlanQuery,
  useGetUsersQuery,
  useCancelCostbooksMutation,
  useCancelExtraUsersMutation,
  useCancelPlanMutation,
  useCreateSubscriptionsMutation,
  useUpdateSubscriptionMutation,
} = subscriptionsApi;
