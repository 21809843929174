import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
} from '@stripe/react-stripe-js';
import Button from 'components/Buttons/Button';
import { FormErrorAlert, InputErrorText } from 'components/form/Styles';
import { useIsLoading } from 'hooks/useNavigation';
import { selectAddCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useCallback, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetcher } from 'react-router-dom';
import { FETCHER_KEY, ROUTES } from '../../data/constants';

export default function AddCompanyCard({ stripe }) {
  const { isStripeLoading, stripeInstance, stripeError } = stripe ?? {};

  if (stripeError) {
    console.log('Stripe error', stripeError);
    console.error('Stripe error', stripeError);
  }

  return (
    <>
      {isStripeLoading && <div>Loading</div>}
      {!isStripeLoading && (
        <Elements stripe={stripeInstance}>
          <Card stripe={stripeInstance} />
        </Elements>
      )}
    </>
  );
}

AddCompanyCard.propTypes = {
  stripe: PropTypes.object,
};

const initialState = {
  stripeServerError: null,
  stripeFormErrors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET_ERRORS':
      return initialState;
    case 'SET_STRIPE_SERVER_ERROR':
      return { ...state, stripeServerError: action.payload };
    case 'SET_STRIPE_FORM_ERROR':
      return {
        ...state,
        stripeFormErrors: {
          ...state.stripeFormErrors,
          [action.payload.id]: action.payload.error,
        },
      };
    default:
      return state;
  }
};

function Card({ stripe }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stripeServerError: serverError, stripeFormErrors: errors } = state;
  const [isBusy, setIsBusy] = useState(false);
  const elements = useElements();
  const fetcher = useFetcher({ key: FETCHER_KEY }) ?? {};
  const isLoading = useIsLoading(fetcher);
  const { plan } = useSelector(selectAddCompany);
  const subscriptionName = plan?.subscriptions?.monthly?.name;

  const errorConditions = [
    serverError,
    fetcher.data?.error,
    errors.cardNumber,
    errors.cardExpiry,
    errors.cardCvc,
  ];

  const isDisabled = errorConditions.some(Boolean);

  const handleSubmit = async () => {
    setIsBusy(true);

    const { error, token } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    if (error) {
      console.error('SET_SERVER_ERROR: AddCompany', error);
      return dispatch({ type: 'SET_ERROR', payload: error?.message });
    }

    fetcher.submit(
      {
        intent: ROUTES.CARD,
        subscriptionName,
        tokenId: token.id,
      },
      { method: 'post', action: '/' }
    );

    setIsBusy(false);
  };

  const setStripeError = useCallback((event) => {
    setIsBusy(false);
    const { elementType, error: err } = event ?? {};
    if (err) {
      dispatch({
        type: 'SET_STRIPE_FORM_ERROR',
        payload: { id: elementType, error: err?.message },
      });
    } else {
      dispatch({ type: 'RESET_ERRORS' });
    }
  }, []);

  if (fetcher.data?.error) {
    return (
      <>
        <Stack gap={4} sx={{ mb: 2 }}>
          <FormErrorAlert>{fetcher.data?.error}</FormErrorAlert>
          <AlertBody>
            <span>
              The company was successfully created, but we encountered problems
              while adding a card.
            </span>{' '}
            If this issue persists, please contact our support team.
          </AlertBody>
          <Button primary fullWidth>
            close
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      {serverError && (
        <FormErrorAlert sx={{ mb: 2 }}>{serverError}</FormErrorAlert>
      )}
      <CardGrid>
        <CardFormControl
          id="cardNumber"
          label="Card Number"
          error={errors.cardNumber}
        >
          <CardNumberElement onChange={setStripeError} />
        </CardFormControl>
        <CardFormControl
          id="cardExpiry"
          label="Expiration"
          error={errors.cardExpiry}
        >
          <CardExpiryElement onChange={setStripeError} />
        </CardFormControl>
        <CardFormControl id="cardCvc" label="CVC" error={errors.cardCvc}>
          <CardCvcElement onChange={setStripeError} />
        </CardFormControl>
        <Button
          disabled={isDisabled}
          loading={isBusy || isLoading}
          onClick={handleSubmit}
          sx={{ gridArea: 'submit', mt: 2 }}
          primary
          fullWidth
        >
          Add Credit Card
        </Button>
      </CardGrid>
    </>
  );
}

Card.propTypes = {
  stripe: PropTypes.object,
};

// ##############################
// ### Input Field
// ##############################

function CardFormControl({ children, id, label, error }) {
  return (
    <Box sx={{ gridArea: id }}>
      <CardInputLabel htmlFor={id}>
        {label}
        <span className="card-asterisk">*</span>
      </CardInputLabel>
      <CardInput id={id}>{children}</CardInput>
      {error && <InputErrorText>{error}</InputErrorText>}
    </Box>
  );
}

CardFormControl.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

// ##############################
// ### Styles
// ##############################

const CardGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'cardNumber'
    'cardExpiry'
    'cardCvc'
    'submit'
  `,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      'cardNumber cardNumber'
      'cardExpiry cardCvc'
      'submit submit'
    `,
  },
}));

const CardInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(1),
  '&:focus': {
    color: theme.palette.primary.main,
  },
  '& .card-asterisk': {
    display: 'inline-block',
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
}));

export const CardInput = styled((props) => <Box {...props} />)(({ theme }) => ({
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.white,
  border: '1px solid',
  borderColor: theme.palette.charcoal.main,
  fontSize: 16,
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&:focus': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export const AlertBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.charcoal.main,
  '& span': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
