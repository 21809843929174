import big from 'big.js';
import { apiUrl } from 'data/constants';
import { formatCurrency } from 'utils/currency';
import { apiToUTC, formatInBrowserLocale } from 'utils/dates';
import { hasValue } from 'utils/helpers';
import { api } from './api';
import { ESTIMATE_STATUS } from 'data/constants';

export const jobsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ customerId, ensemblesId }) => {
        let path = '';
        if (hasValue(customerId)) {
          path = `customers/${customerId}/jobs`;
        } else {
          path = `companies/${ensemblesId}/jobs`;
        }
        return {
          url: `${apiUrl}${path}`,
          method: 'GET',
        };
      },
      transformResponse: (res, _req, args) => {
        const currencyCode = args?.currencyCode || 'USD';

        const getJobNumberAsInt = (jobNumber) =>
          parseInt(jobNumber.replace(/\D/g, '')) || 0;

        const jobs = res
          ?.map((job) => ({
            ...job,
            formattedJobNumber: `#${job.job_number}`,
            formattedPrice: formatCurrency(job.cached_price || 0, {
              currencyCode,
            }),
          }))
          .sort(
            (a, b) =>
              getJobNumberAsInt(b.job_number) - getJobNumberAsInt(a.job_number)
          );
        return jobs;
      },
      providesTags: (_result, _error, args) => {
        const { customerId, ensemblesId } = args ?? {};
        const id = hasValue(customerId) ? customerId : ensemblesId;
        return [{ type: 'jobs', id: `JOBS_${id}` }];
      },
    }),
    getJobDetails: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        Array.isArray(response) && response.length > 0 ? response[0] : null,
      providesTags: (result, error, jobId) => [
        { type: 'JobDetails', id: jobId },
      ],
    }),
    getChangeOrders: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/change_orders`,
        method: 'GET',
      }),
      transformResponse: (response) => ({
        cell: {
          altText: 'Change Orders Icon',
          icon: '/icons/invoice_128x128.png',
          name: 'Change Orders',
          price: formatCurrency(
            response.reduce((total, myChangeOrder) => {
              if (myChangeOrder.status === 1 && myChangeOrder.cached_price) {
                return Number(
                  big(total).plus(Number(myChangeOrder.cached_price))
                );
              }
              return total;
            }, 0)
          ),
          url: 'change-orders',
        },
        data: transformChangeOrdersResponse(response),
      }),
      providesTags: (result, error, jobId) => [
        { type: 'JobDetails', id: jobId },
      ],
    }),
    getInvoice: builder.query({
      query: ({ invoiceId }) => ({
        url: `${apiUrl}invoices/${invoiceId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        const data = transformInvoiceResponse(response);
        return data;
      },
      providesTags: (result, error, invoiceId) => [
        { type: 'InvoiceDetails', id: invoiceId },
      ],
    }),
    getInvoices: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/invoices`,
        method: 'GET',
      }),
      transformResponse: (response) => ({
        cell: {
          altText: 'Invoices Icon',
          icon: '/icons/accountsrec_128x128.png',
          name: 'Invoices',
          price: formatCurrency(
            response.reduce((total, myInvoice) => {
              if (myInvoice.cached_amount_due) {
                return Number(
                  big(total).plus(Number(myInvoice.cached_amount_due))
                );
              }
              return total;
            }, 0)
          ),
          url: 'invoices',
        },
        data: transformInvoicesResponse(response),
      }),
      providesTags: (result, error, jobId) => [
        { type: 'JobDetails', id: jobId },
      ],
    }),
    getJobPayments: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/payments`,
        method: 'GET',
      }),
      transformResponse: (response) => ({
        cell: {
          altText: 'Payments Icon',
          icon: '/icons/cash_128x128.png',
          name: 'Payments',
          price: formatCurrency(
            response.reduce((total, myPayment) => {
              if (myPayment.amount) {
                return Number(big(total).plus(Number(myPayment.amount)));
              }
              return total;
            }, 0)
          ),
          url: 'payments',
        },
        data: transformPaymentsResponse(response),
      }),
      providesTags: (result, error, jobId) => [
        { type: 'JobDetails', id: jobId },
      ],
    }),
    getEstimate: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/estimate`,
        method: 'GET',
      }),
      transformResponse: (response) => ({
        cell: {
          altText: 'Estimate Icon',
          icon: '/icons/account_128x128.png',
          documentIcon: '/icons/docs_128x128.png',
          name: 'Estimate',
          price: formatCurrency(response?.cached_price || 0),
          url: 'estimate/document',
        },
        data: transformEstimateResponse(response),
      }),
      providesTags: (result, error, jobId) => [
        { type: 'JobDetails', id: jobId },
      ],
    }),
  }), // End of endpoints
});

export const {
  useGetJobsQuery,
  useGetJobDetailsQuery,
  useGetChangeOrdersQuery,
  useGetInvoiceQuery,
  useGetInvoicesQuery,
  useGetJobPaymentsQuery,
  useGetEstimatetQuery,
} = jobsApi;

// ###########################
// ### Helper Methods
// ###########################

function transformEstimateResponse(response) {
  return {
    ...response,
    altText: 'Document Icon',
    icon: '/icons/docs_128x128.png',
    isPublic: false,
    name: 'Document',
    price: formatCurrency(response?.cached_price || 0),
    viewed: response?.viewed
      ? formatInBrowserLocale(apiToUTC(response?.viewed))
      : 'Never',
  };
}

function transformPaymentsResponse(response) {
  const type = {
    0: 'Deposit',
    1: 'Payment',
    2: 'Refund',
  };

  return response
    .map((payment) => ({
      ...payment,
      cached_amount: payment?.amount,
      amount: formatCurrency(payment?.amount || 0),
      altText: 'Payment Icon',
      date: formatInBrowserLocale(payment?.date),
      icon: '/icons/cash_128x128.png',
      isPublic: false,
      paymentMethod: payment?.payment_method,
      paymentType: type[payment?.payment_type],
      processing: !payment?.saved_to_client_db && !payment?.stripe_receipt_url,
      receiptUrl: payment?.stripe_receipt_url,
      showInvoice: payment?.saved_to_client_db,
      showLinkToStripeReceipt: payment?.stripe_receipt_url,
      viewed: payment?.viewed
        ? formatInBrowserLocale(apiToUTC(payment?.viewed))
        : 'Never',
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // sort desc
}

function transformInvoiceResponse(response) {
  const { company, ...invoice } = response;
  return {
    ...invoice,
    altText: 'Invoice Icon',
    amount: formatCurrency(invoice?.cached_amount_due || 0),
    company,
    date: formatInBrowserLocale(apiToUTC(invoice?.issue_date)),
    desc: invoice?.long_description,
    icon: '/icons/accountsrec_128x128.png',
    isPublic: false,
    invoiceNumber: invoice?.number,
    invoiceType:
      Number(invoice?.cached_amount_due) >= 0 ? 'Invoice' : 'Credit Memo',
    viewed: invoice?.viewed
      ? formatInBrowserLocale(apiToUTC(invoice?.viewed))
      : 'Never',
  };
}

function transformInvoicesResponse(response) {
  const mapped = response.map((invoice) => ({
    ...invoice,
    altText: 'Invoice Icon',
    amount: formatCurrency(invoice?.cached_amount_due || 0),
    date: formatInBrowserLocale(apiToUTC(invoice?.issue_date)),
    desc: invoice?.long_description,
    icon: '/icons/accountsrec_128x128.png',
    isPublic: false,
    invoiceNumber: invoice?.number,
    invoiceType:
      Number(invoice?.cached_amount_due) >= 0 ? 'Invoice' : 'Credit Memo',
    viewed: invoice?.viewed
      ? formatInBrowserLocale(apiToUTC(invoice?.viewed))
      : 'Never',
  }));

  const sorted = mapped.sort(
    (a, b) => b.issue_date.localeCompare(a.issue_date) // sort desc
  );

  return sorted;
}

function transformChangeOrdersResponse(response) {
  const mapped = response.map((changeOrder) => ({
    ...changeOrder,
    altText: 'Change Order Icon',
    date: formatInBrowserLocale(apiToUTC(changeOrder?.issue_date)),
    icon: '/icons/invoice_128x128.png',
    isPublic: false,
    name: changeOrder?.name,
    price: formatCurrency(changeOrder?.cached_price || 0),
    status: ESTIMATE_STATUS[changeOrder?.status],
    viewed: changeOrder?.viewed
      ? formatInBrowserLocale(apiToUTC(changeOrder?.viewed))
      : 'Never',
  }));

  const sorted = mapped.sort(
    (a, b) => b.issue_date.localeCompare(a.issue_date) // sort desc
  );

  return sorted;
}
