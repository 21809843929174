import Button from 'components/Buttons/Button';
import { selectSelectedCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePlanButton } from '../hooks/useButton';
import { useModal } from '../hooks/useModal';
import { useSubscriptionsContext } from '../providers/SubscriptionsProvider';
import { ActionsWrapper } from './Styles';

export default function PlanActions({ plan }) {
  const {
    openModal,
    openCancelModal,
    getPlanModalBundle,
    getCancelModalBundle,
  } = useModal();

  const {
    ensemblesId,
    plan: currentPlan,
    setModalBundle,
    setCancelModalBundle,
    stripeCustomerId: stripeCustomerIdFromContext,
  } = useSubscriptionsContext();

  const { stripe_customer_id: stripeCustomerIdFromCache } = useSelector(
    selectSelectedCompany
  );

  const { key, label, isSubscribedPlan } = usePlanButton(plan, currentPlan);

  const stripeCustomerId =
    stripeCustomerIdFromContext || stripeCustomerIdFromCache;

  const handleCancel = useCallback(() => {
    const bundle = getCancelModalBundle({
      currentPlan,
      ensemblesId,
      planName: plan.name,
      stripeCustomerId,
    });
    setCancelModalBundle(bundle);
    openCancelModal();
  }, [key, stripeCustomerId]);

  const handleSubmit = useCallback(
    (id) => () => {
      const bundle = getPlanModalBundle({
        currentPlan,
        ensemblesId,
        plan,
        stripeCustomerId: id,
      });
      setModalBundle(bundle);
      openModal();
    },
    [key, stripeCustomerId]
  );

  return (
    <ActionsWrapper>
      <CurrentPlan visible={isSubscribedPlan} />
      <Button fullWidth primary onClick={handleSubmit(stripeCustomerId)}>
        {label}
      </Button>
      <CancelSubscription onClick={handleCancel} visible={isSubscribedPlan} />
    </ActionsWrapper>
  );
}

PlanActions.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    renewalPeriod: PropTypes.oneOf(['monthly', 'yearly']),
    renewalPeriodOpposite: PropTypes.oneOf(['monthly', 'yearly']),
  }),
};

function CancelSubscription({ onClick: handleClick, visible }) {
  if (!visible) return null;

  return (
    <Button fullWidth secondary onClick={handleClick}>
      Cancel Subscription
    </Button>
  );
}

CancelSubscription.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

function CurrentPlan({ visible: isVisible }) {
  if (!isVisible) return null;

  return (
    <Button fullWidth blueNotActive>
      Your Current Plan
    </Button>
  );
}

CurrentPlan.propTypes = {
  visible: PropTypes.bool,
};
