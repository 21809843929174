import {setCard, setPayments} from 'lib/store/rootSlice';
import {billingApi} from 'services/billingService';
import {subscriptionsApi} from 'services/subscriptionsService';
import {dispatchAndUnwrap, dispatchMultipleEndpoints} from 'utils/api';
import {isExpired} from 'utils/dates';
import {sleep} from 'utils/helpers';
import {createStripeCustomerAndUpdateCompany} from '../company/actions';
import {
  appleToStripeProductId,
  createSubscription,
} from '../subscriptions/index';

export async function getCard(store, stripeCustomerId, ensemblesId) {
  const { card } = await dispatchAndUnwrap(
    store,
    billingApi.endpoints.getCard,
    { stripeCustomerId }
  );

  store.dispatch(setCard({ card, ensemblesId }));

  return card;
}

export async function getPayments(store, ensemblesId) {
  const { payments } = await dispatchAndUnwrap(
    store,
    billingApi.endpoints.getPayments,
    { ensembles_id: ensemblesId }
  );

  store.dispatch(setPayments({ payments, ensemblesId }));

  return payments;
}

export async function getPaymentAndCardInfo(
  store,
  ensemblesId,
  stripeCustomerId
) {
  const results = await dispatchMultipleEndpoints(store, [
    {
      endpoint: billingApi.endpoints.getPayments,
      params: { ensembles_id: ensemblesId },
    },
    {
      endpoint: billingApi.endpoints.getCard,
      params: { stripeCustomerId },
    },
  ]);

  const [paymentsResult, cardResult] = results ?? [];
  const { payments } = paymentsResult ?? {};
  const { card } = cardResult ?? {};

  store.dispatch(setPayments({ payments, ensemblesId }));
  store.dispatch(setCard({ card, ensemblesId }));

  return { payments, card };
}

export async function manageCustomerCard(
  store,
  { selectedCompany, tokenId, update }
) {
  if (!selectedCompany) {
    return handleError('No company selected');
  }

  let stripeCustomerId = selectedCompany.stripe_customer_id;
  if (!stripeCustomerId) {
    try {
      stripeCustomerId = await createStripeCustomerAndUpdateCompany(
        store,
        selectedCompany
      );
    } catch (error) {
      return handleError(
        'Unable to create stripe customer. Please try again.',
        error
      );
    }
  }

  await sleep(256);

  try {
    await dispatchAndUnwrap(store, billingApi.endpoints.updateCard, {
      stripeCustomerId,
      tokenId,
    });

    // Wait for card to be fully updated
    await sleep(256);

    // Because we're possibly updating the card, we need to bust cache
    // pass true for forceRefetch
    const { card } = await dispatchAndUnwrap(
      store,
      billingApi.endpoints.getCard,
      { stripeCustomerId },
      true
    );
    store.dispatch(
      setCard({ card, ensemblesId: selectedCompany.ensembles_id })
    );
    return { success: true, error: null, card };
  } catch (error) {
    const message = update ? 'update' : 'add';
    console.error(`Manage Card: Unable to ${message} card. Please try again.`);
    console.error(error);
    return handleError(
      `Unable to ${message} card. Please contact customer support.`,
      error
    );
  }
}

export async function convertApplePlanToStripe({
  ensemblesId,
  productId,
  expirationDate: appleDate,
  stripeCustomerId,
  store,
}) {
  try {
    const subscriptionName = appleToStripeProductId[productId];
    const result = await createSubscription({
      appleDate,
      ensemblesId,
      subscriptionName,
      stripeCustomerId,
      store,
    });
    return result;
  } catch (error) {
    console.error(
      'Error converting Apple Costbook to Stripe Subscription:',
      error
    );
    throw error;
  }
}

export async function convertAppleCosbookToStripe({
  ensemblesId,
  expirationDate: appleDate,
  productId,
  stripeCustomerId,
  store,
}) {
  try {
    const subscriptionName = appleToStripeProductId(productId);
    const result = await createSubscription({
      appleDate,
      ensemblesId,
      subscriptionName,
      store,
    });
  } catch (error) {
    console.error(
      'Error converting Apple Costbook to Stripe Subscription:',
      error
    );
    throw error;
  }
}

export async function getSubscriptionsForCancelApple({ ensemblesId, store }) {
  try {
    const results = await dispatchMultipleEndpoints(store, [
      {
        endpoint: subscriptionsApi.endpoints.getCostbooks,
        params: { ensemblesId },
      },
      {
        endpoint: subscriptionsApi.endpoints.getExtraUsers,
        params: { ensemblesId },
      },
      {
        endpoint: subscriptionsApi.endpoints.getPlan,
        params: { ensemblesId },
      },
      {
        endpoint: subscriptionsApi.endpoints.getUsers,
        params: { ensemblesId },
      },
    ]);
    const [costbooks, extraUsers, plan, users] = results ?? [];

    // determine if apple is active
    const isAppleActive =
      plan?.source === 0 && !isExpired(plan?.expiration_date);

    return {
      costbooks,
      extraUsers,
      isAppleActive,
      plan,
      users,
    };
  } catch (error) {
    console.error('Fail to load subscriptions for canceling apple', error);
    throw error;
  }
}

export async function cancelAppleSubscription({ ensemblesId, reason, store }) {
  try {
    const response = await dispatchAndUnwrap(
      store,
      billingApi.endpoints.cancelAppleSubscription,
      { ensemblesId, reason }
    );

    return response;
  } catch (error) {
    console.error('Error canceling apple subscription:', error);
    throw error;
  }
}

function handleError(message, error) {
  console.error(message, error);
  if (error) {
    console.error(error);
  }
  return { success: false, error: message };
}
