import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { PDFViewer } from '@react-pdf/renderer';
import Icon from 'components/Icon';
import { Modal, ModalBody } from 'components/Modal';
import { COMPONENT_KEYS } from 'data/constants';
import PropTypes from 'prop-types';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback } from 'react';
import Receipt from './Receipt';

export default function ReceiptModal({ payment }) {
  const modalContext = useComponentStateContext();

  const open = modalContext.state?.open[COMPONENT_KEYS.RECEIPT_MODAL] || false;

  const handleClose = useCallback(() => {
    modalContext.setClose(COMPONENT_KEYS.RECEIPT_MODAL)();
  }, []);

  return (
    <Modal open={open} onClose={handleClose} size="lg">
      <ModalBody>
        <Box sx={{ height: '90vh', overflow: 'hidden' }}>
          <Header onClose={handleClose} />
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            {payment && <Receipt payment={payment} />}
          </PDFViewer>
        </Box>
      </ModalBody>
    </Modal>
  );
}

ReceiptModal.propTypes = {
  payment: PropTypes.object,
};

function Header({ onClose: handleClose }) {
  return (
    <HeaderContent>
      <CloseButton onClick={handleClose} />
    </HeaderContent>
  );
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
};

// ##############################
// ### Styles
// ##############################

const HeaderContent = styled((props) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="flex-end"
    {...props}
  />
))(({ theme }) => ({
  paddingTop: 0,
  marginTop: 0,
  paddingBottom: theme.spacing(2),
}));

const CloseButton = styled((props) => (
  <IconButton edge="end" {...props}>
    <Icon size="xs" icon={faTimes} />
  </IconButton>
))(({ theme }) => ({
  width: '40px',
  height: '40px',
  position: 'relative',
  top: '2px',
  color: theme.palette.charcoal.light,
}));
