import { selectSelectedCompany } from 'lib/store/rootSlice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  isFreeTrial as buildFreeTrialValidator,
  isDowngradeable,
  isSubscribedPlan,
  isUpgradeable,
} from '../utils';

export function usePlanButton(plan, currentPlan) {
  const companies = useSelector(selectSelectedCompany);
  const isFreeTrial = buildFreeTrialValidator(companies);
  const { name, id: planId } = plan ?? {};
  const {
    plan_id: currentPlanId,
    product_id,
    subscribed,
    renewalPeriod,
  } = currentPlan ?? {};

  const label = determinePlanButtonLabel(currentPlan, plan, isFreeTrial);

  return useMemo(() => {
    return {
      label,
      isSubscribedPlan: isSubscribedPlan(currentPlanId, planId, subscribed),
      key: `${name}_${currentPlanId}_${planId}_${product_id}_${renewalPeriod}_subscribed_${subscribed}`,
      subscribed,
    };
  }, [label, currentPlanId, planId, product_id, subscribed]);
}

export function useCostbookButton(currentCostbook, costbooks, subscribed) {
  const { subscriptions } = costbooks ?? {};
  const {
    product_id: currentCostbookId,
    renewalPeriod,
    subscribed: costbooksSubscribed,
    stripe_subscription_id: stripeSubscriptionId,
  } = currentCostbook ?? {};

  // Main button in a modal to submit a plan change.
  return useMemo(() => {
    let label = 'Select A plan first';
    let options = { blueNotActive: true };

    if (subscribed) {
      label = 'Add Subscription';
      options = { primary: true };
    }

    if (costbooksSubscribed && currentCostbookId === 10) {
      label = `Bill Me Yearly ${subscriptions.yearly.formatted_price}`;
      options = { primary: true };
    }

    if (costbooksSubscribed && currentCostbookId === 15) {
      label = `Bill Me Monthly ${subscriptions.monthly.formatted_price}`;
      options = { primary: true };
    }

    return {
      label,
      options,
      key: `${currentCostbookId}_${stripeSubscriptionId}_${renewalPeriod}_subscribed_${costbooksSubscribed}_${subscribed}`,
    };
  }, [
    currentCostbookId,
    stripeSubscriptionId,
    costbooksSubscribed,
    subscribed,
  ]);
}

export function useExtraUsersButton(currentPlan, extraUsers) {
  const { name, subscribed } = currentPlan ?? {};
  const {
    subscribed: extraUsersSubscribed,
    stripe_subscription_id: stripeSubscriptionId,
  } = extraUsers ?? {};

  return useMemo(() => {
    let label = 'Select A plan first';
    let options = {
      blueNotActive: true,
    };

    if (subscribed) {
      label = 'Add Subscription';
      options = { primary: true };
    }

    if (extraUsersSubscribed) {
      label = 'Cancel Subscription';
      options = { secondary: true };
    }

    return {
      label,
      options,
      key: `${name}_${stripeSubscriptionId}_subscribed_${extraUsersSubscribed}_${subscribed}`,
    };
  }, [subscribed, stripeSubscriptionId, extraUsersSubscribed]);
}

// ########################################################
// ### Helper methods
// ########################################################

// Need to do assign the label based on the current plan and the plan model.
const determinePlanButtonLabel = (currentPlan, plan, isFreeTrial) => {
  const {
    plan_id: currentPlanId,
    renewalPeriodOpposite,
    subscribed,
  } = currentPlan ?? {};

  const { id: planId, subscriptions } = plan ?? {};
  const { formatted_price: price } = subscriptions[renewalPeriodOpposite] ?? {};

  let label = 'Renew Subscription';

  if (isSubscribedPlan(currentPlanId, planId, subscribed)) {
    label = `Bill me ${renewalPeriodOpposite} for ${price}`;
  }

  if (isDowngradeable(currentPlanId, planId, subscribed)) {
    label = `Downgrade to ${plan.name}`;
  }

  if (isUpgradeable(currentPlanId, planId, subscribed)) {
    label = `Upgrade to ${plan.name}`;
  }

  if (isFreeTrial(currentPlanId)) {
    label = 'Start Free Trial';
  }

  return label;
};
