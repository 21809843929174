import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AddButton from 'components/Buttons/AddButton';
import CloseButton from 'components/Buttons/CloseButton';
import MobileDrawer from 'components/Drawer/MobileDrawer';
import { COMPONENT_KEYS } from 'data/constants';
import { useTransition } from 'hooks/useTransition';
import userSession from 'lib/storage/user';
import {
  selectCompanies,
  selectSelectedCompany,
  setSelectedCompany,
} from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hasValue } from 'utils/helpers';
import AddCompanyModal from './AddCompany/AddCompanyModal';
import CompanyButton from './CompanyButton';

export default function CompanyDrawer() {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanies);
  const modalContext = useComponentStateContext();
  const open = modalContext.state?.open[COMPONENT_KEYS.COMPANY_DRAWER] || false;
  const selectedCompany = useSelector(selectSelectedCompany);
  const theme = useTheme();
  const navigate = useNavigate();
  const { setPageNavigationDirection } = useTransition();

  const toggleDrawer = useCallback(
    (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      modalContext.toggleOpen(COMPONENT_KEYS.COMPANY_DRAWER)();
      modalContext.setDisabled(COMPONENT_KEYS.OPTIONS_DRAWER)(!open);
    },
    [open]
  );

  const handleClose = useCallback(() => {
    modalContext.setClose(COMPONENT_KEYS.COMPANY_DRAWER)();
    modalContext.setDisabled(COMPONENT_KEYS.OPTIONS_DRAWER)(false);
  }, []);

  const handleAddCompany = useCallback(() => {
    modalContext.setOpen(COMPONENT_KEYS.ADD_COMPANY_MODAL)();
    modalContext.setClose(COMPONENT_KEYS.COMPANY_DRAWER)();
    modalContext.setDisabled(COMPONENT_KEYS.OPTIONS_DRAWER)(false);
    navigate('/');
  }, []);

  const handleSelectCompany = (selected, items, event) => {
    const item = items.find((i) => i.ensembles_id === event.currentTarget.id);
    if (item.ensembles_id !== selected.ensembles_id) {
      const newSelectedCompany = companies.find(
        (company) => company.ensembles_id === item.ensembles_id
      );
      dispatch(setSelectedCompany(newSelectedCompany));
      userSession.setSelectedCompany(newSelectedCompany);
      if (location.pathname !== '/') {
        modalContext.setTransitioning(true);
        setPageNavigationDirection('page-back');
        navigate('/');
      }
    }
  };

  if (!hasValue(companies)) {
    return null;
  }

  return (
    <>
      <CompanyButton
        disabled={modalContext.state.disabled[COMPONENT_KEYS.COMPANY_DRAWER]}
        open={open}
        onClick={toggleDrawer}
        selectedCompanyName={
          selectedCompany?.name || <Skeleton variant="text" width={128} />
        }
      />
      <MobileDrawer
        open={open}
        onClose={handleClose}
        zIndex={theme.zIndex.appBar - 1}
      >
        <StackBox>
          <Toolbar />
          <Header />
          <StyledList>
            {companies.map((company) => (
              <ListItem disablePadding key={company.ensembles_id}>
                <ListItemButton
                  id={company.ensembles_id}
                  onClick={(event) => {
                    handleSelectCompany(selectCompanies, companies, event);
                    handleClose();
                  }}
                  selected={
                    company.ensembles_id === selectedCompany?.ensembles_id
                  }
                >
                  <ListItemText primary={company.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </StyledList>
          <Footer onAddCompany={handleAddCompany} onClose={handleClose} />
        </StackBox>
      </MobileDrawer>
      <AddCompanyModal />
    </>
  );
  //
}

function Header() {
  return (
    <Box>
      <StyledTitle>
        <span>Companies</span>
      </StyledTitle>
      <Divider />
    </Box>
  );
}

function Footer({ onAddCompany: handleAddCompany, onClose: handleClose }) {
  return (
    <>
      <Divider />
      <Box
        sx={{
          width: '100%',
          padding: 2,
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
        }}
      >
        <CloseButton fullWidth onClick={handleClose}>
          Close
        </CloseButton>
        <AddButton fullWidth onClick={handleAddCompany}>
          Add Company
        </AddButton>
      </Box>
    </>
  );
}

Footer.propTypes = {
  onAddCompany: PropTypes.func,
  onClose: PropTypes.func,
};

// ##############################
// ### Styles
// ##############################

const StackBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const StyledList = styled(List)({
  overflow: 'auto',
  flexGrow: 1,
});

const StyledTitle = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  '& span': {
    ...theme.typography.body1,
    color: theme.palette.charcoal.light,
    paddingBottom: theme.spacing(1),
    display: 'block',
  },
}));
