import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { selectAddCompany } from 'lib/store/rootSlice';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function Summary({ onClose: handleClose }) {
  const { company, plan } = useSelector(selectAddCompany);
  const companyName = company?.companyName || '';
  const planName = plan?.name || '';
  const rate = plan?.subscriptions?.monthly?.rate || '';
  const formattedPrice = plan?.subscriptions?.monthly?.formatted_price || '';
  const zipCode = company?.zipCode || '';

  return (
    <>
      <SummaryTableContainer>
        <Table aria-label="summary table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>Company:</StyledTableCell>
              <TableCell>{companyName}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Plan:</StyledTableCell>
              <TableCell>{planName}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Rate:</StyledTableCell>
              <TableCell>{rate}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Price Per Month:</StyledTableCell>
              <TableCell>
                {formattedPrice}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Zip Code:</StyledTableCell>
              <TableCell>{zipCode}</TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </SummaryTableContainer>
      <Button
        secondary
        startIcon={<Icon icon={faCheckCircle} />}
        onClick={handleClose}
        fullWidth
        sx={{ mb: 2 }}
      >
        OK
      </Button>
    </>
  );
}

Summary.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey.light,
  },
}));

const SummaryTableContainer = styled(TableContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
  minHeight: '320px',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  '& .MuiTable-root': {
    borderTop: '1px solid',
    width: '100%',
    borderColor: theme.palette.divider,
  },
}));
