import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import { Footer } from './Styles';

export default function ModalFooter({
  children,
  id = 'modal-footer',
  divider: showDivider = true,
}) {
  return (
    <>
      {showDivider && <Divider />}
      <Footer id={id}>{children}</Footer>
    </>
  );
}

ModalFooter.propTypes = {
  divider: PropTypes.bool,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};
