import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Icon from 'components/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

export default function Header({ icon, title, onClose: handleClose }) {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.spacing(2),
          backgroundColor: theme.palette.white,
          '& *': {
            userSelect: 'none',
          },
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          {icon && (
            <Icon size="lg" icon={icon} color={theme.palette.charcoal.main} />
          )}
          <Typography variant="h6" sx={{ color: theme.palette.charcoal.main }}>
            {title}
          </Typography>
        </Stack>
        <IconButton onClick={handleClose}>
          <Icon size="xs" icon={faTimes} />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
};
