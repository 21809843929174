import Divider from '@mui/material/Divider';
import ArrowButton from 'components/Buttons/ArrowButton';
import { FormSuccessAlert } from 'components/form/Styles';
import { FormCard, FormFooter, FormHeader, PageLayout } from 'features/login';
import PublicIndexLayout from 'layouts/PublicIndexLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSearchParmFromLocation } from 'utils/urls';

export default function ForgotPasswordSent() {
  return (
    <PublicIndexLayout>
      <PageLayout>
        <Content />
      </PageLayout>
    </PublicIndexLayout>
  );
}

function Content() {
  const location = useLocation();
  const navigate = useNavigate();
  const email = getSearchParmFromLocation(location, 'email') || 'your email';
  const message = `An email has been sent to ${email}. Please allow a few minutes for it to arrive.`;
  return (
    <FormCard>
      <FormHeader>Forgot Your Password?</FormHeader>
      <FormSuccessAlert sx={{ mb: 4, mt: 2 }}>{message}</FormSuccessAlert>
      <Divider />
      <FormFooter>
        <ArrowButton primary fullWidth onClick={() => navigate('/login')}>
          Sign In
        </ArrowButton>
      </FormFooter>
    </FormCard>
  );
}
