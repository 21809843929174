import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// ##############################
// ### Form Styled Components
// ##############################

export const FormCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  width: '100%',
  boxShadow: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '75%',
    maxWidth: '548px',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
}));

export const FormDescription = styled((props) => (
  <Typography variant="body1" component="p" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const FormHeader = styled((props) => (
  <Typography variant="h5" component="h2" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingBottom: theme.spacing(1),
}));

export const FormContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}));

export const FormFooter = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: 0,
  paddingTop: theme.spacing(2),
}));

export const PageFooter = styled((props) => (
  <Box component="footer" {...props} />
))(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(2),
  flexDirection: 'row',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 0,
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    width: '75%',
    maxWidth: '600px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

export const FormLink = styled((props) => <Link {...props} />)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
