import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icon from 'components/Icon';
import { forwardRef } from 'react';

export const ErrorTransitionWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  '&.error-text-enter': {
    display: 'block',
    opacity: 0,
    maxHeight: '0px',
  },

  '&.error-text-enter-active': {
    opacity: 1,
    maxHeight: '24px',
    transition: `opacity ${theme.transitions.duration.shorter}ms ease-in, max-height ${theme.transitions.duration.shorter}ms ease-in`,
  },

  '&.error-text-exit': {
    opacity: 1,
    maxHeight: '24px',
  },

  '&.error-text-exit-active': {
    opacity: 0,
    maxHeight: '0px',
    transition: `opacity ${theme.transitions.duration.shorter}ms ease-out, max-height ${theme.transitions.duration.shorter}ms ease-out`,
  },
}));

export const FormErrorAlert = forwardRef((props, ref) => (
  <Alert
    variant="filled"
    severity="error"
    color="error"
    ref={ref}
    icon={
      <Icon
        size="sm"
        icon={faExclamationCircle}
        style={{ position: 'relative', top: '1px' }}
      />
    }
    {...props}
  />
));

FormErrorAlert.displayName = 'FormErrorAlert';

export const FormSuccessAlert = styled((props) => (
  <Alert
    variant="filled"
    severity="success"
    icon={
      <Icon
        size="sm"
        icon={faCheckCircle}
        style={{ position: 'relative', top: '1px' }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.green.light,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.green.dark,
  width: '100%',
}));

export const InputErrorText = styled((props) => (
  <Typography variant="caption" {...props} />
))(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
  left: theme.spacing(1),
  position: 'relative',
  display: 'block',
}));

export const InputHintText = styled((props) => (
  <Typography variant="caption" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
  left: theme.spacing(1),
  position: 'relative',
  display: 'block',
}));

export const InputGutter = styled((props) => <Box {...props} />)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: '24px',
  })
);
