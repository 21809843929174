import {apiUrl} from 'data/constants';
import {api} from './api';

export const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Retrieves card information
    // Used in:
    // - actions/billing/getCard
    // - actions/billing/getPaymentAndCardInfo
    // - actions/company/handleAddCompanyCard
    getCard: builder.query({
      query: ({ stripeCustomerId }) => ({
        url: `${apiUrl}stripe/card`,
        method: 'POST',
        body: {
          stripe_customer_id: stripeCustomerId,
        },
      }),
      providesTags: (_result, _error, _args) => [
        { type: 'billing', id: 'BILLING_CARD' },
      ],
    }),

    // Retrieves payment information
    getPayments: builder.query({
      query: ({ ensembles_id }) => ({
        url: `${apiUrl}company/payments`,
        method: 'POST',
        body: {
          ensembles_id,
        },
      }),
      providesTags: (_result, _error, _args) => {
        const { ensembles_id } = _args ?? {};
        return [{ type: 'billing', id: `BILLING_PAYMENTS_${ensembles_id}` }];
      },
    }),

    // Creates a new Stripe customer
    createStripeCustomer: builder.mutation({
      query: ({ ensemblesId }) => ({
        url: `${apiUrl}stripe/createcustomer`,
        method: 'POST',
        body: {
          company_id: ensemblesId,
        },
      }),
    }),

    // Updates existing card information
    updateCard: builder.mutation({
      query: ({ stripeCustomerId, tokenId }) => ({
        url: `${apiUrl}stripe/updatecard`,
        method: 'POST',
        body: {
          stripe_customer_id: stripeCustomerId,
          stripe_card_token: tokenId,
        },
      }),
    }),
    cancelAppleSubscription: builder.mutation({
      query: ({ ensemblesId, reason }) => ({
        url: `${apiUrl}cancelapplesubscription`,
        method: 'POST',
        responseHandler: 'text',
        body: {
          ensembles_id: ensemblesId,
          reason,
        },
      }),
    }),
  }),
});

export const {
  useGetCardQuery,
  useCreateStripeCustomerMutation,
  useUpdateCardMutation,
  useGetPaymentsQuery,
} = billingApi;
