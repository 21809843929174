import { apiUrl } from 'data/constants';
import { setUpdateSelectedCompany } from 'lib/store/rootSlice';
import { api } from './api';

export const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateStripeConnect: builder.query({
      queryFn: async ({
        code,
        error: errorCode,
        errorDescription,
        ensemblesId,
        scope,
        token,
      }) => {
        let error;

        // Check if errorCode has a truthy value
        if (errorCode) {
          switch (errorCode) {
            case 'access_denied':
              error = 'Stripe connection cancelled.';
              break;
            default:
              error = `The account was not connected: ${errorDescription}`;
          }
        }

        if (error) {
          return { data: { success: false, error } };
        }

        // Proceed to try-catch for connecting to Stripe
        try {
          const response = await fetch(
            `${apiUrl}companies/${ensemblesId}/stripe-connect`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ scope, code }),
            }
          );

          if (!response.ok) {
            const error = await response.json();
            return {
              error: {
                status: response.status,
                message: error.message || 'Unknown error',
              },
            };
          }

          // If successful, remove the query params from the URL
          window.history.pushState(
            null,
            '',
            window.location.href.split('?')[0]
          );

          const data = await response.json();
          return { data: { success: true, error: undefined, ...data } };
        } catch (error) {
          console.log('Error', error);
        }

        return { data: { success: true, error: undefined } };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          // If successful, update the state to reflect the change
          // This will be reflected in the UI ( PaymentSettings )
          const { data } = await queryFulfilled;
          if (data?.success) {
            dispatch(
              setUpdateSelectedCompany({ stripe_connect_user_id_exists: true })
            );
          }
        } catch (error) {
          console.error('Query failed', error);
        }
      },
    }),
    deleteStripeConnect: builder.mutation({
      query: ({ ensemblesId }) => {
        return {
          url: `${apiUrl}companies/${ensemblesId}/stripe-connect`,
          method: 'DELETE',
          responseHandler: 'text',
        };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(
            setUpdateSelectedCompany({ stripe_connect_user_id_exists: false })
          );
        } catch (error) {
          console.error('Payment Error', error);
          // The server responds with plain text, not JSON. Setting responseHandler: 'text'
          // prevents JSON parsing errors. We catch errors here to handle any other issues,
          // but no JSON parsing error should occur due to the responseHandler setting.
        }
      },
    }),
    postPaymentIntent: builder.mutation({
      query: ({ body }) => {
        return {
          url: `${apiUrl}stripe-payment-intent`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res, _req, args) => {
        const { customerId, isPublic } = args ?? {};
        const { client_secret: clientSecret, metadata } = res ?? {};
        const { job_id: jobId, payment_id: paymentId } = metadata ?? {};

        const defaultBaseUrl = apiUrl || 'http://localhost/';
        let basePath = `/jobs/${jobId}`;
        let baseUrl = defaultBaseUrl;

        if (customerId) {
          basePath = `/customers/${customerId}/jobs/${jobId}`;
          baseUrl = `${defaultBaseUrl}customers/${customerId}`;
        }

        return {
          clientSecret,
          paymentId,
          returnPath: `${basePath}/payments/${paymentId}`,
          returnUrl: `${baseUrl}jobs/${jobId}/payments/${paymentId}`,
        };
      },
    }),
    postMockData: builder.mutation({
      // The query is not relevant here, so a 'null' returning 'queryFn' is used
      queryFn: () => ({ data: null }),
      transformResponse: (res, _req, args) => {
        const { search } = args ?? {};
        // console.log('Xfer Search:', search);
        return res;
      },
      onQueryStarted: () => {
        // console.log('postMockData started');
      },
    }),
  }),
});

export const {
  useValidateStripeConnectQuery,
  useDeleteStripeConnectMutation,
  usePostPaymentIntentMutation,
  usePostMockDataMutation,
} = paymentsApi;
