import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// ##############################
// ### Styles
// ##############################

const SlidePaper = styled(Paper)(({ _theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  overflowY: 'auto',
  position: 'absolute',
  top: 0,
  zIndex: 2,
  elevation: 0,
  square: true,
  display: 'flex',
  flexDirection: 'column',
}));

export default function SlideSheet({
  children,
  internalRef,
  direction = 'left',
  slide = false,
}) {
  return (
    <Slide
      in={slide}
      container={internalRef?.current}
      direction={direction}
      mountOnEnter
      unmountOnExit
    >
      <SlidePaper>{children}</SlidePaper>
    </Slide>
  );
}

SlideSheet.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  internalRef: PropTypes.shape({ current: PropTypes.any }),
  slide: PropTypes.bool,
};
