// src/utils/planUtils.js
import { isExpired } from 'utils/dates';
import { hasValue, isNil } from 'utils/helpers';

export const isSubscribedPlan = (currentPlanId, planId, subscribed) => {
  return currentPlanId === planId && subscribed;
};

export const isDowngradeable = (currentPlanId, planId, subscribed) => {
  return currentPlanId > planId && subscribed;
};

export const isUpgradeable = (currentPlanId, planId, subscribed) => {
  return currentPlanId < planId && subscribed;
};

// Used as a buildFreeTrialValidator in useButton.js and useModal.js
// We get companies from store and then pass this function down to
// helper functions to determine if a plan is a free trial based on planId
export const isFreeTrial = (companies) => (currentPlanId) => {
  if (!companies) {
    throw new Error(
      'Companies are required to determine if a plan is a free trial'
    );
  }
  const onlyCompany = companies.length <= 1;
  return onlyCompany && !hasValue(currentPlanId);
};

export const hasStripeSubscription = ({
  stripe_subscription_id,
  subscribed,
}) => {
  return hasValue(stripe_subscription_id) && subscribed;
};

export const isCostbooksSubscribed = (currentCostbookId, costbookId) => {
  return currentCostbookId === costbookId;
};

export const isActiveAppleSubscription = (plan) => {
  const { expiration_date: date, source } = plan ?? {};

  if (isNil(source) || isNil(date)) {
    return false;
  }

  return source === 0 && date && !isExpired(date);
};
