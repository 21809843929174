import { Elements } from '@stripe/react-stripe-js';
import Sheet from 'components/Drawer/Sheet';
import SlideSheet from 'components/Drawer/SlideSheet';
import TemporaryDrawer from 'components/Drawer/TemporaryDrawer';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useCustomerCardDrawer } from '../../hooks/useBilling';
import { useBillingContext } from '../../providers/BillingProvider';
import CancelAppleSlide from './CancelAppleSlide';
import CreditCardSlide from './CreditCardSlide';

export default function Drawer({ status }) {
  const cancelAppleSuccessRef = useRef(null);
  const cancelAppleErrorRef = useRef(null);
  const cancelAppleAddCardRef = useRef(null);

  const {
    closeDrawer,
    isDrawerOpen,
    slideInAppleError,
    slideInAppleSuccess,
    slideInAddCard,
  } = useCustomerCardDrawer();
  const { stripe } = useBillingContext();

  const renderContent = () => {
    switch (status) {
      case 'updateCard':
        return (
          <Sheet>
            <Elements stripe={stripe}>
              <CreditCardSlide update />
            </Elements>
          </Sheet>
        );
      case 'cancelApplePay': {
        return (
          <>
            <Sheet>
              <CancelAppleSlide />
            </Sheet>
            <SlideSheet
              slide={slideInAppleSuccess}
              internalRef={cancelAppleSuccessRef}
            >
              <CancelAppleSlide success />
            </SlideSheet>
            <SlideSheet
              slide={slideInAppleError}
              internalRef={cancelAppleErrorRef}
            >
              <CancelAppleSlide error />
            </SlideSheet>
            <SlideSheet
              slide={slideInAddCard}
              internalRef={cancelAppleAddCardRef}
            >
              <Elements stripe={stripe}>
                <CreditCardSlide />
              </Elements>
            </SlideSheet>
          </>
        );
      }
      case 'addCard':
        return (
          <Sheet>
            <Elements stripe={stripe}>
              <CreditCardSlide update={false} />
            </Elements>
          </Sheet>
        );
      default:
        return null;
    }
  };

  return (
    <TemporaryDrawer onClose={closeDrawer} open={isDrawerOpen} width={360}>
      {renderContent()}
    </TemporaryDrawer>
  );
}

Drawer.propTypes = {
  status: PropTypes.oneOf(['addCard', 'updateCard', 'cancelApplePay']),
};
