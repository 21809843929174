import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ icon, color, size, ...props }) => (
  <FontAwesomeIcon icon={icon} color={color} size={size} {...props} />
);

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf([
    'xs',
    'lg',
    'sm',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x',
  ]),
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  border: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  inverse: PropTypes.bool,
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  pull: PropTypes.oneOf(['left', 'right']),
  rotation: PropTypes.oneOf([90, 180, 270]),
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  className: PropTypes.string,
};

Icon.defaultProps = {
  color: 'inherit',
  size: '1x',
  spin: false,
  pulse: false,
  border: false,
  fixedWidth: false,
  inverse: false,
  flip: null,
  pull: null,
  rotation: null,
  transform: null,
  style: {},
  className: '',
};

export default Icon;
