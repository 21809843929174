import { COMPONENT_KEYS } from 'data/constants';
import { selectCompanies } from 'lib/store/rootSlice';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isExpired, setTrialEndDate } from 'utils/dates';
import { hasValue } from 'utils/helpers';
import {
  isFreeTrial as buildFreeTrialValidator,
  hasStripeSubscription,
  isDowngradeable,
  isSubscribedPlan,
  isUpgradeable,
} from '../utils';

import { costbooks, extraUsers } from '../data/plans';

export function useModal() {
  const { state, setOpen, setClose } = useComponentStateContext();
  const companies = useSelector(selectCompanies);
  const isFreeTrial = buildFreeTrialValidator(companies);
  const dispatch = useDispatch();

  /**
   *  Plan Modal Bundle
   */
  const getPlanModalBundle = useCallback(
    ({ currentPlan, ensemblesId, plan, stripeCustomerId }) => {
      const key = getSubscriptionKey(currentPlan, plan);
      const builder = getModalMessageBuilder(key);

      const label = getModalSubmitButton(currentPlan, plan, isFreeTrial);
      const title = getModalTitle(currentPlan, plan, isFreeTrial);

      const payload = getModalSubmitPayload(currentPlan, plan, isFreeTrial);

      return {
        label,
        messageBuilder: builder,
        title,
        key,
        payload: { ensemblesId, stripeCustomerId, ...payload },
      };
    },
    []
  );

  return useMemo(() => {
    /**
     * Cancel Modal Methods
     */
    const openCancelModal = setOpen(COMPONENT_KEYS.CANCEL_SUBSCRIPTION_MODAL);
    const isCancelOpen = !!state.open[COMPONENT_KEYS.CANCEL_SUBSCRIPTION_MODAL];
    const closeCancelModal = setClose(COMPONENT_KEYS.CANCEL_SUBSCRIPTION_MODAL);

    /**
     * Open Modal Methods
     */
    const openModal = setOpen(COMPONENT_KEYS.SUBSCRIPTION_MODAL);
    const isOpen = !!state.open[COMPONENT_KEYS.SUBSCRIPTION_MODAL];
    const closeModal = setClose(COMPONENT_KEYS.SUBSCRIPTION_MODAL);

    /**
     *  Cancel Modal Bundle
     */
    const getCancelModalBundle = ({
      currentPlan,
      ensemblesId,
      stripeCustomerId,
      planName,
    }) => {
      const title = `Cancel ${planName} Subscription`;
      const { stripe_subscription_id: stripeSubscriptionId } =
        currentPlan ?? {};

      return {
        title,
        ensemblesId,
        stripeCustomerId,
        stripeSubscriptionId,
        isExtraUsers: extraUsers.name === planName,
        isCostbooks: costbooks.name === planName,
      };
    };

    /**
     *  Costbooks Modal Bundle
     */
    const getCostbooksModalBundle = ({
      currentCostbooks,
      costbooks,
      ensemblesId,
      stripeCustomerId,
    }) => {
      const {
        product_id: productId,
        renewalPeriodOpposite,
        renewalPeriod,
        subscribed,
      } = currentCostbooks ?? {};
      const {
        subscriptions: { monthly, yearly },
      } = costbooks ?? {};

      let key = 'craftsmansMonthly';
      let payloadKey = 'renewSubscription';
      let price = monthly.formatted_price;
      let subscriptionName = monthly?.name;

      // Easy way to switch the price. If subscribed and the product id is montly, i.e. 10,
      // then we want the yearly price
      const isCraftsmansYearlySubscribed = subscribed && productId === 10;
      const isCraftsmansYearlyNotSubscribed = !subscribed && productId === 15;

      if (isCraftsmansYearlySubscribed || isCraftsmansYearlyNotSubscribed) {
        key = 'craftsmansYearly';
        price = yearly.formatted_price;
        subscriptionName = yearly?.name;
      }

      if (
        hasStripeSubscription(currentCostbooks) &&
        !isExpired(currentCostbooks?.expiration_date)
      ) {
        payloadKey = 'updateSubscription';
      }

      const renewal = subscribed ? renewalPeriodOpposite : renewalPeriod;

      return {
        label: `Bill Me ${renewal} ${price}`,
        messageBuilder: messageBuilder[key],
        title: `Add Craftsman Costbooks`,
        key,
        payload: {
          key: payloadKey,
          ensemblesId,
          stripeCustomerId,
          stripeSubscriptionId: currentCostbooks?.stripe_subscription_id,
          subscriptionName,
        },
      };
    };

    const getExtraUsersModalBundle = ({
      currentExtraUsers,
      extraUsers,
      ensemblesId,
      stripeCustomerId,
    }) => {
      const stripePlanName = extraUsers.subscription.name;

      const label = `Bill Me ${extraUsers.price.month}`;

      const key = `${stripePlanName}-${currentExtraUsers.subscribed}`;

      const messageBuilder = {
        text: `You will now be billed ${extraUsers.price.month}/mo for each additional user beyond the number included with your plan.`,
        variables: {},
      };

      return {
        label,
        messageBuilder,
        title: `Add Additional Users`,
        key,
        payload: {
          key: 'renewSubscription',
          ensemblesId,
          stripeCustomerId,
          subscriptionName: stripePlanName,
        },
      };
    };

    return {
      openModal,
      isOpen,
      closeModal,
      openCancelModal,
      isCancelOpen,
      closeCancelModal,
      getCancelModalBundle,
      getCostbooksModalBundle,
      getExtraUsersModalBundle,
      getPlanModalBundle,
    };
  }, [dispatch, setOpen, setClose, state]);
}

// ########################################################
// Helper methods
// ########################################################

function getSubscriptionKey(currentPlan, plan) {
  const {
    plan_id: currentPlanId,
    renewalPeriod,
    renewalPeriodOpposite,
    is_cancelled: isCancelled,
    subscribed,
  } = currentPlan ?? {};
  const { id: planId, name } = plan ?? {};

  const planName = name.toLowerCase();

  // Start of if we're on a free trial or renewing
  // by assigning key to be plan for the monthly rate
  let key = `${planName}Monthly`;

  if (!subscribed && isCancelled) {
    const rp = renewalPeriod.charAt(0).toUpperCase() + renewalPeriod.slice(1);
    key = `${planName}${rp}`;
  }

  if (isSubscribedPlan(currentPlanId, planId, subscribed)) {
    // lowercase the first letter of the name
    // capitalize the first letter of the renewal period
    // camelCase the two together.
    // E.g. basicMonthly, basicYearly
    const rp =
      renewalPeriodOpposite.charAt(0).toUpperCase() +
      renewalPeriodOpposite.slice(1);
    key = `${planName}${rp}`;
  }

  if (isDowngradeable(currentPlanId, planId, subscribed)) {
    key = `${planName}Downgrade`;
  }

  if (isUpgradeable(currentPlanId, planId, subscribed)) {
    key = `${planName}Upgrade`;
  }

  return key;
}

function getModalMessageBuilder(key) {
  const builder = messageBuilder[key];

  if (!hasValue(builder)) {
    console.error(`No message builder found for key: ${key}`);
  }

  return builder;
}

function getModalTitle(currentPlan, plan, isFreeTrial) {
  const {
    plan_id: currentPlanId,
    renewalPeriodOpposite,
    subscribed,
  } = currentPlan ?? {};

  const { id: planId, name, subscriptions } = plan ?? {};
  let title = `Renew ${name}`;

  if (isSubscribedPlan(currentPlanId, planId, subscribed)) {
    const renewalPeriod =
      renewalPeriodOpposite.charAt(0).toUpperCase() +
      renewalPeriodOpposite.slice(1);
    title = `Bill Me ${renewalPeriod}`;
  }

  if (isDowngradeable(currentPlanId, planId, subscribed)) {
    title = `Downgrade to ${name}`;
  }

  if (isUpgradeable(currentPlanId, planId, subscribed)) {
    title = `Upgrade to ${name}`;
  }

  if (isFreeTrial(currentPlanId)) {
    title = `Free Trial`;
  }

  return title;
}

function getModalSubmitButton(currentPlan, plan, isFreeTrial) {
  const {
    plan_id: currentPlanId,
    renewalPeriodOpposite,
    renewalPeriod,
    subscribed,
  } = currentPlan ?? {};
  const { id: planId, subscriptions } = plan ?? {};
  const { formatted_price: price } = subscriptions[renewalPeriodOpposite] ?? {};
  const { formatted_price: renewalPrice } = subscriptions[renewalPeriod] ?? {};

  let label = `Renew for ${renewalPrice}`;

  if (isSubscribedPlan(currentPlanId, planId, subscribed)) {
    label = `Bill me ${renewalPeriodOpposite} ${price}`;
  }

  if (isDowngradeable(currentPlanId, planId, subscribed)) {
    label = 'Downgrade';
  }

  if (isUpgradeable(currentPlanId, planId, subscribed)) {
    label = 'Upgrade';
  }

  if (isFreeTrial(currentPlanId)) {
    label = 'Start free trial';
  }

  return label;
}

function getModalSubmitPayload(currentPlan, plan, isFreeTrial) {
  const {
    plan_id: currentPlanId,
    renewalPeriod,
    renewalPeriodOpposite,
    subscribed,
    stripe_subscription_id,
  } = currentPlan ?? {};

  const { id: planId, subscriptions } = plan ?? {};

  const { monthly, yearly } = subscriptions;

  // Need to get subscriptionName.
  // The api calls subscriptionName: stripe_plan_id,
  // We can get it from subscriptions.monthly.name or subscriptions.yearly.name
  const planRenewalPeriod = isSubscribedPlan(currentPlanId, planId, subscribed)
    ? renewalPeriodOpposite
    : renewalPeriod;

  const subscriptionName =
    planRenewalPeriod === 'monthly' ? monthly?.name : yearly?.name;

  // tiral_end is the unix timestamp of when the trial ends
  const trialEnd = setTrialEndDate(currentPlan);

  // Assume we are creating a subscription
  let key = 'renewSubscription';

  // Should we update the subscription?
  if (
    hasStripeSubscription(currentPlan) &&
    !isExpired(currentPlan?.expiration_date)
  ) {
    key = 'updateSubscription';
  }

  // Last check for free trial
  if (isFreeTrial(currentPlanId)) {
    key = 'createFreeTrialSubscription';
  }

  return {
    key,
    stripeSubscriptionId: stripe_subscription_id,
    subscriptionName,
    trialEnd,
  };
}

// ########################################################
// ### Message Builder
// ########################################################

const messages = {
  renewalPeriod:
    'You will now be billed {renewalPeriod} for your ContractorTools {plan} plan.',
  downgrade:
    'Downgrading to the {plan} will remove the ability to create {reason}.',
  upgrade: 'Upgrading to the {plan} will add the ability to create {reason}.',
};

// Variable options for each message
// We break parts of the message into variables to be able to
// splice certain text back together as html with span tags etc.
// for styling purposes.
// See SubscriptionModal.jsx for how this is used in the buildMessage function.

const renewalPeriod = {
  monthly: 'monthly',
  yearly: 'yearly',
};

const plan = {
  basic: 'Basic',
  essentials: 'Essentials',
  pro: 'Pro',
};

const downgradeOptions = {
  basic: 'invoices and payments',
  essentials:
    'change orders, credits, draw schedules, multiple invoices per job, and multiple payments per job',
};

const upgradeOptions = {
  essentials: 'a single invoice per job and a single payment per job',
  pro: 'change orders, credits, draw schedules, multiple invoices per job, and multiple payments per job',
};

/**
 * Message Builder
 */
const messageBuilder = {
  basicMonthly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.monthly,
      plan: plan.basic,
    },
  },
  basicYearly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.yearly,
      plan: plan.basic,
    },
  },
  essentialsMonthly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.monthly,
      plan: plan.essentials,
    },
  },
  essentialsYearly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.yearly,
      plan: plan.essentials,
    },
  },
  proMonthly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.monthly,
      plan: plan.pro,
    },
  },
  proYearly: {
    text: messages.renewalPeriod,
    variables: {
      renewalPeriod: renewalPeriod.yearly,
      plan: plan.pro,
    },
  },
  basicDowngrade: {
    text: messages.downgrade,
    variables: {
      plan: plan.basic,
      reason: downgradeOptions.basic,
    },
  },
  essentialsDowngrade: {
    text: messages.downgrade,
    variables: {
      plan: plan.essentials,
      reason: downgradeOptions.essentials,
    },
  },
  essentialsUpgrade: {
    text: messages.upgrade,
    variables: {
      plan: plan.essentials,
      reason: upgradeOptions.essentials,
    },
  },
  proUpgrade: {
    text: messages.upgrade,
    variables: {
      plan: plan.pro,
      reason: upgradeOptions.pro,
    },
  },
  craftsmansMonthly: {
    text: 'The monthly Craftsman Costbooks subscription for $12.99/mo will be added to your account.',
    variables: {},
  },
  craftsmansYearly: {
    text: 'The yearly Craftsman Costbooks subscription for $129/yr will be added to your account.',
    variables: {},
  },
};
