import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';

export default function CompanyButton({
  disabled,
  open,
  onClick: handleClick,
  selectedCompanyName,
}) {
  return (
    <StyledButton disabled={disabled} open={open} onClick={handleClick}>
      {selectedCompanyName}
    </StyledButton>
  );
}

CompanyButton.propTypes = {
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  selectedCompanyName: PropTypes.any,
};

const StyledButton = styled(
  (props) => (
    <Button
      variant="text"
      endIcon={<Icon size="1x" icon={faChevronDown} />}
      {...props}
    />
  ),
  { shouldForwardProp: (prop) => prop !== 'open' }
)(({ theme, open }) => ({
  color: theme.palette.charcoal.main,
  textTransform: 'none',
  '&:hover': {
    color: theme.palette.blue.light,
  },
  svg: {
    color: theme.palette.blue.main,
    transform: `rotate(${open ? '180deg' : '0'})`,
    transition: 'transform 0.3s ease-in-out',
  },

  '&:disabled': {
    opacity: 0.5,
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '28ch',
  color: theme.palette.charcoal.main,
}));
