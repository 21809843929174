import { createGlobalStyle } from 'styled-components';
import { getColor } from './utils';

// Fonts are loaded in a separate static css file, because: https://github.com/styled-components/styled-components/issues/1593
export default createGlobalStyle`

  *{
    box-sizing: border-box;
  }

  html, body{
    padding: 0;
    margin: 0;
    height: 100%;
  }
  body{
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 16px;
    color: ${getColor('charcoal')};
    font-weight: normal;
    background-color: ${getColor('grey', 'light')};
    min-height: 100%;
  }

  p{
    font-size: 1em;
    margin: 0 0 15px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0 0 15px;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.15em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: .9em;
  }

  ul, ol{
    margin:0 0 15px;
    padding-left: 30px;
  }

  hr{
    display: block;
    width: 100%;
    height: 1px;
    background: ${getColor('grey', 'dark')};
  }

  a{
    color: ${getColor('blue')};
    text-decoration: none;
    &:hover{
      color: ${getColor('blue', 'dark')};
    }
    &:focus{
      outline-color: ${getColor('blue')};
      outline-width: 1px;
    }
  }

  img{
    max-width: 100%;
  }

  table{
    border-collapse: collapse;
    border-spacing: 0;
  }

  tr, th, td{
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
  }

  label{
    display: block;
    font-size: .85em;
  }
  .form-control{
    width: 100%;
    height: 46px;
    padding: 10px 15px;
    font-size: .9em;
    background-color: ${getColor('white')};
    background-image: none;
    border: 1px solid ${getColor('grey', 'dark')};
    border-radius: 4px;
    transition: border-color .15s linear;

    &:focus{
      outline: none;
      border-color: ${getColor('blue')};
    }

    &[type=number]::-webkit-outer-spin-button,
    &[type=number]::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    &input[type=number] {
      -moz-appearance:textfield; /* Firefox */
    }
  }


  /* Company Drawer for Mobile Toolbar */


  .company-drawer-menu{
    &&& {
      width: 100%;
      border: none;
      border-inline-end: none;
    }
  }

  /* Company Dropdown for Desktop Toolbar */

  .company-dropdown-menu .ant-dropdown-menu {
    padding: 8px;
  }

  .company-dropdown-menu .ant-dropdown-menu-item {
    min-width: 256px;
  }

  /* Company Dropdown and Drawer menu items and buttons */

  .company-dropdown-menu .ant-dropdown-menu-item-group,
  .company-dropdown-menu .ant-dropdown-menu-item-group div,
  .company-drawer-menu .ant-menu-item-group,
  .company-drawer-menu .ant-menu-item-group div{
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${getColor('grey', 'divider')};
  }

  .company-dropdown-menu .ant-dropdown-menu #menu-button, .company-drawer-menu #menu-button {
    color: ${getColor('green')};
    font-weight: bold;
    margin-bottom: 4px;
    &:hover {
      color: ${getColor('white')};
      background-color: ${getColor('green')};
    }
  }


  .company-dropdown-menu .ant-dropdown-menu-item-selected#menu-button, .company-drawer-menu .ant-menu-item-selected#menu-button {
    color: ${getColor('green')};
    background-color: ${getColor('white')};
    &:hover {
      color: ${getColor('white')};
      background-color: ${getColor('green')};
    }
  }



`;
// Not using .company-dropdown-menu .ant-dropdown-menu-item-selected#menu-button
// but keeping here to remember how to target in-case needed during refactor - 2/7/2024
