import { FORM_IDS } from 'data/constants';
import { getUserFromTokenAndSetUserInCache } from 'features/auth';
import { authApi } from 'services/authService';
import { dispatchAndUnwrap } from 'utils/api';
import { hasValue } from 'utils/helpers';
import { getRedirectOnLoginSuccess } from 'utils/urls';
export { PageLayout } from './components/PageLayout';
export { SignoutModal } from './components/SignoutModal';
export {
  FormCard,
  FormContent,
  FormDescription,
  FormFooter,
  FormHeader,
  FormLink,
  PageFooter,
} from './components/Styles';
export { useSignout } from './hooks/useSignout';

export const login = async (store, { email, password }) => {
  const { token } = await dispatchAndUnwrap(store, authApi.endpoints.login, {
    email,
    password,
  });

  const user = await getUserFromTokenAndSetUserInCache(token, store);

  const url = getRedirectOnLoginSuccess(user);
  return { url };
};

export const reset = async (store, { email }) => {
  await dispatchAndUnwrap(store, authApi.endpoints.reset, { email });
  return { url: `/forgot-password/sent?email=${email}`, email };
};

export const validationCallback = (id, value) => {
  let error = null;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Check if at least 8 characters, including 1 uppercase, 1 lowercase, and 1 digit and special characters are allowed

  switch (id) {
    case FORM_IDS.USER_EMAIL:
      if (!hasValue(value)) {
        error = 'Email is required.';
      } else if (!emailRegex.test(value)) {
        error = 'Email is invalid.';
      }
      break;
    case FORM_IDS.USER_PASSWORD:
      if (!hasValue(value)) {
        error = 'Password is required.';
      }
      break;
    default:
      break;
  }
  return error;
};
