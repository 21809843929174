import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import { COMPONENT_KEYS } from 'data/constants';
import { useTransition } from 'hooks/useTransition';
import userSession from 'lib/storage/user';
import {
  selectCompanies,
  selectSelectedCompany,
  setSelectedCompany,
} from 'lib/store/rootSlice';
import { useComponentStateContext } from 'providers/ComponentStateProvider';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasValue } from 'utils/helpers';
import AddCompanyModal from './AddCompany/AddCompanyModal';
import CompanyButton from './CompanyButton';

export default function CompanyDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanies);
  const modalContext = useComponentStateContext();
  const open = Boolean(anchorEl);
  const selectedCompany = useSelector(selectSelectedCompany);
  const location = useLocation();
  const navigate = useNavigate();
  const { setPageNavigationDirection } = useTransition();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnAddCompany = useCallback(() => {
    modalContext.setOpen(COMPONENT_KEYS.ADD_COMPANY_MODAL)();
    setAnchorEl(null);
    navigate('/');
  }, []);

  const handleSelectCompany = (selected, items, event) => {
    const item = items.find((i) => i.ensembles_id === event.currentTarget.id);
    if (item.ensembles_id !== selected.ensembles_id) {
      const newSelectedCompany = companies.find(
        (company) => company.ensembles_id === item.ensembles_id
      );
      dispatch(setSelectedCompany(newSelectedCompany));
      userSession.setSelectedCompany(newSelectedCompany);

      if (location.pathname !== '/') {
        modalContext.setTransitioning(true);
        setPageNavigationDirection('page-back');
        navigate('/');
      }
    }
  };

  if (!hasValue(companies)) {
    return null;
  }

  return (
    <>
      <StyledDivider orientation="vertical" flexItem />
      <CompanyButton
        open={open}
        onClick={handleClick}
        selectedCompanyName={
          selectedCompany?.name || <Skeleton variant="text" width={128} />
        }
      />
      <CompaniesMenu
        anchorEl={anchorEl || null}
        open={open}
        onClose={handleClose}
      >
        <MenuHeader>
          <span>Companies</span>
        </MenuHeader>
        <Divider sx={{ mb: 1 }} />
        {companies.map((company) => (
          <MenuItem
            key={company.ensembles_id}
            id={company.ensembles_id}
            selected={company.ensembles_id === selectedCompany?.ensembles_id}
            onClick={(event) => {
              handleSelectCompany(selectCompanies, companies, event);
              handleClose();
            }}
          >
            <CompanyText>{company.name}</CompanyText>
          </MenuItem>
        ))}
        <Divider />
        <MenuFooterButton onClick={handleOnAddCompany}>
          Add Company
        </MenuFooterButton>
      </CompaniesMenu>
      <AddCompanyModal />
    </>
  );
}

// ##############################
// ### Styles
// ##############################

const ITEM_HEIGHT = 48;

const CompaniesMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    PaperProps={{
      style: {
        // maxHeight: `calc(${ITEM_HEIGHT}px * 5)`,
        maxHeight: '90svh',
        width: '28ch',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const CompanyText = styled((props) => (
  <Typography variant="body2" {...props} />
))(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '28ch',
  color: theme.palette.charcoal.main,
}));

const MenuHeader = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  '& span': {
    ...theme.typography.subtitle2,
    color: theme.palette.charcoal.light,
    paddingBottom: theme.spacing(1),
    display: 'block',
  },
}));

const MenuFooterButton = styled((props) => (
  <Button startIcon={<Icon icon={faPlus} />} variant="text" {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: theme.palette.green.main,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  paddingLeft: theme.spacing(3),
  '&:hover': {
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.dark,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
