import { apiUrl } from 'data/constants';
import { isChangeOrder } from 'features/jobs';
import { setTempPublicAccount } from 'lib/store/rootSlice';
import { formatCurrency } from 'utils/currency';
import {
  apiToUTC,
  formatDateForPurchaseOrder,
  formatInBrowserLocale,
} from 'utils/dates';
import { hasValue } from 'utils/helpers';
import { api } from './api';
import { ESTIMATE_STATUS } from 'data/constants';

export const publicApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicPurchaseOrders: builder.query({
      query: () => ({
        url: `${apiUrl}purchaseorders`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        transformPurchaseOrdersResponse(response),
      providesTags: ['PurchaseOrders'],
    }),
    getPublicEstimate: builder.query({
      query: ({ estimateId }) => ({
        url: `${apiUrl}public/estimate/${estimateId}`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        transformPublicEstimateResponse(response),
      onQueryStarted: (arg, lifecycleApi) =>
        setTempPubliceAccountOnQueryStarted(arg, lifecycleApi),
      providesTags: (result, error, estimateId) => [
        { type: 'EstimateDetails', id: estimateId },
      ],
    }),
    getPublicInvoice: builder.query({
      query: ({ invoiceId }) => ({
        url: `${apiUrl}public/invoice/${invoiceId}`,
        method: 'GET',
      }),
      transformResponse: (response) => transformPublicInvoiceResponse(response),
      onQueryStarted: (arg, lifecycleApi) =>
        setTempPubliceAccountOnQueryStarted(arg, lifecycleApi),
      providesTags: (result, error, invoiceId) => [
        { type: 'InvoiceDetails', id: invoiceId },
      ],
    }),
    getPublicInvoices: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/invoices`,
        method: 'GET',
      }),
      transformResponse: (response) => transformInvoicesResponse(response),
    }),
    getPublicPayment: builder.query({
      query: ({ paymentId }) => ({
        url: `${apiUrl}public/payment/${paymentId}`,
        method: 'GET',
      }),
      onQueryStarted: (arg, lifecycleApi) =>
        setTempPubliceAccountOnQueryStarted(arg, lifecycleApi),
      transformResponse: (response) => transformPublicPaymentResponse(response),
      providesTags: (result, error, paymentId) => [
        { type: 'PaymentDetails', id: paymentId },
      ],
    }),
    getPublicPayments: builder.query({
      query: ({ jobId }) => ({
        url: `${apiUrl}jobs/${jobId}/payments`,
        method: 'GET',
      }),
      transformResponse: (response) => transformPaymentsResponse(response),
    }),
    inviteCustomer: builder.mutation({
      // Body: { email, first_name, last_name, contact_id }
      query: (body) => {
        return {
          url: `${apiUrl}invitecustomer`,
          method: 'POST',
          body,
        };
      },
    }),
    // Body: { email, password }
    acceptCustomer: builder.mutation({
      query: (body) => {
        return {
          url: `${apiUrl}acceptcustomer`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetPublicPurchaseOrdersQuery,
  useGetPublicInvoiceQuery,
  useGetPublicInvoicesQuery,
  useGetPublicEstimateQuery,
  useGetPublicPaymentQuery,
  useGetPuglicPaymentsQuery,
  useInviteCustomerMutation,
  useAcceptCustomerMutation,
} = publicApi;

// ##########################################
// ### Helpers
// ##########################################
function transformPublicEstimateResponse(response) {
  const { estimate } = response;
  return {
    ...response,
    estimate: {
      ...estimate,
    status: ESTIMATE_STATUS[estimate?.status],
      viewed: estimate?.viewed
        ? formatInBrowserLocale(estimate.viewed)
        : 'Never',
    },
  };
}

function transformPublicInvoiceResponse(response) {
  const { invoice } = response;
  return {
    ...response,
    invoice: {
      ...response?.invoice,
      amount: formatCurrency(invoice?.cached_amount_due || 0),
      date: formatInBrowserLocale(apiToUTC(invoice?.issue_date)),
      invoiceNumber: invoice?.number,
      invoiceType:
        Number(invoice?.cached_amount_due) >= 0 ? 'Invoice' : 'Credit Memo',
      viewed: invoice?.viewed
        ? formatInBrowserLocale(apiToUTC(invoice?.viewed))
        : 'Never',
    },
  };
}

function transformInvoicesResponse(response) {
  const mapped = response.map((invoice) => ({
    ...invoice,
    altText: 'Invoice Icon',
    amount: formatCurrency(invoice?.cached_amount_due || 0),
    date: formatInBrowserLocale(apiToUTC(invoice?.issue_date)),
    desc: invoice?.long_description,
    icon: '/icons/accountsrec_128x128.png',
    isPublic: false,
    invoiceNumber: invoice?.number,
    invoiceType:
      Number(invoice?.cached_amount_due) >= 0 ? 'Invoice' : 'Credit Memo',
    viewed: invoice?.viewed
      ? formatInBrowserLocale(apiToUTC(invoice?.viewed))
      : 'Never',
  }));

  const sorted = mapped.sort((a, b) =>
    b.issue_date.localeCompare(a.issue_date)
  );

  return sorted;
}

function transformPublicPaymentResponse(response) {
  const { payment } = response;
  return {
    ...response,
    payment: {
      ...payment,
      amount: formatCurrency(payment?.amount || 0),
      date: payment?.date ? formatInBrowserLocale(payment.date) : 'Not paid', // This should never happen, but just in case
      hasPaid: !!payment?.date,
      viewed: payment?.viewed ? formatInBrowserLocale(payment.viewed) : 'Never',
    },
  };
}

function transformPaymentsResponse(response) {
  const type = {
    0: 'Deposit',
    1: 'Payment',
    2: 'Refund',
  };

  return response
    .map((payment) => ({
      ...payment,
      cached_amount: payment?.amount,
      amount: formatCurrency(payment?.amount || 0),
      altText: 'Payment Icon',
      date: formatInBrowserLocale(payment?.date),
      icon: '/icons/cash_128x128.png',
      isPublic: false,
      paymentMethod: payment?.payment_method,
      paymentType: type[payment?.payment_type],
      processing: !payment?.saved_to_client_db && !payment?.stripe_receipt_url,
      receiptUrl: payment?.stripe_receipt_url,
      showInvoice: payment?.saved_to_client_db,
      showLinkToStripeReceipt: payment?.stripe_receipt_url,
      viewed: payment?.viewed
        ? formatInBrowserLocale(apiToUTC(payment?.viewed))
        : 'Never',
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // sort desc
}

function transformPurchaseOrdersResponse(response) {
  const total = formatCurrency(
    response?.reduce((amount, e) => {
      return amount + Number(e.cached_amount ?? 0);
    }, 0) ?? 0
  );

  const rows = response.map((po) => ({
    id: po.id,
    amount: formatCurrency(po.cached_amount || 0),
    deliveryDate: formatDateForPurchaseOrder(po.delivery_date),
    vendorName: po.vendor_name,
    venderId: po.vendor_id,
    contact: po.contact,
    accountNumber: po.account_number,
    orderDate: formatDateForPurchaseOrder(po.order_date),
    uploadDate: formatDateForPurchaseOrder(po.upload_date),
    status: po?.status ? 'Sent to Bid Room' : 'Printed/Emailed/Shared',
  }));

  return {
    rows,
    response,
    total,
  };
}

async function setTempPubliceAccountOnQueryStarted(
  _arg,
  { dispatch, queryFulfilled }
) {
  try {
    const { data } = await queryFulfilled;

    const idMap = {
      invoice: 'invoice',
      estimate: 'estimate',
      payment: 'payment',
    };

    const id = Object.keys(idMap).find((key) => data[key]?.id);
    const hasCustomer = hasValue(data?.customer?.user_id);
    const redirectURL = makeRedirectURL(id, data?.job?.id, data[id]);

    if (!id) {
      throw new Error('No id found in response for public account');
    }

    dispatch(setTempPublicAccount({ ...data, redirectURL, hasCustomer, id }));
  } catch (error) {
    console.log('Error: ', error);
  }
}

function makeRedirectURL(id, jobId, data) {
  switch (id) {
    case 'invoice':
      return `/jobs/${jobId}/invoices/${data.id}`;
    case 'estimate': {
      const subDirectory = isChangeOrder(data?.estimate_type)
        ? 'change-orders'
        : 'estimate';
      return `/jobs/${jobId}/${subDirectory}/${data?.id}`;
    }
    case 'payment':
      return `/jobs/${jobId}/payments/${data.id}`;
    default:
      return '/';
  }
}
