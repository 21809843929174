export const homeNavigation = [
  {
    altText: 'Jobs Icon',
    animate: true,
    src: '/icons/home_128x128.png',
    text: 'Jobs',
    to: '/jobs',
  },
  {
    altText: 'Customers Icon',
    animate: true,
    src: '/icons/user_128x128.png',
    text: 'Customers',
    to: '/customers',
    permission: 'customers_view',
  },
  {
    altText: 'Account Icon',
    src: '/icons/account_48x48.png',
    text: 'Account Info',
    to: '/account',
  },
  {
    altText: 'Affiliate Icon',
    src: '/icons/tax_48x48.png',
    text: 'Affiliate Info',
    to: '/affiliate',
    permission: 'affiliateData_view',
  },
  {
    altText: 'Subscriptions Icon',
    src: '/icons/subscriptions_48x48.png',
    text: 'Subscriptions',
    to: '/subscriptions',
    permission: 'subscriptionSettings_view',
  },
  {
    altText: 'Billing Icon',
    src: '/icons/billing_48x48.png',
    text: 'Billing Info',
    to: '/billing',
    permission: 'billingSettings_view',
  },
  {
    altText: 'Online Payment Settings Icon',
    src: '/icons/user_128x128.png',
    text: 'Online Payment Settings',
    to: '/payment-settings',
    permission: 'paymentSettings_view',
  },
  {
    altText: 'Downloads Icon',
    src: '/icons/downloads_48x48.png',
    text: 'Download the App',
    to: '/downloads',
  },
  {
    altText: 'Signout Icon',
    src: '/icons/signout_48x48.png',
    text: 'Sign Out',
    to: null,
  },
];

export const ROUTES = {
  customer: '/customers/:customerId/*',
  customerChangeOrders: '/customers/:customerId/jobs/:jobId/change-orders/*',
  customerChangeOrder:
    '/customers/:customerId/jobs/:jobId/change-orders/:changeOrderId/*',
  customerEstimate: '/customers/:customerId/jobs/:jobId/estimate/*',
  customerEstimateDocument:
    '/customers/:customerId/jobs/:jobId/estimate/document/*',
  customerInvoices: '/customers/:customerId/jobs/:jobId/invoices/*',
  customerInvoice: '/customers/:customerId/jobs/:jobId/invoices/:invoiceId/*',
  customerJob: '/customers/:customerId/jobs/:jobId/*',
  customerJobs: '/customers/:customerId/jobs/*',
  customerPayments: '/customers/:customerId/jobs/:jobId/payments/*',
  customerPayment: '/customers/:customerId/jobs/:jobId/payments/:paymentId/*',
  changeOrders: '/jobs/:jobId/change-orders/*',
  changeOrder: '/jobs/:jobId/change-orders/:changeOrderId/*',
  estimate: '/jobs/:jobId/estimate/*',
  estimateDocument: '/jobs/:jobId/estimate/document/*',
  invoices: '/jobs/:jobId/invoices/*',
  invoice: '/jobs/:jobId/invoices/:invoiceId/*',
  job: '/jobs/:jobId/*',
  payments: '/jobs/:jobId/payments/*',
  payment: '/jobs/:jobId/payments/:paymentId/*',
  impersonate: '/admin/impersonate',
};
