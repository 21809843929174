import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// ##############################
// ### Styles
// ##############################

const SheetPaper = styled(Paper)(({ _theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export default function Sheet({ children }) {
  return <SheetPaper>{children}</SheetPaper>;
}

Sheet.propTypes = {
  children: PropTypes.node,
};
