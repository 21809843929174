import Button from 'components/Buttons/Button';
import { CTLogoWrapper } from 'components/LoadingApp/style';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from '../../styles/global-styles';

export default function FeatureNotAvailable() {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Stack>
        <Header>Feature Not Available</Header>
        <Typography>
          It looks like you tried to access a feature that is not included in
          your company's plan. Please contact us to upgrade your plan.
        </Typography>
        <Button
          blue
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          Home
        </Button>
      </Stack>
    </PageWrapper>
  );
}

function PageWrapper({ children }) {
  return (
    <>
      <GlobalStyles />
      <Background>
        <Grid>
          <CenterColumn>
            <CTLogoWrapper>
              <img src="/images/ct-logo-white.png" alt="Contractor Tools" />
            </CTLogoWrapper>
            {children}
          </CenterColumn>
        </Grid>
      </Background>
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.any,
};

// ########################################################
// ### STYLED COMPONENTS
// ########################################################

const Background = styled.div`
  background-color: navy;
  height: 100vh;
`;

const Grid = styled.main`
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 16px 1fr 16px;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 600px) {
    grid-template-columns: 0.25fr 1fr 0.25fr;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto 1fr;
  }
`;

const CenterColumn = styled.div`
  grid-area: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin: 0 auto;
  margin-top: 32px;
  gap: 32px;
  width: 100%;

  @media (min-width: 960px) {
    width: 720px;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 48px;
  background-color: white;
  color: black;
  width: 100%;
  border-radius: 4px;
`;

const LogoContainer = styled.div`
  width: 80%;
  margin-top: 32px;
`;

const Header = styled.h4`
  font-size: 1.5em;
  font-weight: 500;
  margin: 0.5em 0;
`;

const Typography = styled.p`
  font-size: 0.875em;
  margin: 0.25em 0;
`;
