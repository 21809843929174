import {css} from 'styled-components'

const screenSizes = {
  xSmall: 480,
  small: 768,
  medium: 992,
  large: 1170,
  xLarge: 1400,
};

// Convert pixels to ems
const pxToEm = (px) => `${px / 16}em`;

// Define media query functions for 'up' and 'down' scenarios
const createMediaQuery = (minWidth = null, maxWidth = null) => (...args) => css`
  @media ${minWidth && `(min-width: ${pxToEm(minWidth)})`} ${minWidth && maxWidth && 'and'} ${maxWidth && `(max-width: ${pxToEm(maxWidth - 1)})`} {
    ${css(...args)}
  }
`;

// Generate 'up' and 'down' breakpoints
const generateBreakpoints = (sizes) => {
  const up = Object.keys(sizes).reduce((result, breakpoint) => ({
    ...result,
    [breakpoint]: createMediaQuery(sizes[breakpoint]),
  }), {});

  const down = Object.keys(sizes).reduce((result, breakpoint, index, array) => {
    const nextBreakpoint = sizes[array[index + 1]];
    return {
      ...result,
      [breakpoint]: createMediaQuery(null, nextBreakpoint || Infinity),
    };
  }, {});

  return { up, down };
};

// Create the media utility with 'up' and 'down' methods for each breakpoint
export const media = generateBreakpoints(screenSizes);

const colors = {
  blue: {
    base: '#2B87D8',
    // light: '#6AB0F3',
    light: '#F1F8FF', 
    dark: '#1E5A8A',
  },
  yellow: {
    base: '#F9E448',
    dark: '#FFC107'
  },
  green: {
    base: '#5CB85C',
    light: '#CBEBCB',
  },
  red: {
    base: '#D55D65',
    dark: '#A43D44',
  },
  grey: {
    base: '#E4E4E4',
    light: '#F7F7F9',
    dark: '#999999',
    divider: '#EAEAEA',
  },
  charcoal: {
    base: '#46494B',
  },
  white: {
    base: '#FFFFFF',
  },
  black: {
    base: '#000000',
  }
}

const fontWeights = {
  normal: 400,
  light: 200,
  bold: 700
}

/**
 * Get a color hex value
 * @param  {string} color     the color group
 * @param  {string} variation the variation if any
 * @return {string}           The chosen color (defaults to white if not found)
 * @example ${getColor('blue')} //returns the blue base color
 * @example ${getColor('blue', 'dark')} //returns the dark variation of blue
 */
export function getColor(color, variation){
  const colorGroup = colors[color]
  return variation ? colorGroup[variation] : colorGroup.base || '#FFFFFF'
}

export function getSpacing(size){
  return `${size * 8}px`
}

/**
 * Gets a weight value given a string name
 * @param  {string} weight normal, light, bold
 * @return {number}        the number weight
 * @example ${getWeight('bold')}
 */
export function getWeight(weight){
  return fontWeights[weight]
}


export default {
  media,
  getColor
}
