import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import { useFetcher } from 'react-router-dom';
import { plans } from '../data/plans';

export default function Plans() {
  return (
    <PlansWrapper>
      {plans.map((model) => (
        <PlanView key={model.name} model={model} />
      ))}
    </PlansWrapper>
  );
}

function PlanView({ model }) {
  const fetcher = useFetcher({ key: 'addCompany' });
  return (
    <PlanWrapper
      component={fetcher.Form}
      method="post"
      action="/"
      id={model?.id}
    >
      <input type="hidden" name="plan" value={model.name} />
      <Plan model={model} />
      <ActionsWrapper>
        <Button
          fullWidth
          primary
          name="intent"
          type="submit"
          value="addCompany-signup"
        >
          {`Sign up for ${model?.name}`}
        </Button>
      </ActionsWrapper>
    </PlanWrapper>
  );
}

PlanView.propTypes = {
  model: PropTypes.object,
};

function Plan({ model }) {
  const { name, subscriptions, users, callouts } = model ?? {};
  const { monthly, yearly } = subscriptions ?? {};
  const monthlyPrice = monthly.formattedPrice;
  const monthlyTxt = 'mo';
  const annualPrice = yearly.formattedPrice;
  const annualTxt = 'yr';
  const plural = users > 1 ? 's' : '';

  return (
    <PlanPanel>
      <Typography variant="h5">{name}</Typography>
      <Divider sx={{ width: '3.125em', my: 2 }} />
      <MonthPrice>
        {monthlyPrice}
        <span>/{monthlyTxt}</span>
      </MonthPrice>
      <YearPrice>
        {annualPrice}
        <span>/{annualTxt}</span>
      </YearPrice>
      <UsersLabel>
        {users} User{plural}
      </UsersLabel>
      <CalloutList>
        {callouts.defaults.map((callout) => (
          <Callout key={callout}>
            <span>
              <Dot />
            </span>
            {callout}
          </Callout>
        ))}
        {callouts.extras.map((callout) => (
          <CalloutHightlight key={callout}>
            <span>
              <Icon icon={faCheck} />
            </span>
            {callout}
          </CalloutHightlight>
        ))}
      </CalloutList>
    </PlanPanel>
  );
}

Plan.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subscriptions: PropTypes.shape({
      monthly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
      yearly: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }),
    }),
    users: PropTypes.number,
    callouts: PropTypes.shape({
      defaults: PropTypes.arrayOf(PropTypes.string),
      extras: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

// ##############################
// ### Styles
// ##############################

const PlanWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    maxWidth: '296px',
  },
}));

const PlansWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  width: '100%',
  '& > button': {
    marginBottom: theme.spacing(2),
  },
}));

// ##############################
// ### Plan Styled Components
// ##############################

const PlanPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%',
  height: '480px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const MonthPrice = styled((props) => <Typography variant="h4" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    '& span': {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

const YearPrice = styled((props) => <Typography variant="body1" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& span': {
      marginLeft: '2px',
    },
  })
);

const UsersLabel = styled((props) => <Typography variant="body1" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.dark,
    padding: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  })
);

const CalloutList = styled((props) => <Box component="ul" {...props} />)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: 0,
    paddingLeft: theme.spacing(1),
    '& li': {
      marginTop: theme.spacing(0.25),
    },
  })
);

const Callout = styled((props) => <Box component="li" {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.charcoal.dark,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    listStyle: 'none',
    '& span': {
      display: 'block',
      margin: 0,
      padding: 0,
      marginRight: theme.spacing(1),
    },
  })
);

const CalloutHightlight = styled((props) => <Callout {...props} />)(
  ({ theme }) => ({
    color: theme.palette.green.main,
    span: {
      color: theme.palette.green.main,
      display: 'block',
      position: 'relative',
      left: '-4px',
    },
  })
);

const Dot = styled((props) => <Box component="span" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.charcoal.light,
    borderRadius: '50%',
    display: 'block',
    height: '6px',
    width: '6px',
  })
);
