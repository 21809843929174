import { jwtDecode } from 'jwt-decode';
import { isDateInPast } from 'utils/dates';
import localStorageHelper from './localStorageHelper';

// If you want to use this in a component, you can import it like this:
// import userSession from 'lib/storage/user';
// Then access the methods like this:
// userSession.getUser();
export default {
  createUserDataFromToken,
  getUser,
  setUser,
  getToken,
  tokenHasExpired,
  setSelectedCompany,
  getSelectedCompany,
  removeUser,
  getUserCustomerId,
  getUserCookie,
  setUserCookie,
};

// alacarte imports
export {
  createUserDataFromToken,
  getSelectedCompany,
  getToken,
  getUser,
  getUserCookie,
  getUserCustomerId,
  removeUser,
  setSelectedCompany,
  setUser,
  setUserCookie,
  tokenHasExpired,
};

/**
 * Returns the user object from our localstorage
 * @return {object} user object
 */
function getUser() {
  return localStorageHelper.getItem('user');
}

/**
 * Sets the user value in localStorage
 * @param {object} user The user object to store
 */
function setUser(user) {
  if (!user) {
    return;
  }
  localStorageHelper.setItem('user', user);
  return user;
}

/**
 * Creates an object from a decodedToken that we want to store in our session data
 * @param  {object} decodedToken A decoded token
 * @return {object}              An object that we want to store
 */
function createUserDataFromToken(token) {
  const decodedToken = jwtDecode(token);
  return Object.assign({}, decodedToken, {
    token,
  });
}

/**
 * Returns the jwt token from our user localstorage object
 * @return {string} the token
 */
function getToken() {
  const user = getUser();
  return user ? user.token : undefined;
}

/**
 * Checks if the token we have stored is expired
 * @return {boolean} true if expired
 */
function tokenHasExpired(token) {
  if (!token) {
    return true;
  }

  const user = createUserDataFromToken(token);
  return (
    !user ||
    !user.token ||
    !user.exp ||
    user.invalidToken ||
    isDateInPast(new Date(user.exp * 1000))
  );
}

/**
 * Sets the selected company in our localStorage
 * @param {object} company The org that is selected
 */
function setSelectedCompany(company) {
  try {
    localStorageHelper.setItem('selectedCompany', company);
  } catch (e) {
    console.error('Error setting selectedCompany in localStorage', e);
  }
}

/**
 * Gets the selected company as stored in localStorage
 * @return {object} The company that is selected
 */
function getSelectedCompany() {
  return localStorageHelper.getItem('selectedCompany');
}

/**
 * Remove user from localStorage and sessionStorage and clear any cookies
 */
function removeUser() {
  localStorageHelper.removeItem('user');
  localStorageHelper.removeItem('selectedCompany');
  document.cookie = `signinjwttoken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${
    process.env.NODE_ENV === 'production' ? 'contractortools.com' : 'localhost'
  };Path=/;SameSite=Lax`;
}

function getUserCustomerId() {
  const user = getUser();
  return user.customer_id;
}

/**
 * Gets the signinjwttoken cookie
 * @return {string}
 */
function getUserCookie() {
  const cookieString = `; ${document.cookie}`;
  const parts = cookieString.split('; signinjwttoken=');
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

function setUserCookie(userData) {
  const { exp, token } = userData ?? {};
  const expDate = new Date(exp * 1000);
  const userCookie = `signinjwttoken=${token};expires=${expDate.toUTCString()};domain=${
    process.env.NODE_ENV === 'production' ? 'contractortools.com' : 'localhost'
  };Path=/;SameSite=Lax`;
  document.cookie = userCookie;
}
