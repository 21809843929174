import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { getColor } from 'styles/utils';
import contractorToolsLogo from 'assets/images/contractor-tools-logo.png';
import { apiToUTC, formatInBrowserLocale } from 'utils/dates';
import { plansByProductId } from '../data/plansByProductId';

Font.register({
  family: 'proxima-nova',
  src: '/fonts/proxima-nova/proximanova-regular-webfont.ttf',
  fontStyle: 'normal',
  fontWeight: 'normal',
});
Font.register({
  family: 'proxima-nova',
  src: '/fonts/proxima-nova/proximanova-regularitalic-webfont.ttf',
  fontStyle: 'italic',
  fontWeight: 'normal',
});
Font.register({
  family: 'proxima-nova',
  src: '/fonts/proxima-nova/proximanova-semibold-webfont.ttf',
  fontStyle: 'normal',
  fontWeight: 'bold',
});

const Receipt = ({ payment }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'proxima-nova',
      fontSize: '14pt',
    },
    receiptHeader: {
      padding: '10pt',
      textAlign: 'center',
      color: getColor('blue'),
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderBottom: `2pt solid ${getColor('blue')}`,
    },
    logoBox: {
      width: '100%',
      padding: '20pt',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      borderBottom: `2pt solid ${getColor('blue')}`,
      marginBottom: '30pt',
    },
    logoBoxInner: {
      flex: '0 0 200pt',
    },
    logoImg: {
      width: '200pt',
    },
    receiptDate: {
      fontStyle: 'italic',
      padding: '10pt',
    },
    lineItem: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      padding: '10pt',
      borderTop: `1pt solid ${getColor('grey', 'dark')}`,
    },
    itemColumn: {
      display: 'block',
    },
    totals: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      padding: '10pt',
      borderTop: `1pt solid ${getColor('grey', 'dark')}`,
    },
    totalCol: {
      fontWeight: 'bold',
    },
  });

  return (
    <Document
      title={`Contractor Tools Receipt: ${formatInBrowserLocale(
        apiToUTC(payment.purchase_date)
      )}`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.receiptHeader}>
          <Text>Receipt</Text>
        </View>
        <View style={styles.logoBox}>
          <View style={styles.logoBoxInner}>
            <Image src={contractorToolsLogo} style={styles.logoImg} />
          </View>
        </View>
        <View style={styles.receiptDate}>
          <Text>
            {formatInBrowserLocale(apiToUTC(payment.purchase_date))}
          </Text>
        </View>
        <View style={styles.lineItem}>
          <View style={styles.itemColumn}>
            <Text>{plansByProductId[payment.product_id]}</Text>
          </View>
          <View style={styles.itemColumn}>
            <Text>
              {payment.price} {payment.currency_code}
            </Text>
          </View>
        </View>
        <View style={styles.totals}>
          <View style={styles.totalCol}>
            <Text>Paid</Text>
          </View>
          <View style={styles.totalCol}>
            <Text>
              {payment.price} {payment.currency_code}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

Receipt.propTypes = {
  payment: PropTypes.shape({
    purchase_date: PropTypes.string.isRequired,
    product_id: PropTypes.number.isRequired,
    currency_code: PropTypes.string,
    price: PropTypes.string.isRequired,
  }),
};

export default Receipt;
