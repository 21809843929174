import userSession from 'lib/storage/user';
import {
  selectSelectedCompany,
  setAddCompany,
  setCard,
  setCompanies,
  setPayments,
  setSelectedCompany,
} from 'lib/store/rootSlice';
import { billingApi } from 'services/billingService';
import { companiesApi } from 'services/companiesService';
import { dispatchAndPollWithValidation, dispatchAndUnwrap } from 'utils/api';
import { hasValue, sleep } from 'utils/helpers';
import { createSubscription } from '../subscriptions/actions';
import { plans } from '../subscriptions/data/plans';
import { FORM_IDS, ROUTES } from './data/constants';

// Helper function to create a Stripe customer and update the selected company
export async function createStripeCustomerAndUpdateCompany(
  store,
  selectedCompany
) {
  const data = await dispatchAndUnwrap(
    store,
    billingApi.endpoints.createStripeCustomer,
    { ensemblesId: selectedCompany.ensembles_id }
  );

  if (data?.stripe_customer_id) {
    selectedCompany = updateCompany(store, {
      ...selectedCompany,
      stripe_customer_id: data.stripe_customer_id,
    });

    return selectedCompany.stripe_customer_id;
  }

  throw new Error('Unable to create stripe customer.');
}

// Used in loader.js
export async function getCompaniesForUser(store, user, forceRefetch = false) {
  let companyResponse;
  // Check if the user is a customer
  if (user?.customer_id) {
    // If the user is a customer, dispatch the getCompaniesForCustomer action
    companyResponse = await dispatchAndUnwrap(
      store,
      companiesApi.endpoints.getCompaniesForCustomer,
      { userId: user.id },
      forceRefetch
    );
  } else {
    // If the user is not a customer, dispatch the getCompanies action
    companyResponse = await dispatchAndUnwrap(
      store,
      companiesApi.endpoints.getCompanies,
      null,
      forceRefetch
    );
  }
  // Unwrap the response to get the companies data
  const { companies, customerId } = companyResponse;

  // Sysnc companies to store because we need access to this in various places
  // See ToolbarProvider.jsx
  store.dispatch(setCompanies(companies));

  return { companies, customerId };
}

// Used in loader.js
// Gets the selected company from local storage
// syncs if customerId is provided
// updates the store and local storage
// returns the selected company
export function getSelectedCompanyOnRefresh(store, companies, customerId) {
  const selectedCompany = userSession.getSelectedCompany();
  if (selectedCompany?.['ensembles_id'] && companies.length > 0) {
    const foundCompany = companies.find(
      (c) => c.ensembles_id === selectedCompany.ensembles_id
    );
    return updateCompany(store, foundCompany, customerId);
  }

  // If no selected company exists and companies list is not empty, set the first company as selected
  if (companies.length > 0) {
    return updateCompany(store, companies[0], customerId);
  }

  // If no companies exist, return null
  return null;
}

export async function handleResetState(store) {
  store.dispatch(
    setAddCompany({ company: null, plan: null, route: ROUTES.PLANS })
  );
  return { success: false, error: null };
}

export function handleAddCompanyPlans(store, formData) {
  const name = formData.get('planName');
  const plan = plans.find((p) => p.name === name);
  store.dispatch(setAddCompany({ plan, route: ROUTES.INFO }));
  return { success: true, error: null };
}

export async function handleAddCompanyInformation(store, formData) {
  const companyName = formData.get(FORM_IDS.COMPANY_NAME);
  const zipCode = formData.get(FORM_IDS.ZIP_CODE);
  const promoCode = formData.get(FORM_IDS.PROMO_CODE);

  try {
    // Create a new company and customer
    const { company_id: companyId } = await dispatchAndUnwrap(
      store,
      companiesApi.endpoints.createCompanyAndCustomer,
      {
        companyName,
        zipCode,
        promoCode,
      }
    );

    // Get the companies and set the selected company
    const { companies } = await dispatchAndPollWithValidation(
      store,
      companiesApi.endpoints.getCompanies,
      {},
      ({ companies }) => isCompanyPresent(companies, companyId),
      {
        maxAttempts: 5,
        delay: 256,
      }
    );

    const selectedCompany = companies.find(
      ({ ensembles_id }) => ensembles_id === companyId
    );

    store.dispatch(setCompanies(companies));

    if (!selectedCompany) {
      handleError('Unable to find company.');
    }

    updateCompany(store, selectedCompany);

    store.dispatch(
      setAddCompany({
        company: {
          companyName,
          zipCode,
        },
        route: ROUTES.CARD,
      })
    );

    return {
      success: true,
      error: null,
    };
  } catch (error) {
    handleError('Unable to create company. Please try again.', error);
  }
}

export async function handleAddCompanyCard(store, formData) {
  // Get the selected company from the store
  // Note: we just set this in handleAddCompany
  const selectedCompany = selectSelectedCompany(store.getState());

  // Guard clause to check if we have a selected company
  if (!selectedCompany) {
    handleError('No company selected');
  }

  // Check if we have a stripe customer id
  // if not we need to get one and update selected company in store and cache
  let stripeCustomerId = selectedCompany.stripe_customer_id;
  if (!stripeCustomerId) {
    try {
      stripeCustomerId = await createStripeCustomerAndUpdateCompany(
        store,
        selectedCompany
      );
    } catch (error) {
      handleError('Unable to create stripe customer. Please try again.', error);
    }
  }

  // WAIT to make sure backend has time to process
  await sleep(256);

  try {
    // Update the card before we get it
    await dispatchAndUnwrap(store, billingApi.endpoints.updateCard, {
      stripeCustomerId,
      tokenId: formData.get('tokenId'),
    });

    // WAIT for card to be fully updated before moving on.
    await sleep(256);

    // We now updated the card. Get the card and set it in the store
    // Bust cache for card: Pass true for forceRefetch
    const { card } = await dispatchAndUnwrap(
      store,
      billingApi.endpoints.getCard,
      { stripeCustomerId },
      true
    );
    store.dispatch(
      setCard({ card, ensemblesId: selectedCompany.ensembles_id })
    );

    // Next we want to create a subscription
    const { error } = await createSubscription({
      ensemblesId: selectedCompany.ensembles_id,
      stripeCustomerId,
      subscriptionName: formData.get('subscriptionName'),
      store,
    });

    // Guard: check if we have an error
    if (error) {
      return {
        success: false,
        error,
      };
    }

    // Get payments now!
    const { payments } = await dispatchAndPollWithValidation(
      store,
      billingApi.endpoints.getPayments,
      { ensembles_id: selectedCompany.ensembles_id },
      ({ payments }) => hasPayments(payments),
      {
        maxAttempts: 5,
        delay: 256,
      }
    );

    store.dispatch(
      setPayments({ payments, ensemblesId: selectedCompany.ensembles_id })
    );

    store.dispatch(
      setAddCompany({
        route: ROUTES.SUMMARY,
      })
    );

    return {
      success: true,
      error: null,
    };
  } catch (error) {
    handleError('Unable to add card. Please try again.', error);
  }
}

// Helper function to add customer ID to company and update local storage and store
export function updateCompany(store, company, customerId) {
  let newCompany = company;
  if (hasValue(customerId)) {
    newCompany = addCustomerIdToCompany(company, customerId);
  }

  // Update local storage and store
  if (newCompany) {
    userSession.setSelectedCompany(newCompany);
    store.dispatch(setSelectedCompany(newCompany));
  }

  return newCompany;
}

// ###############################################
// ### Private Helper Methods
// ###############################################

// Private Function to add stripe_customer_id to a company
const addCustomerIdToCompany = (company, customerId) => {
  return {
    ...company,
    stripe_customer_id: customerId,
  };
};

// Helper function to handle errors
function handleError(message, error) {
  console.log(`%c${message}`, 'color: red');

  if (error) {
    console.error(error);
  }

  return {
    success: false,
    error: message,
  };
}

const isCompanyPresent = (companies, companyId) =>
  companies.some(({ ensembles_id }) => ensembles_id === companyId);

const hasPayments = (payments) => payments.length > 0;
