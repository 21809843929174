import styled, {keyframes} from 'styled-components'

import {getColor, getWeight} from 'styles/utils'

export const LoadingView = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: radial-gradient(70% 100%, #479FEC 10%, #125A9A 100%);
  text-align: center;
  padding-top: 20vh;
`

export const CTLogoWrapper = styled.div`
  display: block;
  position: relative;

  img{
    width: 345px;
    max-width: 100%;
  }
`

export const LoadingMsgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const loadingIconAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

export const LoadingIcon = styled.div`
  width: 25px;
  height: 26px;
  animation: ${loadingIconAnim} 4s linear infinite;
  img{
    width: 25px;
  }
`

export const LoadingText = styled.p`
  color: ${getColor('blue', 'dark')};
  font-weight: ${getWeight('bold')};
  font-size: 23px;
  margin-left: 10px;
`
