import { api } from './api';
import { apiUrl } from 'data/constants';

export const customersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ ensemblesId }) => ({
        url: `${apiUrl}companies/${ensemblesId}/customers`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => {
        const { ensemblesId } = args ?? {};
        // selectedCompany?.ensembles_id
        return [{ type: 'customers', id: `CUSTOMERS_${ensemblesId}` }];
      },
    }),
    getCustomer: builder.query({
      query: ({ customerId }) => ({
        url: `${apiUrl}customers/${customerId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        const customer = response?.[0] ?? {};
        return customer;
      },
      providesTags: (_result, _error, args) => {
        const { customerId } = args ?? {};
        return [{ type: 'customer', id: `CUSTOMER_${customerId}` }];
      },
    }),
    getJobCount: builder.query({
      query: ({ customerId }) => ({
        url: `${apiUrl}customers/${customerId}/jobs`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        const activeJobs = response.filter((myJob) => myJob.is_active);
        return activeJobs.length;
      },
    }),
  }),
});

export const { useGetCustomersQuery, useGetCustomerQuery, useGetJobCount } =
  customersApi;
