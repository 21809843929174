import {
  add,
  addMonths,
  addYears,
  differenceInCalendarDays,
  differenceInMonths,
  endOfDay,
  endOfMonth,
  format,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  isSameMonth,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfToday,
  sub,
} from 'date-fns';
import {hasValue} from './helpers';

export function isExpired(date) {
  if (!date) {
    return false;
  }
  return isBefore(parseISO(date), new Date());
}

export function expirationForDate(exp) {
  const expiration = fromUnixTime(exp);
  const now = new Date();

  // For example, to check if the expiration date is in the past:
  const isExpired = isBefore(expiration, now);

  // If you need to format the date, you can use:
  const formattedExpiration = format(expiration, 'yyyy-MM-dd HH:mm:ss');

  // The difference between the expiration date and now, in milliseconds:
  const differenceBetweenNow = expiration.getTime() - now.getTime();

  return {
    differenceBetweenNow,
    expiration,
    isExpired,
    formattedExpiration,
  };
}

export function isSameDay(a, b) {
  if (hasValue(a) && hasValue(b)) {
    return differenceInCalendarDays(a, b) === 0;
  }
  return false;
}

export function isSameCalendarMonth(a, b) {
  return isSameMonth(a, b);
}

export function getDayRangeForDate(date) {
  const start = startOfDay(date);
  const end = endOfDay(date);
  return { start, end };
}

export function getMonthRangeForCalendar(date) {
  const start = sub(startOfMonth(date), { days: 12 });
  const end = add(endOfMonth(date), { days: 12 });
  return { start, end };
}

export function getMonthRangeForDate(date) {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return { start, end };
}

export function getPreviousDay(date) {
  return sub(startOfDay(date), { days: 1 });
}

export function getNextDay(date) {
  return add(startOfDay(date), { days: 1 });
}

export function getPreviousMonth(date) {
  return sub(startOfDay(date), { months: 1 });
}

export function getNextMonth(date) {
  return add(startOfDay(date), { months: 1 });
}

export function getStartOfToday() {
  return startOfToday();
}

export function oneYearAgoFromToday() {
  return sub(startOfToday(), { years: 1 });
}

export function formatDate(date, formatString = 'yyyy-MM-dd') {
  return format(date, formatString);
}

// ########################################################
// ## BILLING HELPERS
// ########################################################

export function addMonthsToUnix(date, months) {
  return getUnixTime(addMonths(date, months));
}

export function diffInMonths(date1, date2) {
  return differenceInMonths(date1, date2);
}

export function dateToUnix(date) {
  return getUnixTime(parseISO(date));
}

// ########################################################
// ## SUBSCRIPTION HELPERS
// ########################################################

export function setTrialEndDate(plan) {
  const { purchase_date: purchaseDate, plan_id: planId } = plan ?? {};
  const lastMonth = new Date();
  if (planId <= 3) {
    if (differenceInMonths(lastMonth, new Date(purchaseDate)) > 0) {
      return 'now';
    }
    return getUnixTime(addMonths(new Date(purchaseDate), 1));
  }
  return getUnixTime(addMonths(new Date(), 1));
}

export function setAppleDateForTrialEndDate(appleDate) {
  return getUnixTime(parseISO(appleDate));
}

// ########################################################
// ## OTHER HELPERS
// ########################################################

// New methods to pull everything together:

export function isDateInPast(date) {
  return isAfter(new Date(), date);
}

// From dateHelper.js old:

export function apiToUTC(apiDate) {
  //all dates should be displayed in utc timezone, so we just make sure this
  //date stays in that timezone instead of converting to local time
  if (apiDate) {
    return parseISO(apiDate);
  }
  return new Date();
}

export function formatInBrowserLocale(dateToFormat) {
  return format(dateToFormat, 'P');
}

export function formatDateForPurchaseOrder(date = new Date()) {
  return format(date, 'M/d/yyyy');
}

export function isSubscribed(data) {
  const { expiration_date, is_cancelled } = data ?? {};
  if (!expiration_date ) {
    return false;
  }

  return isBefore(new Date(), expiration_date) && !is_cancelled;
}

export function isExpirationDateInPast(expirationDate) {
  // Create a Date object for the expiration date
  const dateExpirationDate = new Date(expirationDate);

  // Check if the expiration date is before the current date
  return isBefore(dateExpirationDate, new Date());
}

export function getNewTempExpirationDateForSubscription({
  appleDate,
  plan: { product_id },
}) {
  if (appleDate) {
    return appleDate;
  }
  const isMonthlyRate = !(product_id >= 11);
  const timeValue = isMonthlyRate ? addMonths : addYears;
  return timeValue(new Date(), 1).toISOString();
}

export const getPurchaseDateForSubscription = (data) => {
  const startDate = data?.subscription?.start_date;
  if (typeof startDate === 'number' && !Number.isNaN(startDate)) {
    return new Date(startDate * 1000).toISOString();
  } else {
    // Return current date/time if start_date is not a valid number
    return new Date().toISOString();
  }
};
