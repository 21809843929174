import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ArrowButton from 'components/Buttons/ArrowButton';
import CloseButton from 'components/Buttons/CloseButton';
import Textfield from 'components/form/Textfield';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { useFormInputHandler } from 'hooks/useFormInputHandler';
import { useErrorSnackbar, useSuccessSnackbar } from 'hooks/useSnackbar';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hasValue } from 'utils/helpers';
import { cancelSubscription } from '../actions';
import { useModal } from '../hooks/useModal';
import { useSubscriptionsContext } from '../providers/SubscriptionsProvider';

export default function CancelSubscriptionModal({ mobile: isMobile }) {
  const { isCancelOpen: isOpen, closeCancelModal: closeModal } = useModal();

  return (
    <Modal open={isOpen} onClose={closeModal} fullScreen={isMobile} size="md">
      <Content mobile={isMobile} onClose={closeModal} />
    </Modal>
  );
}

CancelSubscriptionModal.propTypes = {
  mobile: PropTypes.bool,
};

const REASON = 'cancel-subscription-reason';

function Content({ mobile: isMobile, onClose: handleClose }) {
  const dispatch = useDispatch();
  const setSuccessSnackbar = useSuccessSnackbar();
  const setErrorSnackbar = useErrorSnackbar();

  const {
    cancelModalBundle: bundle,
    errors,
    setError,
  } = useSubscriptionsContext();

  const { title } = bundle ?? {};

  const { disabled, getError, handleBlur, handleChange, validationRef } =
    useFormInputHandler({
      errorContext: { errors, setError },
      ids: [REASON],
      validationCallback,
    });

  const [isLoading, setIsloading] = useState(false);

  const handleCancelSubcription = useCallback(async () => {
    try {
      setIsloading(true);
      const reason = validationRef.current[REASON].value;
      const {
        ensemblesId,
        stripeSubscriptionId: stripe_subscription_id,
        stripeCustomerId: stripe_customer_id,
      } = bundle;

      if (!hasValue(stripe_customer_id)) {
        console.error('Missing stripe_customer_id');
        setErrorSnackbar(
          "We couldn't cancel your subscription. Please contact support for assistance.\n888-802-1040"
        );
        return;
      }

      const cancel = cancelSubscription(bundle);

      const { error } = await cancel({
        ensemblesId,
        reason,
        store: { dispatch },
        stripe_subscription_id,
        stripe_customer_id,
      });

      if (error) {
        throw error;
      }

      setSuccessSnackbar('Your subscription has been successfully canceled.');
      handleClose();
    } catch (error) {
      console.error('Error cancelling subscription', error);
      setErrorSnackbar(
        "We couldn't cancel your subscription. Please contact support for assistance.\n888-802-1040"
      );
    } finally {
      setIsloading(false);
    }
  }, [bundle?.stripe_subscription_id]);

  return (
    <>
      <ModalHeader onClose={handleClose}>{title}</ModalHeader>
      <ModalBody>
        <Typography variant="body1">
          We would really appreciate it if you could let us know why you would
          like to end your subscription.
        </Typography>
        <Typography variant="body1" className="strong">
          Our goal is to make ContractorTools a great app at a great price,
        </Typography>
        <Typography variant="body1">
          so your feedback is very important to us.
        </Typography>
        <Typography variant="body1">
          Feel free to give us a call at
          <Typography variant="body1" component="span" className="strong">
            {' (888) 802-1040 '}
          </Typography>
          if you have any questions or suggestions.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Textfield
          error={getError(REASON)}
          fullWidth
          id={REASON}
          label="Reason for cancellation"
          onBlur={handleBlur(REASON)}
          onChange={handleChange(REASON)}
          placeholder="Please let us know why you are cancelling"
          required
          maxLength={500}
          validationRef={validationRef}
          sx={{ marginBottom: 1 }}
        />
      </ModalBody>
      <ModalFooter id="modal-footer-cancelsubscriptions">
        <CloseButton fullWidth={isMobile} onClick={handleClose}>
          Cancel
        </CloseButton>
        <ArrowButton
          fullWidth={isMobile}
          primary
          disabled={disabled}
          onClick={handleCancelSubcription}
          loading={isLoading}
        >
          Cancel Subscription
        </ArrowButton>
      </ModalFooter>
    </>
  );
}

Content.propTypes = {
  mobile: PropTypes.bool,
  onClose: PropTypes.func,
};

// ##################################################
// ### Validation Callback
// ##################################################

const validationCallback = (id, value) => {
  let error = null;
  switch (id) {
    case REASON:
      if (!value) {
        error = 'Reason for cancellation is required';
      }
      if (value.length >= 500) {
        error = 'Must be less than 500 characters';
      }
      break;
    default:
      break;
  }
  return error;
};
