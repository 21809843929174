export const plansByProductId = {
  1: 'Basic - Apple',
  2: 'Essentials - Apple',
  3: 'Pro - Apple',
  4: 'Enterprise - Apple',
  5: 'Costbooks - Apple',
  6: 'Basic Plan (mo)',
  7: 'Essentials Plan (mo)',
  8: 'Pro Plan (mo)',
  9: 'Enterprise (mo)',
  10: 'Craftsman (mo)',
  11: 'Basic Plan (yr)',
  12: 'Essentials Plan (yr)',
  13: 'Pro Plan (yr)',
  14: 'Enterprise Plan (yr)',
  15: 'Costbook (yr)',
  16: 'Additional Users',
}