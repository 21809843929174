import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme,
  decomposeColor,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import { getColor } from 'styles/utils';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export function useColorModeContext() {
  return useContext(ColorModeContext);
}

export default function CustomStyles({ children }) {
  const [mode, setMode] = useState('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => {
    const customTheme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          mobile: 375,
          mobileLandscape: 480,
          sm: 600,
          tablet: 768,
          md: 900,
          desktop: 1024,
          lg: 1280,
          xl: 1536,
        },
      },
      mixins: {
        elevation: {
          '@media (min-width:0px)': '0',
          '@media (min-width:600px)': '1',
        },
        decomposeColor: (color, opacity) => {
          const {
            values: [r, g, b],
          } = decomposeColor(color);
          return `rgb(${r}, ${g}, ${b}, ${opacity || 1})`;
        },
      },
      palette: {
        mode,
        primary: {
          contrastText: '#FFFFFF',
          dark: '#1E5A8A',
          main: '#2B87D8',
          light: '#6AB0F3',
          superLight: '#F1F8FF',
        },
        secondary: {
          contrastText: '#FFFFFF',
          dark: '#FFC107',
          light: '#FFDD4B',
          main: '#F9E448',
        },
        charcoal: {
          main: '#46494B',
          dark: '#2B2E2F',
          light: '#6E7375',
        },
        blue: {
          main: '#2B87D8',
          dark: '#1E5A8A',
          light: '#6AB0F3',
          veryLight: '#D7EBFF',
          superLight: '#F1F8FF',
        },
        yellow: {
          main: '#F9E448',
          dark: '#FFC107',
          light: '#FFDD4B',
        },
        green: {
          main: '#5CB85C',
          dark: '#449144',
          light: '#CBEBCB',
          superLight: '#f0f9f0',
        },
        red: {
          main: '#D55D65',
          dark: '#CB343E',
          superDark: '#A1474D',
          light: '#DE8A92',
        },
        grey: {
          main: '#E4E4E4',
          light: '#F7F7F9',
          dark: '#999999',
          divider: '#EAEAEA',
        },
        white: '#FFFFFF',
        black: '#000000',
        getColor,
      },
      shape: {
        backgroundGradient: {
          backgroundColor: `rgb(111,80,122)`,
          background: `linear-gradient(90deg, rgba(111,80,122,1) 0%, rgba(87,57,99,1) 80%)`,
        },
        borderRadius: 3,
      },
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true, // No more ripple, on the whole application 💣
          },
        },
        MuiButton: {
          defaultProps: {
            disableElevation: true,
            disableRipple: true,
            disableFocusRipple: true,
            disableTouchRipple: true,
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 8, // Sets border radius for Dialog
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 3, // Sets border radius for InputBase
            },
          },
        },
        MuiSkeleton: {
          defaultProps: {
            animation: false,
          },
        },
      },
      typography: {
        fontFamily: 'proxima-nova, Arial, sans-serif',
        fontWeightRegular: 400, // Default weight for normal text
        fontWeightLight: 200, // Light weight
        fontWeightBold: 700, // Bold weight
        h1: {
          fontWeight: 400,
          fontSize: '3.25rem', // 52px
          lineHeight: 1.167,
          letterSpacing: '0em',
          '@media (max-width:600px)': {
            fontSize: '2.25rem', // 36px for mobile
          },
        },
        h2: {
          fontWeight: 400,
          fontSize: '2.75rem', // 44px
          lineHeight: 1.2,
          letterSpacing: '0em',
          '@media (max-width:600px)': {
            fontSize: '2.25rem', // 36px for mobile
          },
        },
        h3: {
          fontWeight: 400,
          fontSize: '2.25rem', // 36px
          lineHeight: 1.167,
          letterSpacing: '0em',
          '@media (max-width:600px)': {
            fontSize: '2rem', // 32px for mobile
          },
        },
        h4: {
          fontWeight: 400,
          fontSize: '2rem', // 32px
          lineHeight: 1.235,
          letterSpacing: '0.00735em',
          '@media (max-width:600px)': {
            fontSize: '1.75rem', // 28px for mobile
          },
        },
        h5: {
          fontWeight: 400,
          fontSize: '1.75rem', // 28px
          lineHeight: 1.334,
          letterSpacing: '0em',
          '@media (max-width:600px)': {
            fontSize: '1.5rem', // 24px for mobile
          },
        },
        h6: {
          fontWeight: 700,
          fontSize: '1.5rem', // 24px

          lineHeight: 1.6,
          letterSpacing: '0.0075em',
          '@media (max-width:600px)': {
            fontSize: '1.25rem', // 20px for mobile
          },
        },
        subtitle1: {
          fontWeight: 400,
          fontSize: '1.25rem', // 20px
          lineHeight: 1.75,
          letterSpacing: '0.00938em',
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: '1.125rem', // 18px
          lineHeight: 1.57,
          letterSpacing: '0.00714em',
        },
        body1: {
          fontWeight: 400,
          fontSize: '1.125rem', // 18px
          lineHeight: 1.5,
          letterSpacing: '0.00938em',
        },
        body2: {
          fontWeight: 400,
          fontSize: '1rem', // 16px
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        button: {
          fontWeight: 700,
          fontSize: '1rem', // 16px
          lineHeight: 1.75,
          letterSpacing: '0.02857em',
          textTransform: 'uppercase',
        },
        caption: {
          fontWeight: 400,
          fontSize: '1rem', // 16px
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        overline: {
          fontWeight: 400,
          fontSize: '0.875rem', // 14px
          lineHeight: 2.66,
          letterSpacing: '0.08333em',
          textTransform: 'uppercase',
        },
      },
    });

    // Make responsive!
    // See: https://material-ui.com/guides/responsive-ui/
    // console.log(`%cCustomTheme`, 'color: #7FFFD4');
    // console.log(customTheme);
    return responsiveFontSizes(customTheme);
  }, [mode]);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <CssBaseline />
        {inputGlobalStyles}
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

CustomStyles.propTypes = {
  children: PropTypes.any,
};

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': {
        width: '100%',
        margin: 0,
        padding: 0,
        fontFamily: 'proxima-nova, Arial, sans-serif',
      },
      '*:focus': {
        outline: 'none',
      },
      'button:focus': {
        outline: 'none',
      },
      a: {
        color: '#2B87D8',
        textDecoration: 'none',
      },

      'a:hover': {
        color: '#125A9A',
      },
      'a:focus': {
        outlineColor: '#2B87D8',
        outlineWidth: '1px',
      },
      'input:focus': {
        outline: 'none',
      },
    }}
  />
);
