import { checkPermissions, featureEnabled } from 'features/accessControl';
import { getToken, tokenHasExpired } from 'lib/storage/user';
import { redirect } from 'react-router-dom';
import { getRedirectLoginUrl } from 'utils/urls';

const redirectNoFeatures = () => {
  return redirect('/feature-not-available');
};
const redirectNoPermissions = () => {
  return redirect('/not-allowed');
};

export async function createLazyRoute(
  page,
  store,
  { permissions = null, features = null } = {}
) {
  try {
    // dynamically import the component, loader, and action from the page directory,
    const { action, loader, Component } = await import(
      `../../pages/${page}.jsx`
    );

    return buildLazyRoute({
      action,
      loader,
      Component,
      store,
      permissions,
      features,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createLazyCustomersRoute(
  page,
  store,
  { permissions = null, features = null } = {}
) {
  try {
    // dynamically import the component, loader, and action from the page directory,
    const { action, loader, Component } = await import(
      `../../pages/customers/${page}.jsx`
    );

    return buildLazyRoute({
      action,
      loader,
      Component,
      store,
      permissions,
      features,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createLazyJobsRoute(
  page,
  store,
  { permissions = null, features = null } = {}
) {
  try {
    // dynamically import the component, loader, and action from the page directory,
    const { action, loader, Component } = await import(
      `../../pages/jobs/${page}.jsx`
    );

    return buildLazyRoute({
      action,
      loader,
      Component,
      store,
      permissions,
      features,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createLazyPublicRoute(page, store) {
  try {
    // dynamically import the component, loader, and action from the page directory,
    const { action, loader, Component } = await import(
      `../../pages/public/${page}.jsx`
    );

    return buildLazyRoute({ action, loader, Component, store, isPublic: true });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createLazyAdminRoute(page, store) {
  try {
    // dynamically import the component, loader, and action.
    const { action, loader, Component } = await import(
      `../../pages/admin/${page}.jsx`
    );

    return buildLazyRoute({
      action,
      loader,
      Component,
      store,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Builds a lazy route configuration object.
 *
 * @param {Object} param0 - The parameters for building the lazy route.
 * @param {Function} [param0.action] - The action function to be executed. It receives the store and an object containing params and request.
 * @param {Function} [param0.loader] - The loader function to be executed. It receives the store and an object containing params and request.
 * @param {React.Component} param0.Component - The React component to be rendered for this route.
 * @param {Object} param0.store - The Redux store or any other store object to be passed to the action and loader functions.
 * @param {Object} [param0.features] - The features object to be passed to the action and loader functions.
 * @param {Object} [param0.permissions] - The permissions object to be passed to the action and loader functions.
 * @param {Boolean} [param0.isPublic] - Whether this route is public or not.
 * @returns {Object} The lazy route configuration object.
 * @returns {Function} [returns.action] - The wrapped action function, or undefined if no action is provided.
 * @returns {Function} [returns.loader] - The wrapped loader function, or undefined if no loader is provided.
 * @returns {React.Component} returns.Component - The React component to be rendered for this route.
 */
function buildLazyRoute({
  action,
  loader,
  Component,
  store,
  features,
  permissions,
  isPublic,
}) {
  return {
    action: action
      ? ({ params, request }) => {
          return action(store, { params, request, features, permissions });
        }
      : undefined,
    loader: loader
      ? ({ params, request }) => {
          const token = getToken();
          const tokenExpired = tokenHasExpired(token);

          // First check if the token has expired
          // and if the route is not public
          // If it so, redirect to the login page
          if (tokenExpired && !isPublic) {
            const redirectUrl = getRedirectLoginUrl();
            return redirect(redirectUrl);
          }

          // For most private routes we check permissions
          // For jobs we also check features
          // For public pages these checks should fall through!
          const featurePassed = featureEnabled(features);
          const permissionsPassed = checkPermissions(permissions);

          if (!featurePassed) {
            return redirectNoFeatures(features);
          }

          if (!permissionsPassed) {
            console.log('Permissions failed', permissions);
            return redirectNoPermissions(permissions);
          }

          return loader(store, { params, request, features, permissions });
        }
      : undefined,
    Component,
  };
}
