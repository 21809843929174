import Typography from '@mui/material/Typography';
import Button from 'components/Buttons/Button';
import { selectSelectedCompany } from 'lib/store/rootSlice';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { extraUsers, plans } from '../data/plans';
import { useExtraUsersButton } from '../hooks/useButton';
import { useModal } from '../hooks/useModal';
import { useSubscriptionsContext } from '../providers/SubscriptionsProvider';
import {
  ActionsWrapper,
  AddOnImage,
  AddOnStack,
  AlternatePrice,
  Price,
} from './Styles';

export default function ExtraUsers() {
  const {
    getCancelModalBundle,
    getExtraUsersModalBundle,
    openModal,
    openCancelModal,
  } = useModal();
  const { footer, image, price } = extraUsers ?? {};

  // Current Plan (from context)
  const {
    ensemblesId,
    extraUsers: currentExtraUsers,
    plan,
    setCancelModalBundle,
    setModalBundle,
    stripeCustomerId: stripeCustomerIdFromContext,
  } = useSubscriptionsContext();

  const { stripe_customer_id: stripeCustomerIdFromCache } = useSelector(
    selectSelectedCompany
  );

  const stripeCustomerId =
    stripeCustomerIdFromContext || stripeCustomerIdFromCache;

  const { key, label, options } = useExtraUsersButton(plan, currentExtraUsers);

  // Get user count from the plan model based on the current plan name.
  // The plan model has a hardcoded user count for each plan.
  const planDetails = plans.find((plan) => plan.name === name);
  const count = planDetails?.users || 1;

  const handleCancel = useCallback(() => {
    const bundle = getCancelModalBundle({
      currentPlan: currentExtraUsers,
      ensemblesId,
      planName: extraUsers.name,
      stripeCustomerId,
    });
    setCancelModalBundle(bundle);
    openCancelModal();
  }, [key, stripeCustomerId]);

  const handleSubscribe = useCallback(() => {
    const bundle = getExtraUsersModalBundle({
      currentExtraUsers,
      ensemblesId,
      extraUsers,
      stripeCustomerId,
    });
    setModalBundle(bundle);
    openModal();
  }, [key, stripeCustomerId]);

  const handleClick = currentExtraUsers?.subscribed
    ? handleCancel
    : handleSubscribe;

  return (
    <AddOnStack>
      <Price>{price.month}</Price>
      <AlternatePrice>{price.user}</AlternatePrice>
      <AddOnImage src={image} alt={name} />
      <ActionsWrapper>
        <Button fullWidth {...options} onClick={handleClick}>
          {label}
        </Button>
        <Typography variant="caption" color="textSecondary">
          {footer.caption(plan.name, count, plan.subscribed)}
        </Typography>
      </ActionsWrapper>
    </AddOnStack>
  );
}

// Not using costbooks as a prop in this component, but leaving
// To act as a reference for the PropTypes for costbooks from data/plans.js
ExtraUsers.propTypes = {
  users: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    subscription: PropTypes.string,
    price: PropTypes.shape({
      month: PropTypes.string,
      user: PropTypes.string,
    }),
    footer: PropTypes.shape({
      caption: PropTypes.func,
    }),
  }),
};
