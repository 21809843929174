import {
  faCheckCircle,
  faExchangeAlt,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowButton from 'components/Buttons/ArrowButton';
import Button from 'components/Buttons/Button';
import CloseButton from 'components/Buttons/CloseButton';
import DrawerHeader from 'components/Drawer/DrawerHeader';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
// import Typoography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from 'utils/helpers';
import { createStripeCustomerAndUpdateCompany } from '../../../company/actions';
import {
  cancelAppleSubscription,
  convertApplePlanToStripe,
  getSubscriptionsForCancelApple,
} from '../../actions';
import {
  useCustomerCardDrawer,
  useInitCancelApple,
} from '../../hooks/useBilling';
import { useBillingContext } from '../../providers/BillingProvider';

export default function CancelApple({ success, error }) {
  const [isSubmitting, setSubmitting] = useState(false);

  const { closeDrawer, setSlideInAppleError, setSlideInAppleSuccess } =
    useCustomerCardDrawer();

  const { ensemblesId, selectedCompany } = useBillingContext();

  const initCancelApple = useInitCancelApple();

  const hasErrors = false;

  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const getState = () => state;

  const theme = useTheme();

  // Handle submit
  // 1. get plans and subscriptions if not already loaded
  // 2. make call to cancle apple subscription
  // 3. create stripe customer
  // 4. If apple account was active and not expired,
  //    then convert apple subscription to stripe subscription
  // 5. If costbooks is active, then convert costbooks to stripe subscription
  const handleSubmit = async () => {
    try {
      initCancelApple();
      setSubmitting(true);

      // 1. Get plans and subscriptions
      const subscription = await getSubscriptionsForCancelApple({
        store: { dispatch },
        ensemblesId,
      });

      // 2. Cancel Apple Subscription
      await cancelAppleSubscription({
        store: { dispatch },
        ensemblesId,
        reason: 'UPDATING CREDIT CARD',
      });

      // 3. Create Stripe Customer and update company
      const stripeId = await createStripeCustomerAndUpdateCompany(
        { dispatch },
        selectedCompany
      );

      // 4. Need to create a new stripe subscription
      // ensemblesId,
      // expirationDate: appleDate,
      // productId,
      // stripeId: stripeCustomerId,
      // store,
      const { isAppleActive, plan } = subscription ?? {};
      const { expiration_date: expirationDate, product_id: productId } =
        plan ?? {};
      if (isAppleActive) {
        await convertApplePlanToStripe({
          ensemblesId,
          expirationDate,
          productId,
          stripeCustomerId: stripeId,
          store: { dispatch, getState },
        });
      }

      // TODO: need to remove isAppleActive from the plan
      // 5. Convert costbooks to stripe subscription

      // TODO: Figure out costbooks here after subscription is created

      // We can update the plan here??

      // Finish the submission
      await sleep(1000);
      setSlideInAppleSuccess(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSlideInAppleError(true);
    }
  };

  if (error) {
    return <CancelAppleError />;
  }

  if (success) {
    return <CancelAppleSuccess />;
  }

  return (
    <>
      <DrawerHeader title={title} onClose={closeDrawer} />
      <DrawerPaper>
        <Stack gap={2}>
          <Stack alignItems="center" sx={{ my: 2 }}>
            <Icon
              icon={faExchangeAlt}
              size="3x"
              color={theme.palette.info.main}
            />
          </Stack>
          <Type variant="body2">{defaultBodyText}</Type>
          <Type variant="body2">{defaultBodyText2}</Type>
        </Stack>
      </DrawerPaper>
      <Footer>
        <CloseButton fullWidth onClick={closeDrawer}>
          Cancel
        </CloseButton>
        <ArrowButton
          disabled={hasErrors}
          fullWidth
          primary
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Proceed
        </ArrowButton>
      </Footer>
    </>
  );
}

CancelApple.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
};

function CancelAppleError() {
  const { closeDrawer } = useCustomerCardDrawer();
  const theme = useTheme();
  return (
    <>
      <DrawerHeader title={title} onClose={closeDrawer} />
      <DrawerPaper>
        <Stack gap={2}>
          <Stack alignItems="center" sx={{ my: 2 }}>
            <Icon
              icon={faExclamationCircle}
              size="3x"
              color={theme.palette.error.main}
            />
          </Stack>
          <Type variant="body2">
            <span className="error">
              There was an error canceling your Apple subscription. Please try
              again.
            </span>
          </Type>
        </Stack>
      </DrawerPaper>
      <Footer>
        <Button fullWidth primary onClick={closeDrawer}>
          Close
        </Button>
      </Footer>
    </>
  );
}

function CancelAppleSuccess() {
  const { closeDrawer, setSlideInAddCard } = useCustomerCardDrawer();
  const handleAddCard = () => {
    setSlideInAddCard(true);
  };
  const theme = useTheme();
  return (
    <>
      <DrawerHeader title={title} onClose={closeDrawer} />
      <DrawerPaper>
        <Stack gap={2}>
          <Stack alignItems="center" sx={{ my: 2 }}>
            <Icon
              icon={faCheckCircle}
              size="3x"
              color={theme.palette.success.main}
            />
          </Stack>
          <Type variant="body2">
            You will receive an email with instructions on how to cancel your
            App Store subscription with Apple.
          </Type>
          <Type variant="body2">
            You can now switch to direct billing with us.
          </Type>
        </Stack>
      </DrawerPaper>
      <Footer>
        <Button fullWidth secondary onClick={closeDrawer}>
          Close
        </Button>
        <ArrowButton fullWidth primary onClick={handleAddCard}>
          Add Card
        </ArrowButton>
      </Footer>
    </>
  );
}

// Text and Strings
const title = 'Switch to Direct Billing';
const defaultBodyText = `You're currently paying with Apple Subscriptions.`;
const defaultBodyText2 = `Before you can
switch, we'll need to cancel your Apple subscription and switch your
existing company to our direct billing system.`;

// ##############################
// ### Styles and Components
// ##############################

const DrawerPaper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflowY: 'auto',
}));

function Footer({ children }) {
  return (
    <>
      <Divider />
      <Stack direction="row" alignItems="center" gap={2} sx={{ p: 2 }}>
        {children}
      </Stack>
    </>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
};

const Type = styled(Typography)(({ theme }) => ({
  // textWrap: 'balance',
  textAlign: 'left',
  '& .error': {
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
}));
