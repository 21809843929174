import {useCustomerCardDrawer} from 'features/billing';
import {useErrorSnackbar} from 'hooks/useSnackbar';
import {
  selectCardByEnsemblesId,
  selectSelectedCompany,
  selectSubscriptionByEnsemblesId,
} from 'lib/store/rootSlice';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useSubscriptionsContext} from '../providers/SubscriptionsProvider';
import {hasStripeSubscription, isActiveAppleSubscription} from '../utils';

export function useValidation(payload, key) {
  const { ensemblesId } = payload ?? {};
  const { id: cardId } =
    useSelector(selectCardByEnsemblesId(ensemblesId)) ?? {};

  const hasCardId = !!cardId;
  const { plan: currentPlan } = useSelector(
    selectSubscriptionByEnsemblesId(ensemblesId)
  );

  const validate = useCallback(() => {
    if (!hasStripeSubscription(currentPlan) && !hasCardId) {
      return {
        error: 'Please add a credit card to continue.',
        key,
      };
    }
    return {};
  }, [hasCardId, key]);

  return {
    validate,
  };
}

export function useErrorManager(payload, key) {
  const { setStripe } = useSubscriptionsContext();
  const { openDrawer } = useCustomerCardDrawer();
  const setErrorSnackbar = useErrorSnackbar();

  const { key: planKey } = payload ?? {};

  const { ensembles_id: ensemblesId, stripe_customer_id: customerId } =
    useSelector(selectSelectedCompany);

  const { id: cardId } =
    useSelector(selectCardByEnsemblesId(ensemblesId)) ?? {};

  const { plan } =
    useSelector(selectSubscriptionByEnsemblesId(ensemblesId)) ?? {};

  const isActiveApple = isActiveAppleSubscription(plan);
  const isCreateSubscription = planKey === 'renewSubscription'; // I know it's renew for create
  const isUpdateSubscription = planKey === 'updateSubscription';
  const hasCardId = !!cardId;
  const hasStripeCustomerId = !!customerId;

  const handleError = useCallback(
    async (err) => {
      console.error('Subscription Error:');
      console.error(err);

      // Done!
      if (isUpdateSubscription && !hasCardId) {
        console.log('Update Subscription Error:', err);
        setErrorSnackbar(errorNeedsCard('update'));
        openDrawer();
        return;
      }

      // Done!
      if (isUpdateSubscription) {
        setErrorSnackbar(errorDefaultMessage('update'));
        return;
      }

      // Done!
      if (isCreateSubscription && hasCardId) {
        setErrorSnackbar(errorDefaultMessage('renew'));
        return;
      }

      // Done!
      if (isCreateSubscription && !hasCardId && !isActiveApple) {
        setErrorSnackbar(errorNeedsCard('renew'));
        openDrawer();
        return;
      }

      if (
        isCreateSubscription &&
        !hasCardId &&
        isActiveApple &&
        !hasStripeCustomerId
      ) {
        setErrorSnackbar(
          "You're currently subscribed through Apple. Please add a card to switch to direct billing and continue your subscription."
        );

        // Need to update Stripe here
        // Make sure it's loaded with no account
        // and then update context
        // const stripe = await loadStripeWithAccount();
        // setStripe(stripe);
        // What we need instead is to have the stripeCustomerId

        openDrawer();
        return;
      }

      setErrorSnackbar(
        "We couldn't submit your subscription. Please contact support for assistance.\n888-802-1040"
      );
    },
    [
      key,
      isUpdateSubscription,
      isCreateSubscription,
      hasCardId,
      isActiveApple,
      hasStripeCustomerId,
    ]
  );
  return {
    handleError,
  };
}

// #################################################
// ### Helper Methods
// #################################################

const errorDefaultMessage = (status) =>
  `We couldn't ${status} your subscription. Please contact support for assistance.\n888-802-1040`;

const errorNeedsCard = (status) =>
  `We couldn't ${status} your subscription. Please add a credit card to continue.`;
