import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import Icon from '../Icon';
import PropTypes from 'prop-types';

export default function BackButton({
  children,
  onClick: handleOnClick,
  ...props
}) {
  return (
    <Button
      variant="text"
      onClick={handleOnClick}
      startIcon={<Icon icon={faChevronLeft} />}
      {...props}
    >
      {children}
    </Button>
  );
}

BackButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};
