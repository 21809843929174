import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

export default function TemporaryDrawer({
  children,
  onClose: handleClose,
  open,
  width = 320,
}) {
  return (
    <Drawer onClose={handleClose} open={open} width={width}>
      <Content>{children}</Content>
    </Drawer>
  );
}

TemporaryDrawer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  width: PropTypes.number,
};

const Drawer = styled(
  (props) => <MUIDrawer anchor="right" variant="temporary" {...props} />,
  { shouldForwardProp: (prop) => prop !== 'zIndex' && prop !== 'width' }
)(({ theme, zIndex, width }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  zIndex: zIndex || theme.zIndex.drawer + 100,
  [`& .MuiDrawer-paper`]: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width,
    },
  },
}));

const Content = styled(Box)(({ _theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
}));
