import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  ErrorTransitionWrapper,
  InputErrorText,
  InputHintText,
} from './Styles';

/**
 * CommonInput.jsx and CommonFormControl.jsx
 * Are to be used in Textfield and other input fields that
 * share similar looks and features.
 */

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  '&:focus': {
    color: theme.palette.primary.main,
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.primary.main,
  },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.white,
    border: '1px solid',
    borderColor: theme.palette.charcoal.main,
    fontSize: 16,
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.action.disabled,
    },
  },
}));

export function CommonInput({
  id,
  inputProps,
  onBlur: handleBlur,
  onChange: handleChange,
  validationRef,
  defaultValue,
  ...props
}) {
  return (
    <StyledInput
      id={id}
      inputProps={inputProps}
      onBlur={handleBlur}
      onChange={handleChange}
      inputRef={(el) => (validationRef.current[id] = el)}
      defaultValue={defaultValue}
      {...props}
    />
  );
}

CommonInput.propTypes = {
  id: PropTypes.string,
  inputProps: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validationRef: PropTypes.any,
  defaultValue: PropTypes.string,
};

export function CommonFormControl({
  animateError,
  children,
  error,
  gutter,
  hint,
  id,
  label,
  required,
  ...props
}) {
  const theme = useTheme();
  const errorRef = useRef();
  const [prevError, setPrevError] = useState(false);
  const [showError, setShowError] = useState(false);

  if (!!error !== prevError) {
    setPrevError(!!error);
  }

  if (!!error && !!error !== prevError) {
    setShowError(true);
  }

  return (
    <CSSTransition
      in={!!error}
      timeout={theme.transitions.duration.shorter}
      classNames="error-text"
      nodeRef={errorRef}
      onExited={() => {
        setShowError(false);
      }}
    >
      <FormControl variant="standard" {...props}>
        <StyledInputLabel shrink htmlFor={id} required={required}>
          {label}
        </StyledInputLabel>
        {children}
        {!animateError && error && <InputErrorText>{error}</InputErrorText>}
        {animateError && showError && (
          <ErrorTransitionWrapper ref={errorRef}>
            <InputErrorText>{error}</InputErrorText>
          </ErrorTransitionWrapper>
        )}
        {hint && !error && <InputHintText>{hint}</InputHintText>}
        {gutter && !error && !hint && <InputHintText>&nbsp;</InputHintText>}
      </FormControl>
    </CSSTransition>
  );
}

CommonFormControl.propTypes = {
  animateError: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.string,
  gutter: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
