import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

export function PageLayout({ children }) {
  return (
    <Background>
      <Grid>
        <Content>{children}</Content>
      </Grid>
    </Background>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node,
};

// ##############################
// ### Form Styled Components
// ##############################

const Background = styled(Box)(({ theme }) => ({
  height: '100svh',
  backgroundColor: theme.palette.grey.light,
  overflow: 'hidden',
}));

const FullHeightFlexCenter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative', // added this to allow for the pseudo-element for background
  [theme.breakpoints.up('sm')]: {
    height: '720px',
    borderRadius: theme.shape.borderRadius,
  },
}));

const Content = styled(FullHeightFlexCenter)(({ theme }) => ({
  // backgroundColor: theme.palette.grey.light,
  color: theme.palette.text.primary,
}));

const Grid = styled((props) => <Box component="main" {...props} />)(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.lg,
      margin: '0 auto',
    },
  })
);
