import Button from 'components/Buttons/Button';
import {clearUserSession} from 'features/auth';
import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import styled from 'styled-components';
import {getRedirectLoginUrl, getSearchParmFromLocation} from 'utils/urls';
import GlobalStyles from '../styles/global-styles';
import {CTLogoWrapper} from './LoadingApp/style';

export default function ErrorBoundary() {
  const error = useRouteError();
  const location = useLocation();

  const searchParamStatus = getSearchParmFromLocation(location, 'status');

  const errorStatus = Number(error?.status) || Number(searchParamStatus) || 400;

  // useEffect(() => {
  //   console.log('-----------------------------------');
  //   console.log('ErrorBoundary');
  //   console.log('Error Status:', errorStatus);
  //   console.log(error);
  // }, [errorStatus]);

  if (errorStatus) {
    switch (errorStatus) {
      case 400:
        return <BadRequest />;
      case 401:
        return <Unauthorized />;
      case 403:
        return <Forbidden error={error} />;
      case 404:
        return <NotFound />;
      case 500:
        return <ServerError />;
      case 503:
        return <ServiceUnavailable />;
      default:
        return <UnknownError />;
    }
  }

  if (error) {
    console.error('ErrorBounday caught an unknown error');
  } else {
    console.warn(
      'Error is not defined in the ErrorBoundary. This can happen if the error was a response and caught in a defer loader.'
    );
  }

  return <UnknownError />;
}

function BadRequest() {
  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>400 Bad Request</Header>
        <Typography>
          Something went wrong with your request. It looks like there might be a
          typo or missing information. Please double-check and try again!
        </Typography>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

function Unauthorized() {
  const redirectUrl = getRedirectLoginUrl();
  console.warn('Unauthorized. Redirecting to login.');
  console.warn('Redirecting to:', redirectUrl);
  // I don't believe these are necessary, but just in case
  // it gets past loader checks, will invalidate token here too.
  return <Navigate to={redirectUrl} replace />;
}

function Forbidden({ error }) {
  if (error?.message?.toLowerCase().includes('online payments')) {
    return (
      <Navigate to={`/payment-settings?warning=${error?.message}`} replace />
    );
  }

  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>403 Forbidden</Header>
        <Typography>You do not have permission to access this page.</Typography>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

Forbidden.propTypes = {
  error: PropTypes.object,
};

function NotFound() {
  const navigate = useNavigate();
  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>404 Not Found</Header>
        <Typography>
          The page you&apos;re looking for doesn&apos;t exist.
        </Typography>
        <Button
          danger
          onClick={() => {
            navigate('/');
          }}
        >
          Home
        </Button>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

function ServerError() {
  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>500 Server Error</Header>
        <Typography>Something went wrong on our end.</Typography>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

function ServiceUnavailable() {
  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>503 Service Unavailable</Header>
        <Typography>
          Our service is currently unavailable. Please try again later.
        </Typography>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

function UnknownError() {
  const cleared = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cleared.current) {
      console.log(`%cUnknown Error!`, 'color: #7FFFD4');
      cleared.current = true;
      clearUserSession(dispatch);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <ErrorPageWrapper>
      <ErrorBox>
        <Header>Unknown Error</Header>
        <Typography>An unknown error occurred.</Typography>
        <Button
          danger
          onClick={() => {
            navigate('/login');
          }}
        >
          Login
        </Button>
      </ErrorBox>
    </ErrorPageWrapper>
  );
}

function ErrorPageWrapper({ children }) {
  return (
    <>
      <GlobalStyles />
      <Background>
        <Grid>
          <CenterColumn>
            <CTLogoWrapper>
              <img src="/images/ct-logo-white.png" alt="Contractor Tools" />
            </CTLogoWrapper>
            {children}
          </CenterColumn>
        </Grid>
      </Background>
    </>
  );
}

ErrorPageWrapper.propTypes = {
  children: PropTypes.any,
};

// ########################################################
// ### STYLED COMPONENTS
// ########################################################

const Background = styled.div`
  background-color: navy;
  height: 100vh;
`;

const Grid = styled.main`
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 16px 1fr 16px;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 600px) {
    grid-template-columns: 0.25fr 1fr 0.25fr;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto 1fr;
  }
`;

const CenterColumn = styled.div`
  grid-area: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  margin: 0 auto;
  margin-top: 32px;
  gap: 32px;
  width: 100%;

  @media (min-width: 960px) {
    width: 720px;
  }
`;

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 48px;
  background-color: white;
  color: red;
  width: 100%;
  border-radius: 4px;
`;

const LogoContainer = styled.div`
  width: 80%;
  margin-top: 32px;
`;

const Header = styled.h4`
  font-size: 1.5em;
  font-weight: 500;
  margin: 0.5em 0;
`;

const Typography = styled.p`
  font-size: 0.875em;
  margin: 0.25em 0;
`;
